<template>
  <v-container class="fill-height">
    <v-layout row align-center justify-space-around>
      <v-flex xs12 sm12 md6 lg6 xl4>
        <!-- Login form here -->
        <v-card outlined>
          <h1 :style="`color: ${$vuetify.theme.dark
            ? style.login.primary_dark
            : style.login.primary
            };
                                  font-size: 1.8rem;
                                  font-weight: 700;
                                  text-align: center;`" class="my-6">
            {{
              $vuetify.rtl
              ? localization.fa.signup.resend_confirmation_title
              : localization.en.signup.resend_confirmation_title
            }}
          </h1>

          <v-card-actions class="mt-3">
            <v-spacer></v-spacer>
            <div v-text="
              $vuetify.rtl
                ? localization.fa.signup.resend_confirmation_msg
                : localization.en.signup.resend_confirmation_msg
            " class="mx-2"></div>
            <span @click.prevent="resendConfirmationEmail" v-text="
              $vuetify.rtl
                ? localization.fa.signup.resend_confirmation_action
                : localization.en.signup.resend_confirmation_action
            " style="font-size: 0.8rem; cursor: pointer; color: #30d237" v-if="countdown_finished">
            </span>
            <span v-else style="font-size: 0.88rem; cursor: pointer; font-weight: bold"
              :class="{ 'fa-numeric': $vuetify.rtl }">
              {{ $vuetify.rtl ? timer + " ثانیه" : timer + " s" }}
            </span>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <message-show ref="MessageShow"></message-show>
  </v-container>
</template>

<script>
import APIMixin from "../../../mixins/apiMixin";
import AuthMixin from "../../../mixins/authMixin";
import styleMixin from "../../../mixins/styleMixin";
import utils from "../../../mixins/utils";
import localizationMixin from "../../../mixins/localeMixin";
import MessageShow from "../../../components/MessageShow.vue";

export default {
  metaInfo() {
    return {
      title: this.$vuetify.rtl
        ? this.localization.fa.signup.resend_confirmation_title
        : this.localization.en.signup.resend_confirmation_title,
      meta: [
        {
          name: "description",
          content: "Sign up confirmation",
        },
        {
          property: "og:title",
          content: this.$vuetify.rtl
            ? this.localization.fa.signup.resend_confirmation_title
            : this.localization.en.signup.resend_confirmation_title,
        },
        {
          property: "og:description",
          content: "Sign up confirmation",
        },
        {
          property: "og:url",
          content: "https://zagros.live" + this.$router.currentRoute.path,
        },
      ],
    };
  },
  name: "SignUpConfirmationPage",
  props: ["user"],
  components: {
    MessageShow,
  },
  mixins: [APIMixin, AuthMixin, localizationMixin, styleMixin, utils],
  data: () => ({
    is_loading: false,
    timer: 300,
    countdown_finished: false,
  }),
  methods: {
    goToSignUpPage: function () {
      this.$router.push({ name: "signup" });
    },
    goToDashboardPage: function () {
      this.$router.push({ name: "dashboard" });
    },
    activateProgInterval: function () {
      //Create a timeout every 100 miliseconds
      setTimeout(() => {
        //Increment the time counter by 100
        this.timer -= 1;

        //If our current time is larger than the timeout
        if (this.timer === 0) {
          //Wait 500 miliseconds for the dom to catch up, then reset the snackbar
          setTimeout(() => {
            this.timer = 300; // reset the current time
            this.countdown_finished = true;
          }, 200);
        } else {
          //Recursivly update the progress bar
          this.activateProgInterval();
        }
      }, 1000);
    },
    resendConfirmationEmail: function () {
      this.is_loading = true;
      //
      this.countdown_finished = false;
      //   activate timer
      this.activateProgInterval();
      //
      let data = {
        email: this.user.email,
        username: this.user.username,
        lang: this.$vuetify.rtl ? "fa" : "en",
      };
      // resending confirmation
      this.$axios
        .post(this.user_api.signup_confirmation_resend, data, {
          withCredentials: true,
        })
        .then((response) => {
          if (response.data.status) {
            this.$refs.MessageShow.showMessage(
              this.$vuetify.rtl
                ? "ایمیل با موفقیت ارسال شد"
                : "confirmation email has been sent successfully",
              "success"
            );
          } else {
            if (response.data.message) {
              this.$refs.MessageShow.showMessage(
                this.$vuetify.rtl
                  ? this.localization.fa.auth.already_active_msg
                  : this.localization.en.auth.already_active_msg,
                "warning"
              );
              this.$router.push("/");
            } else {
              this.$refs.MessageShow.showMessage(
                this.$vuetify.rtl
                  ? this.localization.fa.auth.reset_password_failed
                  : this.localization.en.auth.reset_password_failed,
                "error"
              );
            }
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            this.logout();
          }
          this.is_loading = false;
          this.$refs.MessageShow.showMessage(
            this.localization.fa.auth.login_failed,
            "error"
          );
          // TO DO:
          // show an error message when login is not successful
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
  created() {
    if (this.isAuthenticated()) {
      this.goToDashboardPage();
    }
    // now check the lang
    if (this.getLocale() === "fa") {
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }
    //
    this.activateProgInterval();
  },
};
</script>

<style scoped>
.no-letter-spacing {
  letter-spacing: 0;
}

.fa-numeric {
  font-family: IRANSans;
}
</style>
