<template>
  <v-container
    class="my-4"
    v-if="$vuetify.rtl && $router.currentRoute.name.split('-')[0] === 'fa'"
  >
    <v-row class="justify-center align-start">
      <v-card
        :max-width="
          $vuetify.breakpoint.mdAndDown
            ? `${$vuetify.breakpoint.width * 0.9}`
            : '1000'
        "
        outlined
        :class="{
          'custom-card-border-light': !$vuetify.theme.dark,
          'custom-card-border-dark': $vuetify.theme.dark,
        }"
      >
        <v-card-title class="about-title">{{ title.fa }}</v-card-title>
        <v-card-text class="about-text text-justify">
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"
                ><strong>اخبار ارزهای دیجیتال</strong></span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">اخبار سایت، روزانه از&nbsp;</span>۶۰
            <span lang="fa-IR"
              >منبع فارسی و انگلیسی استخراج و در سایت نمایش داده
              می&zwnj;شود</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">هر باکس خبر شامل موارد زیر است</span>:
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="fa-IR">عنوان خبر</span>:
            <span lang="fa-IR"
              >با کلیک روی عنوان به صفحه خبر ارجاع داده خواهید شد</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="ar-SA"><span lang="fa-IR">لوگوی منبع خبر</span></span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="ar-SA"><span lang="fa-IR">زمان انتشار</span></span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="fa-IR">اشتراک&zwnj;گذاری</span>:
            <span lang="fa-IR"
              >با کلیک روی این دکمه، می&zwnj;توانید خبر را با دیگران به اشتراک
              بگذارید</span
            >.
            <span lang="fa-IR"
              >هر فردی با کلیک روی لینک ارسالی به سایت زاگرس لایو ارجاع داده
              خواهد شد و خبر با کادر سبز رنگ به او نمایش داده می&zwnj;شود</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="ar-SA"><span lang="fa-IR">میزان بازدید خبر</span></span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="fa-IR">خبر مهم</span>:
            <span lang="fa-IR"
              >کاربران با توجه به عنوان و مضمون خبر می&zwnj;توانند برای اطلاع
              سایر کاربران، با امتیازدهی، اهمیت و تاثیر آن خبر را در بازار مشخص
              کنند</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="fa-IR">سیگنال صعودی</span>:
            <span lang="fa-IR"
              >کاربران با توجه به عنوان و مضمون خبر با کلیک بر روی این دکمه
              می&zwnj;توانند نظر مثبت خود را اعلام کنند</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
            class="mb-4"
          >
            - <span lang="fa-IR">سیگنال نزولی</span>:
            <span lang="fa-IR"
              >کاربران با توجه به عنوان و مضمون خبر با کلیک بر روی این دکمه
              می&zwnj;توانند نظر منفی خود را اعلام کنند</span
            >.
          </p>
          <v-row justify="center" align="center" class="mb-4">
            <v-col cols="12" sm="8" md="6" lg="8">
              <v-img
                contain
                src="https://static.zagros.live/images/mesc/help-fa/news-fa.png"
              ></v-img>
            </v-col>
          </v-row>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"><strong>فیلتر اخبار</strong></span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >کاربران وبسایت می توانند از قسمت سمت چپ یا راست&nbsp;</span
            >(<span lang="fa-IR">بسته به زبان سایت</span>)
            <span lang="fa-IR"
              >فیلتر اختصاصی خود را بر اساس ارزهای دیجیتال، منابع خبر فارسی و
              انگلیسی انجام دهند</span
            >.
            <span lang="fa-IR"
              >محدودیت انتخاب گزینه&zwnj;ها جهت فیلتر اخبار برای کاربر مهمان
              بصورت&nbsp;</span
            >(<span lang="fa-IR"
              >یک ارز دیجیتال، یک منبع خبری فارسی، یک منبع خبری انگلیسی</span
            >) <span lang="fa-IR">است</span>.
            <span lang="fa-IR"
              >کاربر در صورت نیاز به دسترسی نامحدود باید ابتدا در سایت
              ثبت&zwnj;نام کند</span
            >.
          </p>

          <v-row justify="center" align="center" class="mb-4">
            <v-col cols="12" sm="8" md="5">
              <v-img
                contain
                src="https://static.zagros.live/images/mesc/help-fa/filter-fa.png"
              ></v-img>
            </v-col>
          </v-row>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            id="isPasted"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"><strong>جستجو</strong></span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >کاربر با ثبت حرف، واژه یا عبارت در قسمت&nbsp;</span
            >&quot;<span lang="fa-IR">جستجو بر اساس عنوان</span>&quot;
            <span lang="fa-IR"
              >می&zwnj;تواند کلمه موردنظر خود را از میان عناوین خبری پیدا
              کند</span
            >.
            <span lang="fa-IR"
              >در صورت عدم وجود خبر با عنوان مورد جستجو، علامت اخطار با
              عنوان&nbsp;</span
            >&quot;<span lang="fa-IR">خبری یافت نشد</span>&quot;
            <span lang="fa-IR">به کاربر نمایش داده خواهد شد</span>.
          </p>
          <v-row justify="center" align="center" class="mb-4">
            <v-col cols="12" sm="8" md="6">
              <v-img
                contain
                src="https://static.zagros.live/images/mesc/help-fa/search-fa.png"
              ></v-img>
            </v-col>
          </v-row>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            id="isPasted"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"
                ><strong>مرتب&zwnj;سازی اخبار</strong></span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">کاربر می&zwnj;تواند اخبار را بر اساس&nbsp;</span
            >&quot;<span lang="fa-IR">آخرین اخبار</span>&quot;<span lang="fa-IR"
              >،&nbsp;</span
            >&quot;<span lang="fa-IR">تعداد بازدید</span>&quot;<span
              lang="fa-IR"
              >، مهمترین اخبار</span
            >&quot;<span lang="fa-IR">،&nbsp;</span>&quot;<span lang="fa-IR"
              >اخبار با بیشترین سیگنال مثبت</span
            >&quot; <span lang="fa-IR">و&nbsp;</span>&quot;<span lang="fa-IR"
              >اخبار با بیشترین سیگنال منفی</span
            >&quot; <span lang="fa-IR">مرتب&zwnj;سازی کند</span>.
          </p>
          <v-row justify="center" align="center" class="mb-4">
            <v-col cols="12" sm="8" md="5">
              <v-img
                contain
                src="https://static.zagros.live/images/mesc/help-fa/sort-fa.png"
              ></v-img>
            </v-col>
          </v-row>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            id="isPasted"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"><strong>اخبار فارسی</strong></span
            ><strong>/</strong
            ><span lang="fa-IR"><strong>انگلیسی&nbsp;</strong></span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >کاربر برای دسترسی به اخبار فارسی و انگلیسی کافیست بر روی
              دکمه&zwnj;هایی با همین نام در سایت کلیک کند</span
            >.
          </p>
          <v-row justify="center" align="center" class="mb-4">
            <v-col cols="12" sm="8" md="8">
              <v-img
                contain
                src="https://static.zagros.live/images/mesc/help-fa/tab-fa.png"
              ></v-img> </v-col
          ></v-row>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            id="isPasted"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"
                ><strong>دسترسی به تمامی اخبار</strong></span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">تعداد&nbsp;</span>10
            <span lang="fa-IR">خبر در هر صفحه سایت نمایش داده می&zwnj;شود</span
            >.
            <span lang="fa-IR"
              >کاربر برای مشاهده سایر خبرها می&zwnj;تواند در انتهای صفحه به سایر
              صفحات دسترسی پیدا کند</span
            >.
            <span lang="fa-IR"
              >صفحه اول سایت برای نمایش آخرین اخبار، به تناوب در بازه&zwnj;های
              زمانی کوتاه به&zwnj;روزرسانی می&zwnj;شود</span
            >.
          </p>
          <v-row justify="center" align="center" class="mb-4">
            <v-col cols="12" sm="8" md="6">
              <v-img
                contain
                src="https://static.zagros.live/images/mesc/help-fa/access-fa.png"
              ></v-img>
            </v-col>
          </v-row>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            id="isPasted"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"
                ><strong>دسترسی به صفحه اصلی سایت</strong></span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >کاربر با کلیک بر روی لوگوی سایت که در گوشه بالا، سمت چپ قابل
              مشاهده است و یا واژه "خانه" در نوار پایین صفحه می‌تواند به صفحه
              اصلی سایت دسترسی پیدا کند.</span
            >
          </p>
          <div class="mb-4">
            <v-img
              src="https://static.zagros.live/images/mesc/help-fa/home-fa.png"
              height="50px"
              width="50px"
            ></v-img>
          </div>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            id="isPasted"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"><strong>ثبت نام</strong></span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >شما می&zwnj;توانید با تکمیل فرم ثبت&zwnj;نام و پس از تایید آن از
              طریق ایمیل ارسالی زاگرس لایو، عضو سایت زاگرس لایو شوید</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >کاربر پس از ثبت&zwnj;نام از طریق حساب کاربری می&zwnj;تواند نسبت
              به تغییر رمز عبور اقدام نماید</span
            >.
          </p>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="8" md="5">
              <v-img
                contain
                src="https://static.zagros.live/images/mesc/help-fa/signup-fa.png"
              ></v-img>
            </v-col>
          </v-row>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            id="isPasted"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"><strong>حساب کاربری</strong></span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >کاربر با کلیک بر روی دکمه زیر می‌تواند به صفحه کاربری دسترسی پیدا
              کند. در صورتی که ثبت نام کرده‌اید، می‌توانید با ثبت ایمیل یا نام
              کاربری و رمز عبور به حساب کاربری خود دسترسی پیدا کنید و در غیر این
              صورت باید ابتدا فرآیند ثبت‌نام را انجام دهید.</span
            >
          </p>
          <div class="mb-4">
            <v-img
              src="https://static.zagros.live/images/mesc/help-fa/account-fa.png"
              height="50px"
              width="50px"
            ></v-img>
          </div>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            id="isPasted"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"><strong>تغییر رمز عبور</strong></span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >کاربر پس از ورود به حساب کاربری، در قسمت تغییر رمز عبور، با ثبت
              رمز جدید می&zwnj;تواند رمز خود را تغییر دهد</span
            >.
            <span lang="fa-IR"
              >کاربر پس از ورود به حساب کاربری، در قسمت تغییر رمز عبور، با ثبت
              رمز جدید می‌تواند رمز خود را تغییر دهد. در صورتی که رمز عبور خود
              را فراموش کردید، می‌توانید در بخش ورود به حساب با کلیک بر روی
              عبارت "رمز عبور خود را فراموش کرده‌اید” و ثبت ایمیل، به حساب
              کاربری خود دسترسی پیدا کنید.</span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"
                ><strong>تغییر رنگ پس&zwnj;زمینه</strong></span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >کاربر با کلیک بر روی دکمه زیر می&zwnj;تواند رنگ پس&zwnj;زمینه
              سایت را تیره یا روشن کند</span
            >.
          </p>
          <div class="mb-4">
            <v-img
              src="https://static.zagros.live/images/mesc/help-fa/dark-light-fa.png"
              height="50px"
              width="50px"
            ></v-img>
          </div>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            id="isPasted"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"><strong>تغییر زبان</strong></span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >کاربر با کلیک بر روی دکمه زیر می تواند زبان را فارسی کند</span
            >.
          </p>
          <div class="mb-4">
            <v-img
              src="https://static.zagros.live/images/mesc/help-fa/flag-fa.png"
              height="50px"
              width="50px"
            ></v-img>
          </div>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            id="isPasted"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"
                ><strong>تابلوی قیمت ارزهای دیجیتال</strong></span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 198%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">نوار متحرک بالای سایت، آخرین قیمت&nbsp;</span>20
            <span lang="fa-IR"
              >ارز برتر بازار ارزهای دیجیتال را به کاربر نمایش می&zwnj;دهد</span
            >.
          </p>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="8" md="8">
              <v-img
                contain
                src="https://static.zagros.live/images/mesc/help-fa/marquee-fa.png"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
  <v-container class="my-4" v-else>
    <v-row class="justify-center align-start">
      <v-card
        :max-width="
          $vuetify.breakpoint.mdAndDown
            ? `${$vuetify.breakpoint.width * 0.9}`
            : '1000'
        "
        outlined
        :class="{
          'custom-card-border-light': !$vuetify.theme.dark,
          'custom-card-border-dark': $vuetify.theme.dark,
        }"
      >
        <v-card-title class="about-title">{{ title.en }}</v-card-title>
        <v-card-text class="about-text text-justify">
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <strong>Cryptocurrency Market News</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            Site news is extracted daily from ۶۰ Persian and English sources and
            displayed on the site.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            Each news box contains the following:
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            - News title: By clicking on the title, you will be referred to the
            news page.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            - News source logo
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            - Publication date
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            - Share: By clicking this button, you can share the news with
            others. Each person will be referred to the Zagros Live site by
            clicking on the link and the news will be displayed to him with a
            green box.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            - Number of views
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            - Important news: Depending on the title and content of the news,
            users can click on the important button to inform others about the
            impact of that news in the market.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            - Bullish signal: Users can express their positive opinion by
            clicking on this button according to the title and content of the
            news.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            - Bearish signal: Users can express their negative opinion by
            clicking on this button according to the title and content of the
            news.
          </p>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="8" md="6" lg="8">
              <v-img
                src="https://static.zagros.live/images/mesc/help-en/news-en.png"
                contain
              ></v-img>
            </v-col>
          </v-row>
          <p
            align="center"
            style="
              margin-bottom: 0.11in;
              direction: ltr;
              line-height: 1.08px;
              background-image: initial;
              background-position: initial;
              background-size: initial;
              background-repeat: initial;
              background-attachment: initial;
              background-origin: initial;
              background-clip: initial;
            "
          >
            <br />
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <strong>News Filter</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            Website users can perform their own filter based on
            cryptocurrencies, Persian, and English news sources from the left or
            right side (depending on the language of the site). The limitation
            on selecting options for the guest user is (one cryptocurrencies,
            one Persian news sources, one English news sources). If the user
            needs unlimited access, he must first register on the site.
          </p>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="8" md="5">
              <v-img
                contain
                src="https://static.zagros.live/images/mesc/help-en/filter-en.png"
              ></v-img>
            </v-col>
          </v-row>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <br />
          </p>
          <p
            id="isPasted"
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <strong>Search</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            By writing a letter, word, or phrase in the &quot;Search by
            title&quot; section, the user can find the word among the news
            headlines. If there is no news with the title you are looking for, a
            warning sign titled &quot;Not found&quot; will be displayed to the
            user.
          </p>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="8" md="6">
              <v-img
                contain
                src="https://static.zagros.live/images/mesc/help-en/search-en.png"
              ></v-img>
            </v-col>
          </v-row>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <br />
          </p>
          <p
            id="isPasted"
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <strong>News Sorting</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            The user can sort the news by &quot;latest news&quot;, &quot;number
            of views&quot;, most important news, &quot;news with the most
            bullish signal&quot; and &quot;news with the most bearish
            signal&quot;.
          </p>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="8" md="4">
              <v-img
                contain
                src="https://static.zagros.live/images/mesc/help-en/sort-en.png"
              ></v-img>
            </v-col>
          </v-row>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <br />
          </p>
          <p
            id="isPasted"
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <strong>Farsi / English News</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            To access Persian and English news, the user just clicks on buttons
            with the same name on the site.
          </p>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="8" md="8">
              <v-img
                contain
                src="https://static.zagros.live/images/mesc/help-en/tab-en.png"
              ></v-img> </v-col
          ></v-row>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <br />
          </p>
          <p
            id="isPasted"
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <strong>Access to All News</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            10 news items are displayed on each page of the site. The user can
            access other pages at the bottom of the page to view other news. The
            front page of the site is updated periodically to display the latest
            news at short intervals.
          </p>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="8" md="6">
              <v-img
                contain
                src="https://static.zagros.live/images/mesc/help-en/access-en.png"
              ></v-img>
            </v-col>
          </v-row>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <br />
          </p>
          <p
            id="isPasted"
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <strong>Access to Home Page</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            The user can access the home page by clicking on the site logo which
            is visible in the upper left corner.
          </p>
          <v-img
            src="https://static.zagros.live/images/mesc/help-en/home-en.png"
            height="50px"
            width="50px"
          ></v-img>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <br />
          </p>
          <p
            id="isPasted"
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <strong>Signing up</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            You can become a member of the Zagros Live site by completing the
            signup form and after confirming it via the email sent by Zagros
            Live. After login, the user can change the password.
          </p>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="8" md="5">
              <v-img
                contain
                src="https://static.zagros.live/images/mesc/help-en/signup-en.png"
              ></v-img>
            </v-col>
          </v-row>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <br />
          </p>
          <p
            id="isPasted"
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <strong>User Account</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            The user can access the profile page by clicking the button below.
            If you signed up, you can access your account by submitting your
            username and password, otherwise, you will have to go through the
            signup process first.
          </p>
          <v-img
            src="https://static.zagros.live/images/mesc/help-en/account-en.png"
            height="50px"
            width="50px"
          ></v-img>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <br />
          </p>
          <p
            id="isPasted"
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <strong>Reset Password</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            After logging in to the user account, in the password change
            section, the user can change his password by submitting a new
            password. If you forget your password, you can access your account
            via the link in the login section by clicking on the option to
            forget your password and send your email.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <br />
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <strong>Light/Dark Mode</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            The user can change the dark or light mode of the site by clicking
            the button below.
          </p>
          <v-img
            src="https://static.zagros.live/images/mesc/help-en/dark-light-en.png"
            height="50px"
            width="50px"
          ></v-img>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <br />
          </p>
          <p
            id="isPasted"
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <strong>Set Language</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            The user can change the language to english by clicking on the below
            button.
          </p>
          <v-img
            src="https://static.zagros.live/images/mesc/help-en/flag-en.png"
            height="50px"
            width="50px"
          ></v-img>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <br />
          </p>
          <p
            id="isPasted"
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            <strong>Cryptocurrency Price Bar</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 188%;
              text-align: left;
              background: transparent;
            "
          >
            The moving bar at the top of the site shows the user the latest
            prices of the top 20 currencies in the cryptocurrency market.
          </p>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="8" md="8">
              <v-img
                contain
                src="https://static.zagros.live/images/mesc/help-en/marquee-en.png"
              ></v-img>
            </v-col>
          </v-row>
          <p
            align="center"
            style="
              margin-bottom: 0.11in;
              direction: ltr;
              line-height: 1.08px;
              background-image: initial;
              background-position: initial;
              background-size: initial;
              background-repeat: initial;
              background-attachment: initial;
              background-origin: initial;
              background-clip: initial;
            "
          >
            <br />
          </p>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$vuetify.rtl ? "راهنما" : "Help",
      meta: [
        {
          name: "twitter:imagesrc",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          name: "twitter:site",
          content: "Zagros Live",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:title",
          content: this.$vuetify.rtl ? "راهنما" : "Help",
        },
        {
          name: "twitter:description",
          content: "How to Use Zagros",
        },
        {
          property: "og:image",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          property: "og:site_name",
          content: "Zagros Live",
        },
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: this.$vuetify.rtl ? "راهنما" : "Help",
        },
        { property: "og:site_name", content: "Zagros Live" },
        {
          property: "og:description",
          content: "How to Use Zagros",
        },
        {
          property: "og:url",
          content: "https://zagros.live" + this.$router.currentRoute.path,
        },
      ],
    };
  },
  name: "help",
  data: () => ({
    title: {
      fa: "راهنما",
      en: "Help",
    },
  }),
  methods: {
    isLangFa: function () {
      if (this.$router.currentRoute.name.split("-")[0] === "fa") {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
$primary-light-color: #146a5f;
$primary-dark-color: #404548;

.about-text {
  line-height: 1.8;
  font-size: 1.1rem;
}

.about-title {
  font-size: 1.8rem;
}

.custom-card-border-light {
  border-color: $primary-light-color;
}

.custom-card-border-dark {
  border-color: $primary-dark-color;
}

p {
  font-size: 0.95rem;
}

strong {
  font-size: 1.2rem;
}
</style>
