import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import Vuex from 'vuex';
import VueMeta from 'vue-meta';
import Axios from "axios";
import authMixin from "./mixins/authMixin";
import store from './store';

//
Vue.config.productionTip = false;
//
Axios.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = "";

    if (authMixin.methods.isAuthenticated()) {
      config.headers["Authorization"] =
        "Token " + authMixin.methods.getUserAuthenticationToken();
    }
    // "application/json"
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Vue.prototype.$axios = Axios

// 
Vue.use(VueCookies)
Vue.use(Vuex)
Vue.use(VueMeta)


new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
