export default {
  data: () => ({
    Coins: [{
        en: 'Bitcoin',
        fa: 'بیت کوین',
        id: 4001,
        symbol: 'BTC'
      },
      {
        en: 'Ethereum',
        fa: 'اتریوم',
        id: 4002,
        symbol: 'ETH'
      },
      {
        en: 'Binance Coin',
        fa: 'بایننس کوین',
        id: 4003,
        symbol: 'BNB'
      },
      {
        en: 'Cardano',
        fa: 'کاردانو',
        id: 4004,
        symbol: 'ADA'
      },
      {
        en: 'Tether',
        fa: 'تتر',
        id: 4005,
        symbol: 'USDT'
      },
      {
        en: 'XRP',
        fa: 'ریپل',
        id: 4006,
        symbol: 'XRP'
      },
      {
        en: 'Solana',
        fa: 'سولانا',
        id: 4007,
        symbol: 'SOL'
      },
      {
        en: 'Polkadot',
        fa: 'دات',
        id: 4008,
        symbol: 'DOT'
      },
      {
        en: 'USD Coin',
        fa: 'یو اس دی کوین',
        id: 4009,
        symbol: 'USDC'
      },
      {
        en: 'Dogecoin',
        fa: 'دوج کوین',
        id: 4010,
        symbol: 'DOGE'
      },
      {
        en: 'Uniswap',
        fa: 'یونی سواپ',
        id: 4011,
        symbol: 'UNI'
      },
      {
        en: 'Terra',
        fa: 'ترا',
        id: 4012,
        symbol: 'LUNA'
      },
      {
        en: 'Binance USD',
        fa: 'دلار بایننس',
        id: 4013,
        symbol: 'BUSD'
      },
      {
        en: 'Litecoin',
        fa: 'لایت کوین',
        id: 4014,
        symbol: 'LTC'
      },
      {
        en: 'Avalanche',
        fa: 'آوالانچ',
        id: 4015,
        symbol: 'AVAX'
      },
      {
        en: 'Chainlink',
        fa: 'چین کلینک',
        id: 4016,
        symbol: 'LINK'
      },
      {
        en: 'Bitcoin Cash',
        fa: 'بیت کوین کش',
        id: 4017,
        symbol: 'BCH'
      },
      {
        en: 'SHIBA INU',
        fa: 'شیبا',
        id: 4018,
        symbol: 'SHIB'
      },
      {
        en: 'Algorand',
        fa: 'الگو',
        id: 4019,
        symbol: 'ALGO'
      },
      {
        en: 'Polygon',
        fa: 'متیک',
        id: 4020,
        symbol: 'MATIC'
      },
      {
        en: 'Stellar',
        fa: 'استلار',
        id: 4021,
        symbol: 'XLM'
      },
      {
        en: 'VeChain',
        fa: 'وی چین',
        id: 4022,
        symbol: 'VET'
      },
      {
        en: 'Internet Computer',
        fa: 'اینترنت کامپیوتر',
        id: 4023,
        symbol: 'ICP'
      },
      {
        en: 'Axie Infinity',
        fa: 'اکسی اینفنیتی',
        id: 4024,
        symbol: 'AXS'
      },
      {
        en: 'Filecoin',
        fa: 'فایل کوین',
        id: 4025,
        symbol: 'FIL'
      },
      {
        en: 'Cosmos',
        fa: 'اتم',
        id: 4026,
        symbol: 'ATOM'
      },
      {
        en: 'TRON',
        fa: 'ترون',
        id: 4027,
        symbol: 'TRX'
      },
      {
        en: 'Ethereum Classic',
        fa: 'اتریوم کلاسیک',
        id: 4028,
        symbol: 'ETC'
      },
      {
        en: 'FTX Token',
        fa: 'توکن اف تی اکس',
        id: 4029,
        symbol: 'FTT'
      },
      {
        en: 'Dai',
        fa: 'دای',
        id: 4030,
        symbol: 'DAI'
      },
      {
        en: 'Bitcoin BEP2',
        fa: 'بیت کوین بایننس',
        id: 4031,
        symbol: 'BTCB'
      },
      {
        en: 'THETA',
        fa: 'تتا',
        id: 4032,
        symbol: 'THETA'
      },
      {
        en: 'Fantom',
        fa: 'فانتوم',
        id: 4033,
        symbol: 'FTM'
      },
      {
        en: 'Tezos',
        fa: 'تزوس',
        id: 4034,
        symbol: 'XTZ'
      },
      {
        en: 'Hedera',
        fa: 'هدرا',
        id: 4035,
        symbol: 'HBAR'
      },
      {
        en: 'Crypto.com Coin',
        fa: 'کریپتو کوین',
        id: 4036,
        symbol: 'CRO'
      },
      {
        en: 'Monero',
        fa: 'مونرو',
        id: 4037,
        symbol: 'XMR'
      },
      {
        en: 'PancakeSwap',
        fa: 'پنکیک سواپ',
        id: 4038,
        symbol: 'CAKE'
      },
      {
        en: 'Elrond',
        fa: 'الراند',
        id: 4039,
        symbol: 'EGLD'
      },
      {
        en: 'Flow',
        fa: 'فلو',
        id: 4040,
        symbol: 'FLOW'
      },
      {
        en: 'EOS',
        fa: 'ایاس',
        id: 4041,
        symbol: 'EOS'
      },
      {
        en: 'NEAR Protocol',
        fa: 'نی یر پروتکل',
        id: 4042,
        symbol: 'NEAR'
      },
      {
        en: 'Klaytn',
        fa: 'کلایتن',
        id: 4043,
        symbol: 'KLAY'
      },
      {
        en: 'Aave',
        fa: 'آوه',
        id: 4044,
        symbol: 'AAVE'
      },
      {
        en: 'eCash',
        fa: 'ای کش',
        id: 4045,
        symbol: 'XEC'
      },
      {
        en: 'The Graph',
        fa: 'گراف',
        id: 4046,
        symbol: 'GRT'
      },
      {
        en: 'Quant',
        fa: 'کوانت',
        id: 4047,
        symbol: 'QNT'
      },
      {
        en: 'IOTA',
        fa: 'ایوتا',
        id: 4048,
        symbol: 'MIOTA'
      },
      {
        en: 'Stacks',
        fa: 'استکس',
        id: 4049,
        symbol: 'STX'
      },
      {
        en: 'Bitcoin SV',
        fa: 'بیتکوین اس وی',
        id: 4050,
        symbol: 'BSV'
      },
      {
        en: 'Kusama',
        fa: 'کوزاما',
        id: 4051,
        symbol: 'KSM'
      },
      {
        en: 'Neo',
        fa: 'نئو',
        id: 4052,
        symbol: 'NEO'
      },
      {
        en: 'Waves',
        fa: 'ویوز',
        id: 4053,
        symbol: 'WAVES'
      },
      {
        en: 'UNUS SED LEO',
        fa: 'اونوس لئو',
        id: 4054,
        symbol: 'LEO'
      },
      {
        en: 'TerraUSD',
        fa: 'یو اس دی ترا',
        id: 4055,
        symbol: 'UST'
      },
      {
        en: 'Maker',
        fa: 'میکر',
        id: 4056,
        symbol: 'MKR'
      },
      {
        en: 'BitTorrent',
        fa: 'بیت تورنت',
        id: 4057,
        symbol: 'BTT'
      },
      {
        en: 'Harmony',
        fa: 'هارمونی وان',
        id: 4058,
        symbol: 'ONE'
      },
      {
        en: 'Helium',
        fa: 'هلیم',
        id: 4059,
        symbol: 'HNT'
      },
      {
        en: 'Amp',
        fa: 'آمپ',
        id: 4060,
        symbol: 'AMP'
      },
      {
        en: 'Celo',
        fa: 'سلو',
        id: 4061,
        symbol: 'CELO'
      },
      {
        en: 'OMG Network',
        fa: 'ا ام جی',
        id: 4062,
        symbol: 'OMG'
      },
      {
        en: 'Dash',
        fa: 'دش',
        id: 4063,
        symbol: 'DASH'
      },
      {
        en: 'Chiliz',
        fa: 'چیلیز',
        id: 4064,
        symbol: 'CHZ'
      },
      {
        en: 'Compound',
        fa: 'کامپاند',
        id: 4065,
        symbol: 'COMP'
      },
      {
        en: 'Zcash',
        fa: 'زی کش',
        id: 4066,
        symbol: 'ZEC'
      },
      {
        en: 'THORChain',
        fa: 'تورچین',
        id: 4067,
        symbol: 'RUNE'
      },
      {
        en: 'Arweave',
        fa: 'آرویو',
        id: 4068,
        symbol: 'AR'
      },
      {
        en: 'Holo',
        fa: 'هولوچین',
        id: 4069,
        symbol: 'HOT'
      },
      {
        en: 'Decred',
        fa: 'دیسرد',
        id: 4070,
        symbol: 'DCR'
      },
      {
        en: 'NEM',
        fa: 'نم',
        id: 4071,
        symbol: 'XEM'
      },
      {
        en: 'Revain',
        fa: 'ری وین',
        id: 4072,
        symbol: 'REV'
      },
      {
        en: 'Theta Fuel',
        fa: 'تتا فیول',
        id: 4073,
        symbol: 'TFUEL'
      },
      {
        en: 'SushiSwap',
        fa: 'سوشی سواپ',
        id: 4074,
        symbol: 'SUSHI'
      },
      {
        en: 'Decentraland',
        fa: 'دیسنترالند',
        id: 4075,
        symbol: 'MANA'
      },
      {
        en: 'Enjin Coin',
        fa: 'اینجین کوین',
        id: 4076,
        symbol: 'ENJ'
      },
      {
        en: 'ICON',
        fa: 'آیکون',
        id: 4077,
        symbol: 'ICX'
      },
      {
        en: 'XDC Network',
        fa: 'اکس دی سی نتورک',
        id: 4078,
        symbol: 'XDC'
      },
      {
        en: 'TrueUSD',
        fa: 'یو اس دی ترو',
        id: 4079,
        symbol: 'TUSD'
      },
      {
        en: 'Celsius',
        fa: 'سلسیوس',
        id: 4080,
        symbol: 'CEL'
      },
      {
        en: 'yearn.finance',
        fa: 'یرن فایننس',
        id: 4081,
        symbol: 'YFI'
      },
      {
        en: 'Huobi Token',
        fa: 'توکن هوبی',
        id: 4082,
        symbol: 'HT'
      },
      {
        en: 'Qtum',
        fa: 'کوانتوم',
        id: 4083,
        symbol: 'QTUM'
      },
      {
        en: 'Bitcoin Gold',
        fa: 'بیت کوین گلد',
        id: 4084,
        symbol: 'BTG'
      },
      {
        en: 'Curve DAO Token',
        fa: 'کرو',
        id: 4085,
        symbol: 'CRV'
      },
      {
        en: 'Perpetual Protocol',
        fa: 'پروتکل پرپتوال',
        id: 4086,
        symbol: 'PERP'
      },
      {
        en: 'dYdX',
        fa: 'دی وای دی اکس',
        id: 4087,
        symbol: 'DYDX'
      },
      {
        en: 'Zilliqa',
        fa: 'زیلیکا',
        id: 4088,
        symbol: 'ZIL'
      },
      {
        en: 'Mina',
        fa: 'مینا',
        id: 4089,
        symbol: 'MINA'
      },
      {
        en: 'Synthetix',
        fa: 'سینتتیکس',
        id: 4090,
        symbol: 'SNX'
      },
      {
        en: 'Telcoin',
        fa: 'تلکوین',
        id: 4091,
        symbol: 'TEL'
      },
      {
        en: 'Ravencoin',
        fa: 'راونکوین',
        id: 4092,
        symbol: 'RVN'
      },
      {
        en: 'renBTC',
        fa: 'بیتکوین رن',
        id: 4093,
        symbol: 'RENBTC'
      },
      {
        en: 'KuCoin Token',
        fa: 'کوکوین',
        id: 4094,
        symbol: 'KCS'
      },
      {
        en: 'Basic Attention Token',
        fa: 'بت',
        id: 4095,
        symbol: 'BAT'
      },
      {
        en: 'OKB',
        fa: 'او کی بی',
        id: 4096,
        symbol: 'OKB'
      },
      {
        en: 'Ren',
        fa: 'رن',
        id: 4097,
        symbol: 'REN'
      },
      {
        en: 'Nexo',
        fa: 'نکسو',
        id: 4098,
        symbol: 'NEXO'
      },
      {
        en: 'Pax Dollar',
        fa: 'دلار پکس',
        id: 4099,
        symbol: 'USDP'
      },
      {
        en: 'Serum',
        fa: 'سرم',
        id: 4100,
        symbol: 'SRM'
      },
      {
        en: 'Horizen',
        fa: 'هورایزن',
        id: 4101,
        symbol: 'ZEN'
      },
      {
        en: 'Bancor',
        fa: 'بنکور',
        id: 4102,
        symbol: 'BNT'
      },
      {
        en: 'IOST',
        fa: 'آی او اس تی',
        id: 4103,
        symbol: 'IOST'
      },
      {
        en: 'NuCypher',
        fa: 'نوسایفر',
        id: 4104,
        symbol: 'NU'
      },
      {
        en: 'Siacoin',
        fa: 'سایاکوین',
        id: 4105,
        symbol: 'SC'
      },
      {
        en: 'Mdex',
        fa: 'ام دکس',
        id: 4106,
        symbol: 'MDX'
      },
      {
        en: '0x',
        fa: 'زیرو اکس',
        id: 4107,
        symbol: 'ZRX'
      },
      {
        en: 'Audius',
        fa: 'آدیوس',
        id: 4108,
        symbol: 'AUDIO'
      },
      {
        en: 'Ontology',
        fa: 'آنتولوژی',
        id: 4109,
        symbol: 'ONT'
      },
      {
        en: 'Celer Network',
        fa: 'سلر',
        id: 4110,
        symbol: 'CELR'
      },
      {
        en: 'Ankr',
        fa: 'انکر',
        id: 4111,
        symbol: 'ANKR'
      },
      {
        en: 'SKALE Network',
        fa: 'اسکیل نتورک',
        id: 4112,
        symbol: 'SKL'
      },
      {
        en: 'DigiByte',
        fa: 'دیجی بایت',
        id: 4113,
        symbol: 'DGB'
      },
      {
        en: 'Nano',
        fa: 'نانو',
        id: 4114,
        symbol: 'NANO'
      },
      {
        en: 'Secret',
        fa: 'سکرت',
        id: 4115,
        symbol: 'SCRT'
      },
      {
        en: 'UMA',
        fa: 'یو ام ای',
        id: 4116,
        symbol: 'UMA'
      },
      {
        en: 'Raydium',
        fa: 'ری دیوم',
        id: 4117,
        symbol: 'RAY'
      },
      {
        en: 'Voyager Token',
        fa: 'وویجر',
        id: 4118,
        symbol: 'VGX'
      },
      {
        en: 'The Sandbox',
        fa: 'سندباکس',
        id: 4119,
        symbol: 'SAND'
      },
      {
        en: 'IoTeX',
        fa: 'آیوتکس',
        id: 4120,
        symbol: 'IOTX'
      },
      {
        en: 'WOO Network',
        fa: 'وو نتورک',
        id: 4121,
        symbol: 'WOO'
      },
      {
        en: '1inch Network',
        fa: 'وان اینچ',
        id: 4122,
        symbol: '1INCH'
      },
      {
        en: 'SwissBorg',
        fa: 'سوئیس بورگ',
        id: 4123,
        symbol: 'CHSB'
      },
      {
        en: 'Loopring',
        fa: 'لوپرینگ',
        id: 4124,
        symbol: 'LRC'
      },
      {
        en: 'Dent',
        fa: 'دنت',
        id: 4125,
        symbol: 'DENT'
      },
      {
        en: 'Neutrino USD',
        fa: 'دلار نوترینو',
        id: 4126,
        symbol: 'USDN'
      },
      {
        en: 'Fetch.ai',
        fa: 'فچ',
        id: 4127,
        symbol: 'FET'
      },
      {
        en: 'Livepeer',
        fa: 'لایوپیر',
        id: 4128,
        symbol: 'LPT'
      },
      {
        en: 'WAX',
        fa: 'وکس',
        id: 4129,
        symbol: 'WAXP'
      },
      {
        en: 'Golem',
        fa: 'گولم',
        id: 4130,
        symbol: 'GLM'
      },
      {
        en: 'Kava',
        fa: 'کاوا',
        id: 4131,
        symbol: 'KAVA'
      },
      {
        en: 'Nervos Network',
        fa: 'نرووس نتورک',
        id: 4132,
        symbol: 'CKB'
      },
      {
        en: 'Ocean Protocol',
        fa: 'اوشن پروتکل',
        id: 4133,
        symbol: 'OCEAN'
      },
      {
        en: 'Gnosis',
        fa: 'جنوسیس',
        id: 4134,
        symbol: 'GNO'
      },
      {
        en: 'WINkLink',
        fa: 'وینک',
        id: 4135,
        symbol: 'WIN'
      },
      {
        en: 'HUSD',
        fa: 'اچ یو اس دی',
        id: 4136,
        symbol: 'HUSD'
      },
      {
        en: 'Bitcoin Standard',
        fa: 'بیتکوین استاندارد',
        id: 4137,
        symbol: 'BTCST'
      },
      {
        en: 'Function X',
        fa: 'فانکشن اکس',
        id: 4138,
        symbol: 'FX'
      },
      {
        en: 'Alpha Finance Lab',
        fa: 'آلفا فایننس',
        id: 4139,
        symbol: 'ALPHA'
      },
      {
        en: 'Reserve Rights',
        fa: 'رزرو رایتز',
        id: 4140,
        symbol: 'RSR'
      },
      {
        en: 'Reef',
        fa: 'ریف',
        id: 4141,
        symbol: 'REEF'
      },
      {
        en: 'DigitalBits',
        fa: 'دیجیتال بیتز',
        id: 4142,
        symbol: 'XDB'
      },
      {
        en: 'Storj',
        fa: 'استورج',
        id: 4143,
        symbol: 'STORJ'
      },
      {
        en: 'Numeraire',
        fa: 'نومریر',
        id: 4144,
        symbol: 'NMR'
      },
      {
        en: 'Swipe',
        fa: 'سوایپ',
        id: 4145,
        symbol: 'SXP'
      },
      {
        en: 'Lisk',
        fa: 'لیسک',
        id: 4146,
        symbol: 'LSK'
      },
      {
        en: 'Polymath',
        fa: 'پلی مت',
        id: 4147,
        symbol: 'POLY'
      },
      {
        en: 'Fei Protocol',
        fa: 'فی پروتکل',
        id: 4148,
        symbol: 'FEI'
      },
      {
        en: 'COTI',
        fa: 'کوتی',
        id: 4149,
        symbol: 'COTI'
      },
      {
        en: 'XYO',
        fa: 'اکس وای او',
        id: 4150,
        symbol: 'XYO'
      },
      {
        en: 'WazirX',
        fa: 'وزیر اکس',
        id: 4151,
        symbol: 'WRX'
      },
      {
        en: 'Bitcoin Diamond',
        fa: 'بیتکوین دایاموند',
        id: 4152,
        symbol: 'BCD'
      },
      {
        en: 'Injective Protocol',
        fa: 'اینجکتیو پروتکل',
        id: 4153,
        symbol: 'INJ'
      },
      {
        en: 'Verge',
        fa: 'ورج',
        id: 4154,
        symbol: 'XVG'
      },
      {
        en: 'VeThor Token',
        fa: 'وی تور',
        id: 4155,
        symbol: 'VTHO'
      },
      {
        en: 'BakeryToken',
        fa: 'بیکری',
        id: 4156,
        symbol: 'BAKE'
      },
      {
        en: 'MediBloc',
        fa: 'مدی بلاک',
        id: 4157,
        symbol: 'MED'
      },
      {
        en: 'iExec RLC',
        fa: 'آی اکسک آر ال سی',
        id: 4158,
        symbol: 'RLC'
      },
      {
        en: 'NKN',
        fa: 'ان کی ان',
        id: 4159,
        symbol: 'NKN'
      },
      {
        en: 'Orch',
        fa: 'ارکید',
        id: 4160,
        symbol: 'OXT'
      },
      {
        en: 'GateToken',
        fa: 'گیت توکن',
        id: 4161,
        symbol: 'GT'
      },
      {
        en: 'Conflux',
        fa: 'کانفلاکس',
        id: 4162,
        symbol: 'CFX'
      },
      {
        en: 'Cartesi',
        fa: 'کارتسی',
        id: 4163,
        symbol: 'CTSI'
      },
      {
        en: 'Energy Web Token',
        fa: 'انرژی وب',
        id: 4164,
        symbol: 'EWT'
      },
      {
        en: 'Ergo',
        fa: 'ارگو',
        id: 4165,
        symbol: 'ERG'
      },
      {
        en: 'PAX Gold',
        fa: 'پکس گلد',
        id: 4166,
        symbol: 'PAXG'
      },
      {
        en: 'aelf',
        fa: 'الف',
        id: 4167,
        symbol: 'ELF'
      },
      {
        en: 'Civic',
        fa: 'سیویک',
        id: 4168,
        symbol: 'CVC'
      },
      {
        en: 'Status',
        fa: 'استتوس',
        id: 4169,
        symbol: 'SNT'
      },
      {
        en: 'SingularityNET',
        fa: 'سینگیولاریتی',
        id: 4170,
        symbol: 'AGIX'
      },
      {
        en: 'Venus',
        fa: 'ونوس',
        id: 4171,
        symbol: 'XVS'
      },
      {
        en: 'Unibright',
        fa: 'یونی برایت',
        id: 4172,
        symbol: 'UBT'
      },
      {
        en: 'Ardor',
        fa: 'آردر',
        id: 4173,
        symbol: 'ARDR'
      },
      {
        en: 'Prometeus',
        fa: 'پرامتئوس',
        id: 4174,
        symbol: 'PROM'
      },
      {
        en: 'Velas',
        fa: 'ولاس',
        id: 4175,
        symbol: 'VLX'
      },
      {
        en: 'StormX',
        fa: 'استورم اکس',
        id: 4176,
        symbol: 'STMX'
      },
      {
        en: 'ASD',
        fa: 'ای اس دی',
        id: 4177,
        symbol: 'ASD'
      },
      {
        en: 'Ontology Gas',
        fa: 'آنتولوژی گس',
        id: 4178,
        symbol: 'ONG'
      },
      {
        en: 'Hive',
        fa: 'هایو',
        id: 4179,
        symbol: 'HIVE'
      },
      {
        en: 'Band Protocol',
        fa: 'پروتکل بند',
        id: 4180,
        symbol: 'BAND'
      },
      {
        en: 'Origin Protocol',
        fa: 'پروتکل اوریجین',
        id: 4181,
        symbol: 'OGN'
      },
      {
        en: 'Oasis Network',
        fa: 'رز',
        id: 4182,
        symbol: 'ROSE'
      },
      {
        en: 'Badger DAO',
        fa: 'بجر',
        id: 4183,
        symbol: 'BADGER'
      },
      {
        en: 'Stratis',
        fa: 'استریتس',
        id: 4184,
        symbol: 'STRAX'
      },
      {
        en: 'Casper',
        fa: 'کسپر',
        id: 4185,
        symbol: 'CSPR'
      },
      {
        en: 'MyNeighborAlice',
        fa: 'آلیس',
        id: 4186,
        symbol: 'ALICE'
      },
      {
        en: 'Verasity',
        fa: 'وراسیتی',
        id: 4187,
        symbol: 'VRA'
      },
      {
        en: 'Orbs',
        fa: 'اوربس',
        id: 4188,
        symbol: 'ORBS'
      },
      {
        en: 'Ark',
        fa: 'آرک',
        id: 4189,
        symbol: 'ARK'
      },
      {
        en: 'Augur',
        fa: 'آگر',
        id: 4190,
        symbol: 'REP'
      },
      {
        en: 'Syscoin',
        fa: 'سیسکوین',
        id: 4191,
        symbol: 'SYS'
      },
      {
        en: 'Constellation',
        fa: 'کانستیلیشن',
        id: 4192,
        symbol: 'DAG'
      },
      {
        en: 'Enzyme',
        fa: 'انزیم',
        id: 4193,
        symbol: 'MLN'
      },
      {
        en: 'TomoChain',
        fa: 'توموچین',
        id: 4194,
        symbol: 'TOMO'
      },
      {
        en: 'Polkastarter',
        fa: 'پولکااستارتر',
        id: 4195,
        symbol: 'POLS'
      },
      {
        en: 'LuaSwap',
        fa: 'لوا',
        id: 4196,
        symbol: 'LUA'
      },
      {
        en: 'Steem',
        fa: 'استیم',
        id: 4197,
        symbol: 'STEEM'
      },
      {
        en: 'HEX',
        fa: 'هکس',
        id: 4198,
        symbol: 'Hex'
      },
      {
        en: 'FUNToken',
        fa: 'توکن فان',
        id: 4199,
        symbol: 'FUN'
      },
      {
        en: 'Proton',
        fa: 'پروتون',
        id: 4200,
        symbol: 'XPR'
      }
    ]
  })
}