<template>
  <v-container class="fill-height">
    <v-layout row align-center justify-space-around>
      <v-flex xs12 sm12 md6 lg6 xl4>
        <!-- Login form here -->
        <v-card flat tile :color="$vuetify.theme.dark ? style.main.bg_color.dark : style.main.bg_color.light">
          <h1 :style="`color: ${$vuetify.theme.dark
  ? style.login.primary_dark
  : style.login.primary
            };
                      font-size: 2.3rem;
                      font-weight: 700;
                      text-align: center;`" class="my-6">
            {{
              $vuetify.rtl
              ? localization.fa.reset_password.title
              : localization.en.reset_password.title
            }}
          </h1>
          <v-form class="mx-3" ref="resetPassForm">
            <v-text-field type="text" v-model="user.email" :label="$vuetify.rtl ? 'ایمیل' : 'Email'" dir="ltr" outlined
              :rules="emailRules" :color="
                $vuetify.theme.dark
                  ? style.reset_password.secondary
                  : style.reset_password.primary
              " />
            <v-btn block depressed dark :color="style.reset_password.primary" :loading="is_loading"
              v-on:click.prevent="sendResetPasswordRequest" :class="{ 'no-letter-spacing': $vuetify.rtl }">{{
                $vuetify.rtl
                ? localization.fa.reset_password.title
                : localization.en.reset_password.title
              }}</v-btn>
          </v-form>
          <v-card-actions class="mt-3">
            <v-spacer></v-spacer>
            <div v-text="
              $vuetify.rtl
                ? localization.fa.login.no_account_msg
                : localization.en.login.no_account_msg
            " class="mx-2"></div>
            <span @click.prevent="goToSignUpPage()" v-text="
              $vuetify.rtl
                ? localization.fa.login.signup_msg
                : localization.en.login.signup_msg
            " style="font-size: 0.8rem; cursor: pointer; color: #30d237">
            </span>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <message-show ref="MessageShow"></message-show>
  </v-container>
</template>

<script>
import APIMixin from "../../mixins/apiMixin";
import AuthMixin from "../../mixins/authMixin";
import styleMixin from "../../mixins/styleMixin";
import utils from "../../mixins/utils";
import localizationMixin from "../../mixins/localeMixin";
import MessageShow from "../../components/MessageShow.vue";

export default {
  metaInfo() {
    return {
      title: this.$vuetify.rtl
        ? this.localization.fa.reset_password.title
        : this.localization.en.reset_password.title,
      meta: [
        {
          name: "description",
          content: "Reset your password",
        },
        {
          property: "og:title",
          content: this.$vuetify.rtl
            ? this.localization.fa.reset_password.title
            : this.localization.en.reset_password.title,
        },
        { property: "og:site_name", content: "Zagros Live" },
        {
          property: "og:description",
          content: "Reset your password",
        },
        {
          property: "og:url",
          content: "https://zagros.live" + this.$router.currentRoute.path,
        },
      ],
    };
  },
  name: "ResetPasswordPage",
  components: {
    MessageShow,
  },
  mixins: [APIMixin, AuthMixin, localizationMixin, styleMixin, utils],
  data: () => ({
    user: { email: "" },
    is_loading: false,
  }),
  computed: {
    emailRules() {
      const rules = [];
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const rule = (value) => pattern.test(value) || this.emailErrors();
      const reqRule = (value) => !!value || this.requiredErrors();
      //
      rules.push(rule);
      rules.push(reqRule);
      //
      return rules;
    },
  },
  methods: {
    goToSignUpPage: function () {
      this.$router.push({ name: "signup" });
    },
    goToDashboardPage: function () {
      this.$router.push({ name: "dashboard" });
    },
    goToResetPassworPage: function () {
      this.$router.push({ name: "reset_password" });
    },
    sendResetPasswordRequest: function () {
      if (this.$refs.resetPassForm.validate()) {
        this.is_loading = true;
        let data = {
          email: this.user.email,
          lang: this.$vuetify.rtl ? "fa" : "en",
        };
        // sending login request
        this.$axios
          .post(this.user_api.password_reset, data, { withCredentials: true })
          .then((response) => {
            if (response.data.status) {
              this.$router.push({
                name: "login_after_reset_password",
                params: { redirect_email: data.email },
              });
            } else {
              this.$refs.MessageShow.showMessage(
                this.$vuetify.rtl
                  ? this.localization.fa.auth.reset_password_failed
                  : this.localization.en.auth.reset_password_failed,
                "error"
              );
            }
          })
          .catch((e) => {
            if (e.response.status === 401) {
              this.logout();
            }
            this.is_loading = false;
            this.$refs.MessageShow.showMessage(
              this.localization.fa.auth.login_failed,
              "error"
            );
            // TO DO:
            // show an error message when login is not successful
          })
          .finally(() => {
            this.is_loading = false;
          });
      }
    },
    requiredErrors: function () {
      return this.$vuetify.rtl
        ? "این فیلد اجباری است"
        : "this field is required";
    },
    emailErrors: function () {
      return this.$vuetify.rtl
        ? "ایمیل به درستی وارد نشده است"
        : "email format is not correct";
    },
  },
  created() {
    if (this.isAuthenticated()) {
      this.goToDashboardPage();
    }
    // now check the lang
    if (this.getLocale() === "fa") {
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }
  },
};
</script>

<style scoped>
.no-letter-spacing {
  letter-spacing: 0;
}
</style>
