<template>
  <v-container class="fill-height">
    <v-layout row align-center justify-space-around>
      <v-flex xs12 sm12 md6 lg6 xl4>
        <!-- Login form here -->
        <v-card flat tile :color="$vuetify.theme.dark ? style.main.bg_color.dark : style.main.bg_color.light">
          <h1 :style="`color: ${$vuetify.theme.dark
  ? style.login.primary_dark
  : style.login.primary
            };
                                              font-size: 2.3rem;
                                              font-weight: 700;
                                              text-align: center;`" class="my-6">
            {{
              $vuetify.rtl
              ? localization.fa.login.title
              : localization.en.login.title
            }}
          </h1>
          <v-form class="mx-3">
            <div class="pa-0" v-if="!$vuetify.rtl">
              <v-text-field type="text" v-model="user.username" :label="localization.en.login.username_or_email" outlined
                dir="ltr" :color="style.login.secondary" prepend-inner-icon="mdi-account"
                @keydown.enter="sendLoginRequest" />
              <v-text-field :append-icon="hide_password ? 'mdi-eye' : 'mdi-eye-off'"
                :type="hide_password ? 'password' : 'text'" v-model="user.password"
                :label="localization.en.login.password" outlined :color="style.login.secondary"
                prepend-inner-icon="mdi-key" dir="ltr" class="text-capitalize"
                @click:append="hide_password = !hide_password" @keydown.enter="sendLoginRequest" />
            </div>
            <div class="pa-0" v-else>
              <v-text-field type="text" v-model="user.username" :label="localization.fa.login.username_or_email" outlined
                dir="ltr" :color="style.login.secondary" append-icon="mdi-account" @keydown.enter="sendLoginRequest" />
              <v-text-field :prepend-inner-icon="hide_password ? 'mdi-eye' : 'mdi-eye-off'"
                :type="hide_password ? 'password' : 'text'" v-model="user.password"
                :label="localization.fa.login.password" outlined :color="style.login.secondary" append-icon="mdi-key"
                dir="ltr" class="text-capitalize" @click:prepend-inner="hide_password = !hide_password"
                @keydown.enter="sendLoginRequest" />
            </div>
            <v-btn block depressed dark :color="style.login.primary" :loading="is_loading"
              v-on:click.prevent="sendLoginRequest" :class="{ 'no-letter-spacing': $vuetify.rtl }">{{
                $vuetify.rtl
                ? localization.fa.login.title
                : localization.en.login.title
              }}</v-btn>
            <!-- authenticate using third-parties -->
            <v-card-actions>
              <v-spacer />
              <v-divider class="me-2" />
              <span> {{ $vuetify.rtl ? "یا" : "or" }} </span>
              <v-divider class="ms-2" />
              <v-spacer />
            </v-card-actions>
            <!-- using google -->
            <v-card-actions>
              <v-spacer />
              <google-auth-wrapper></google-auth-wrapper>
              <v-spacer />
            </v-card-actions>
            <!--  -->
          </v-form>
          <v-card-actions class="mt-3">
            <v-spacer></v-spacer>
            <div v-text="
              $vuetify.rtl
                ? localization.fa.login.no_account_msg
                : localization.en.login.no_account_msg
            " class="mx-2"></div>
            <span @click.prevent="goToSignUpPage()" v-text="
              $vuetify.rtl
                ? localization.fa.login.signup_msg
                : localization.en.login.signup_msg
            " style="font-size: 0.8rem; cursor: pointer; color: #30d237">
            </span>
            <v-spacer></v-spacer>
          </v-card-actions>
          <!-- forgot password section -->
          <v-card-actions class="mt-n1">
            <v-spacer></v-spacer>
            <span @click.prevent="goToResetPassworPage()" v-text="
              $vuetify.rtl
                ? localization.fa.login.forgot_password_msg
                : localization.en.login.forgot_password_msg
            " style="font-size: 0.8rem; cursor: pointer">
            </span>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <message-show ref="MessageShow"></message-show>
  </v-container>
</template>

<script>
import APIMixin from "../mixins/apiMixin";
import AuthMixin from "../mixins/authMixin";
import styleMixin from "../mixins/styleMixin";
import utils from "../mixins/utils";
import localizationMixin from "../mixins/localeMixin";
import MessageShow from "../components/MessageShow.vue";
import GoogleAuthWrapper from "../components/GoogleAuthWrapper.vue";

export default {
  metaInfo() {
    return {
      title: this.$vuetify.rtl
        ? this.localization.fa.login.title
        : this.localization.en.login.title,
      meta: [
        {
          name: "twitter:image:src",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          name: "twitter:site",
          content: "Zagros Live",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:title",
          content: this.$vuetify.rtl
            ? this.localization.fa.login.title
            : this.localization.en.login.title,
        },
        {
          name: "twitter:description",
          content: "Login",
        },
        {
          property: "og:image",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          property: "og:site_name",
          content: "Zagros Live",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: this.$vuetify.rtl
            ? this.localization.fa.login.title
            : this.localization.en.login.title,
        },
        {
          property: "og:description",
          content: "Login",
        },
        {
          property: "og:url",
          content: "https://zagros.live" + this.$router.currentRoute.path,
        },
      ],
    };
  },
  name: "Login",
  components: {
    MessageShow,
    GoogleAuthWrapper
  },
  mixins: [APIMixin, AuthMixin, localizationMixin, styleMixin, utils],
  data: () => ({
    user: { username: "", password: "" },
    is_loading: false,
    hide_password: true,
  }),
  methods: {
    goToSignUpPage: function () {
      this.$router.push({ name: "signup" });
    },
    goToDashboardPage: function () {
      this.$router.push({ name: "dashboard" });
    },
    goToResetPassworPage: function () {
      this.$router.push({ name: "reset_password" });
    },
    sendLoginRequest: function () {
      this.is_loading = true;
      // create code verifier

      let data = {
        username: this.user.username,
        password: this.user.password,
      };
      // sending login request
      this.$axios
        .post(this.user_api.login, data, { withCredentials: true })
        .then((response) => {
          this.setUserIsLoggedIn(true);
          this.setUserAuthenticationToken(response.data.token);
          this.$refs.MessageShow.showMessage(
            this.localization.fa.auth.login_succed,
            "success"
          );
          this.$router.push("/");
        })
        .catch((e) => {
          // this.logout();
          if (e.response.status === 400) {
            this.is_loading = false;
            this.$refs.MessageShow.showMessage(
              this.localization.fa.auth.login_failed,
              "error"
            );
          }
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
  mounted() {
    // check if it is redirected
    // from reset password or not
    if (this.$router.currentRoute.params.redirect_email != undefined) {
      this.user.username = this.$router.currentRoute.params.redirect_email;
      this.$refs.MessageShow.showMessage(
        this.$vuetify.rtl
          ? this.localization.fa.auth.reset_password_succed
          : this.localization.en.auth.reset_password_succed,
        "success"
      );
    }
  },
  created() {
    // console.log(this.isAuthenticated());
    if (this.isAuthenticated()) {
      this.goToDashboardPage();
    }
    // now check the lang
    if (this.getLocale() === "fa") {
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }
  },
};
</script>

<style scoped>
.no-letter-spacing {
  letter-spacing: 0;
}
</style>