<template>
  <v-container
    fill-height
    v-if="$vuetify.rtl && $router.currentRoute.path.split('/')[1] === 'fa'"
  >
    <v-row class="ma-2 justify-center align-start">
      <v-card
        max-width="1000"
        outlined
        :class="{
          'custom-card-border-light': !$vuetify.theme.dark,
          'custom-card-border-dark': $vuetify.theme.dark,
        }"
      >
        <v-card-title class="about-title">{{ title.fa }}</v-card-title>
        <v-card-text class="about-text text-justify">
          <p
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
            "
          >
            <span lang="fa-IR">با تشکر از بازدید شما</span>.
            <br />
            <span lang="fa-IR"
              >اگر سوال یا نظری دارید لطفا از طریق ایمیل زیر با ما در ارتباط
              باشید.</span
            >
            <br />
            <a href="mailto:info@zagros.live" target="_blank"
              >info@zagros.live</a
            >
            <br />
            <span lang="fa-IR"
              >همچنین اگر می&zwnj;خواهید درباره زاگرس لایو اطلاعات بیشتری کسب
              کنید، صفحه
              <router-link
                :to="{ name: 'fa-about' }"
                style="text-decoration: none"
                >درباره ما</router-link
              >
              را مشاهده نمایید</span
            >.
          </p>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
  <v-container fill-height v-else>
    <v-row class="ma-2 justify-center align-start" dir="ltr">
      <v-card
        max-width="1000"
        outlined
        :class="{
          'custom-card-border-light': !$vuetify.theme.dark,
          'custom-card-border-dark': $vuetify.theme.dark,
        }"
      >
        <v-card-title class="about-title">{{ title.en }}</v-card-title>
        <v-card-text class="about-text text-justify">
          <div class="mt-2"></div>
          Thanks for visiting Zagros Live. <br />
          If you have any questions or comments please send an email to:
          <br />
          <a href="mailto:info@zagros.live" target="_blank">info@zagros.live</a>
          <br />
          If you want to find out more about Zagros Live, check out our
          <router-link :to="{ name: 'en-about' }" style="text-decoration: none"
            >about</router-link
          >
          page.<br />
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$vuetify.rtl ? "تماس با ما" : "Contact",
      meta: [
        {
          name: "twitter:image:src",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          name: "twitter:site",
          content: "Zagros Live",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:title",
          content: this.$vuetify.rtl ? "تماس با ما" : "Contact",
        },
        {
          name: "twitter:description",
          content: "Contact Us",
        },
        {
          property: "og:image",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          property: "og:site_name",
          content: "Zagros Live",
        },
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: this.$vuetify.rtl ? "تماس با ما" : "Contact",
        },
        { property: "og:site_name", content: "Zagros Live" },
        {
          property: "og:description",
          content: "Contact Us",
        },
        {
          property: "og:url",
          content: "https://zagros.live" + this.$router.currentRoute.path,
        },
      ],
    };
  },
  name: "contact",
  data: () => ({
    title: {
      fa: "تماس با ما",
      en: "Contact",
    },
  }),
  methods: {
    isLangFa: function () {
      if (this.$router.currentRoute.name.split("-")[0] === "fa") {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    if (this.$router.currentRoute.path.split("/")[1] === "en") {
      this.$vuetify.rtl = false;
    } else {
      this.$vuetify.rtl = true;
    }
  },
};
</script>

<style lang="scss" scoped>
$primary-light-color: #146a5f;
$primary-dark-color: #404548;

.about-text {
  line-height: 1.8;
  font-size: 1.1rem;
}

.about-title {
  font-size: 1.8rem;
}

.custom-card-border-light {
  border-color: $primary-light-color;
}

.custom-card-border-dark {
  border-color: $primary-dark-color;
}

p {
  font-size: 0.95rem;
}
</style>
