var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height"},[_c('v-layout',{attrs:{"row":"","align-center":"","justify-space-around":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":"","lg6":"","xl4":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('h1',{staticClass:"my-6",style:(("color: " + (_vm.$vuetify.theme.dark
          ? _vm.style.login.primary_dark
          : _vm.style.login.primary) + ";\n                                font-size: 1.8rem;\n                                font-weight: 700;\n                                text-align: center;"))},[_vm._v(" "+_vm._s(_vm.$vuetify.rtl ? _vm.localization.fa.signup.resend_confirmation_title : _vm.localization.en.signup.resend_confirmation_title)+" ")]),_c('v-card-actions',{staticClass:"mt-3"},[_c('v-spacer'),_c('div',{staticClass:"mx-2",domProps:{"textContent":_vm._s(
            _vm.$vuetify.rtl
              ? _vm.localization.fa.signup.resend_confirmation_msg
              : _vm.localization.en.signup.resend_confirmation_msg
          )}}),(_vm.countdown_finished)?_c('span',{staticStyle:{"font-size":"0.8rem","cursor":"pointer","color":"#30d237"},domProps:{"textContent":_vm._s(
            _vm.$vuetify.rtl
              ? _vm.localization.fa.signup.resend_confirmation_action
              : _vm.localization.en.signup.resend_confirmation_action
          )},on:{"click":function($event){$event.preventDefault();return _vm.resendConfirmationEmail.apply(null, arguments)}}}):_c('span',{class:{ 'fa-numeric': _vm.$vuetify.rtl },staticStyle:{"font-size":"0.88rem","cursor":"pointer","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$vuetify.rtl ? _vm.timer + " ثانیه" : _vm.timer + " s")+" ")]),_c('v-spacer')],1)],1)],1)],1),_c('message-show',{ref:"MessageShow"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }