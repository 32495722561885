<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" md="10" lg="9" xl="7" class="mt-4">
        <v-img src="https://ucarecdn.com/e272b259-f04c-46f7-a81e-6fc827c81a38/">
        </v-img>
      </v-col>
    </v-row>
    <!--  -->
    <v-row justify="center" class="my-0 py-0">
      <v-card-title style="color: #156b5f; font-weight: bold">
        {{
          $vuetify.rtl
            ? "صفحه موردنظر یافت نشد"
            : "Unfortunately we couldn't find the page you were looking for"
        }}
      </v-card-title>
    </v-row>
  </v-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$vuetify.rtl ? "یافت نشد" : "Not Found",
      meta: [
        {
          name: "description",
          content: "Not Found",
        },
        {
          property: "og:title",
          content: this.$vuetify.rtl ? "یافت نشد" : "Not Found",
        },
        {
          property: "og:description",
          content: "Not Found",
        },
        {
          property: "og:url",
          content: "https://zagros.live" + this.$router.currentRoute.path,
        },
      ],
    };
  },
  methods: {
    isMobileScreen: function () {
      const breakpoint = this.$vuetify.breakpoint;
      if (
        breakpoint.name === "sm" ||
        breakpoint.name === "md" ||
        breakpoint.name === "xs"
      ) {
        if (breakpoint.height <= 1000 && breakpoint.width <= 700) {
          return 1;
        }
        return 0;
      } else {
        return 0;
      }
    },
    goToMainPage: function () {
      this.$router.push("/dashboard");
    },
  },
};
</script>

<style scoped>
</style>
