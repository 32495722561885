<template>
  <div style="font-family: IRanSans">
    <canvas :id="verbose"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import styleMixin from "../mixins/styleMixin";

export default {
  name: "CustomChart",
  props: ["labels", "dataset", "chart_type", "verbose"],
  mixins: [styleMixin],
  data: () => ({
    chartData: {
      type: "doughnut",
      data: {
        datasets: null,
      },
      options: {
        responsive: true,
        aspectRatio: 0.5,
        legend: {
          display: false,
        },
      },
    },
  }),
  watch: {
    labels() {
      // assigning configurations
      this.chartData.data.labels = this.labels;
      //
      // document.getElementById(this.verbose).remove();
      //
      const ctx = document.getElementById(this.verbose);
      new Chart(ctx, this.chartData);
    },
  },
  mounted() {
    // assigning configurations
    this.chartData.type = this.chart_type;
    this.chartData.data.labels = this.labels;
    this.chartData.data.datasets = new Array(this.dataset);

    // adjusting aspectRatio
    if (this.$vuetify.breakpoint.smAndDown) {
      this.chartData.options.aspectRatio = 1.3;
    } else {
      this.chartData.options.aspectRatio = 1.4;
    }
    //
    const ctx = document.getElementById(this.verbose);
    new Chart(ctx, this.chartData);
  },
  created() {
    // initializing backgroundColor and borderColor
    this.dataset.backgroundColor = new Array();
    this.dataset.borderColor = new Array();
    this.dataset.borderWidth = 1;

    // initializing the colors
    if (this.chart_type === "doughnut" || this.chart_type === "pie") {
      for (let i = 0; i < this.labels.length; ++i) {
        this.dataset.backgroundColor.push(
          this.style.chart.color_palette_68[
            i % this.style.chart.color_palette_68.length
          ]
        );
      }
    } else {
      for (let i = 0; i < this.labels.length; ++i) {
        this.dataset.backgroundColor.push(
          this.style.chart.color_palette_20[
            i % this.style.chart.color_palette_20.length
          ]
        );
        this.dataset.borderColor.push(
          this.style.chart.border_palette[
            i % this.style.chart.border_palette.length
          ]
        );
      }
    }
  },
};
</script>

<style scoped></style>

