<template>
  <v-container fluid>
    <v-row class="justify-center">
      <v-col cols="12">
        <!-- <header-commercial></header-commercial> -->
        <live-crypto-price class="mt-4"></live-crypto-price>
      </v-col>
    </v-row>
    <v-row class="justify-center align-center" :class="{ 'mb-2': !$vuetify.rtl }">
      <v-col cols="12" lg="8" xl="9">
        <v-text-field v-model="search" append-icon="mdi-magnify" :placeholder="locale === 'fa'
            ? localization.fa.search_bar
            : localization.en.search_bar + ' (press /)'
          " outlined hide-details ref="searchBar" color="searchbar" @keydown.enter="getFilteredNews()"></v-text-field>
      </v-col>
      <v-col cols="12" lg="4" xl="3">
        <v-select outlined hide-details v-model="sort_by" @change="getFilteredNews()" class="custom-scrollbar"
          item-text="title" item-value="sort_index" item-color="sort_by_item" color="primary" :placeholder="locale === 'fa'
              ? localization.fa.news.sort.title
              : localization.en.news.sort.title
            " :label="locale === 'fa'
      ? localization.fa.news.sort.title
      : localization.en.news.sort.title
    " :items="locale === 'fa'
      ? localization.fa.news.sort.items
      : localization.en.news.sort.items
    ">
        </v-select>
      </v-col>
    </v-row>
    <v-row class="justify-space-around align-center mb-2" :class="{ 'hidden-by-lang': !$vuetify.rtl }">
      <v-col cols="6">
        <v-btn block v-text="locale === 'fa'
            ? localization.fa.news.lang_tabs.fa
            : localization.en.news.lang_tabs.fa
          " :dark="btn_lang_model === 1 ? true : false" :class="{
      'no-letter-spacing lang-tabs-fa-text-md lang-tabs-fa-text-lg':
        locale === 'fa',
      'lang-tabs-en-text-md lang-tabs-en-text-lg': locale === 'en',
    }" :style="`${checkDarkAndLocale(1)};`" :color="currentRoute() === 'fa' ? 'primary' : '#F5F5F5'"
          @click.prevent="changeLocale(1)"></v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn block :dark="btn_lang_model === 2 ? true : false" v-text="locale === 'fa'
            ? localization.fa.news.lang_tabs.en
            : localization.en.news.lang_tabs.en
          " :class="{
      'no-letter-spacing lang-tabs-fa-text-md lang-tabs-fa-text-lg':
        locale === 'fa',
      'lang-tabs-en-text-md lang-tabs-en-text-lg': locale === 'en',
    }" :style="`${checkDarkAndLocale(2)};`" :color="currentRoute() === 'en' ? 'primary' : '#F5F5F5'"
          @click.prevent="changeLocale(2)"></v-btn>
      </v-col>
    </v-row>
    <!-- ads -->
    <!-- <template>
      <v-container fluid class="ma-0 pa-0">
        <ad-card :locale="NewsTabLang()"></ad-card>
      </v-container>
    </template> -->
    <template v-if="fetched_news_finished && news.length">
      <v-container fluid class="ma-0 pa-0" v-for="(item, i) in news" :key="i">
        <news-card :news="item" :locale="NewsTabLang()" v-if="item.source.locale === currentRoute() && item != undefined">
        </news-card>
        <!-- <side-commercial
          :offset="(i + 1) / 3 + 1"
          v-if="(i + 1) % 3 == 0 && $vuetify.breakpoint.smAndDown"
        /> -->
      </v-container>
    </template>
    <!--  -->
    <template v-if="(!fetched_news_finished && is_loading) || news === null">
      <v-skeleton-loader v-for="n in 10" :key="n" type="list-item-avatar-three-line"
        :dir="NewsTabLang() === 'fa' ? 'rtl' : 'ltr'" class="mb-4"></v-skeleton-loader>
    </template>
    <!--  -->
    <template v-else>
      <div v-if="news.length == 0">
        <v-container v-if="locale === 'fa'" fluid>
          <v-alert outlined type="error" prominent border="left">
            <div>
              {{ localization.fa.news.not_found_msg }}
            </div>
          </v-alert>
        </v-container>
        <v-container v-else fluid>
          <v-alert outlined type="error" prominent border="right">
            <div style="margin-left: 15px">
              {{ localization.en.news.not_found_msg }}
            </div>
          </v-alert>
        </v-container>
      </div>
    </template>
  </v-container>
</template>

<script>
import apiMixin from "../mixins/apiMixin";
import authMixin from "../mixins/authMixin";
import styleMixin from "../mixins/styleMixin";
import utils from "../mixins/utils";
import localeMixin from "../mixins/localeMixin";
import NewsCard from "../components/NewsCard.vue";
// import AdCard from "../components/AdCard.vue";
// import HeaderCommercial from "./commercial/HeaderCommercial.vue";
import LiveCryptoPrice from "../components/LiveCryptoPrice.vue";
// import SideCommercial from "./commercial/SideCommercial.vue";
// import SideFilterBox from "../components/SideFilterBox.vue";
// import assetStack from "../components/assetStack.vue";

const SEARCHBAR_LATENCY_TIMER_VAL = 75;

export default {
  name: "NewsList",
  props: ["locale"],
  metaInfo() {
    return {
      title: this.$vuetify.rtl ? "زاگرس لایو" : "Zagros Live",
      meta: [
        {
          name: "twitter:image:src",
          content:
            "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/zg.png",
        },
        {
          name: "twitter:site",
          content: "Zagros Live",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:title",
          content: this.$vuetify.rtl ? "زاگرس لایو" : "Zagros Live",
        },
        {
          name: "twitter:description",
          content: "Latest Crypto News",
        },
        {
          property: "og:image",
          content:
            "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/zg.png",
        },
        {
          property: "og:site_name",
          content: "Zagros Live",
        },
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: this.$vuetify.rtl ? "زاگرس لایو" : "Zagros Live",
        },
        {
          property: "og:url",
          content: "https://zagros.live" + this.$router.currentRoute.path,
        },
      ],
    };
  },
  components: {
    NewsCard,
    // AdCard,
    // HeaderCommercial,
    LiveCryptoPrice,
    // SideCommercial,
    //  SideFilterBox
  },
  mixins: [apiMixin, styleMixin, localeMixin, authMixin, utils],
  data: () => ({
    news: null,
    asset_filter_list: null,
    fa_source_filter_list: null,
    en_source_filter_list: null,
    is_loading: false,
    last_news_request: Date.now(),
    model: 1,
    btn_lang_model: 1,
    search: "",
    page: 1,
    sort_by: null,
    total_pages: 10,
    polling: null,
    polling_interval: 60000 * 30,
    slug_news: null,
    fetched_news_finished: false,
    timer_value: 75,
    is_searchbar_activated: false,
    notif_opts: {},
  }),
  methods: {
    activateProgInterval: function () {
      //Create a timeout every 100 miliseconds
      setTimeout(() => {
        //Increment the time counter by 100
        this.timer_value -= 25;

        //If our current time is larger than the timeout
        if (this.timer_value === 0) {
          //Wait 500 miliseconds for the dom to catch up, then reset the snackbar
          setTimeout(() => {
            this.timer_value = SEARCHBAR_LATENCY_TIMER_VAL; // reset the current time
            this.getFilteredNews();
            this.is_searchbar_activated = false;
          }, 200);
        } else {
          //Recursivly update the progress bar
          this.activateProgInterval();
        }
      }, 600);
    },
    getCurrentPage: function () {
      let page = this.getPage();
      if (page != null) {
        this.page = page;
      }
      return this.page;
    },
    NewsTabLang: function () {
      return this.btnlang_to_locale[this.btn_lang_model];
    },
    checkDarkAndLocale: function (currentLocale) {
      const situation = this.currentRoute() === "en" ? 2 : 1;
      if (this.$vuetify.theme.dark) {
        if (situation != currentLocale) {
          return "color: #2A2A2A";
        }
      }
    },
    changeBtnLangModel: function (lang_order) {
      this.btn_lang_model = lang_order;
      this.setNewsLocale(lang_order);
      if (parseInt(this.$route.query.page) !== this.page) {
        this.page = this.$route.query.page;
      }
      this.changePage(this.$route.query.page ? this.$route.query.page : 1);
      if (!this.$route.query.news_locale)
        this.$router.replace({
          query: {
            ...this.$route.query,
            ...{ news_locale: this.btnlang_to_locale[lang_order] },
          },
        });
      // this.getFilteredNews();
    },
    changeLocale: function (lang_order) {
      if (this.btn_lang_model != lang_order) {
        this.btn_lang_model = lang_order;
        this.setNewsLocale(lang_order);

        this.slug_news = null;
        this.$router.replace({
          query: { news_locale: this.btnlang_to_locale[lang_order] },
        });
        //
        this.changePage(1);
        this.getFilteredNews();
      }
    },
    getFilteredNews: function () {
      this.is_loading = true;
      this.fetched_news_finished = false;
      var data = {
        assets: this.asset_filter_list,
        sources:
          this.btn_lang_model == 1
            ? this.fa_source_filter_list
            : this.en_source_filter_list,
        page: this.$route.query.page
          ? parseInt(this.$route.query.page)
          : this.page,
        query: this.search,
        sort_by: this.sort_by,
        lang: this.btnlang_to_locale[this.btn_lang_model],
        slug_uid: this.$router.currentRoute.query.news_share_id
          ? { news_slug: this.$router.currentRoute.query.news_share_id }
          : {},
        category: this.$route.params.category,
      };
      //
      let total_pages = -1;
      //
      this.$axios
        .post(this.api.news_filter, data, { withCredentials: true })
        .then((res) => {
          this.news = res.data.data.news;
          total_pages = res.data.data.num_pages;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            this.clearAuthCredit();
          }
          this.is_loading = false;
          this.fetched_news_finished = true;
        })
        .finally(() => {
          // if the slug news was not null
          //
          if (this.slug_news != null) {
            this.news.unshift(this.slug_news);
            this.news = this.news.slice(0, 10);
          }
          // set page
          if (total_pages != -1) {
            this.$emit("handle-max-page", total_pages);
          }
          this.is_loading = false;
          this.fetched_news_finished = true;
          this.setLastRequestDate(Date.now());
          // replace the url
          let route_query = this.$route.query;
          if (this.search)
            this.$router.replace({
              path: this.$route.name.includes("results")
                ? this.$route.path
                : `${this.$route.path}/results`,
              query: {
                ...route_query,
                ...{ search_query: this.search },
              },
            });
          else {
            const route_query = this.$route.query;
            delete route_query.search_query;
            if (this.$route.name.includes("results"))
              this.$router.replace({
                name: this.$route.name.slice(0, this.$route.name.length - 8),
                query: route_query,
              });
          }
        });
    },
    getNewsSideFilter: function (asset_list, fa_source_list, en_source_list, page) {
      this.is_loading = true;
      this.fetched_news_finished = false;
      // let refresh = false;
      if (!this.isArrayEqual(this.asset_filter_list, asset_list)) {
        // refresh = true;
        this.asset_filter_list = asset_list;
        this.$store.commit("changeAssets", asset_list);
      }
      if (!this.isArrayEqual(this.fa_source_filter_list, fa_source_list)) {
        // refresh = true;
        this.fa_source_filter_list = fa_source_list;
        this.$store.commit("changeFaSources", fa_source_list);
      }
      if (!this.isArrayEqual(this.en_source_filter_list, en_source_list)) {
        // refresh = true;
        this.en_source_filter_list = en_source_list;
        this.$store.commit("changeEnSources", en_source_list);
      }

      var data = {
        assets: asset_list,
        sources: this.btn_lang_model == 1 ? fa_source_list : en_source_list,
        page: page,
        query: this.search,
        sort_by: this.sort_by,
        lang: this.btn_lang_model == 1 ? "fa" : "en",
        slug_uid: this.$router.currentRoute.query.news_share_id
          ? { news_slug: this.$router.currentRoute.query.news_share_id }
          : {},
        category: this.$route.params.category,
      };
      //
      let total_pages = -1;

      //
      this.$axios
        .post(this.api.news_filter, data, { withCredentials: true })
        .then((res) => {
          this.news = res.data.data.news;
          total_pages = res.data.data.num_pages;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            this.clearAuthCredit();
          }
          this.is_loading = false;
          this.fetched_news_finished = true;
        })
        .finally(() => {
          // if the slug news was not null
          //
          if (this.slug_news != null) {
            this.news.unshift(this.slug_news);
            this.news = this.news.slice(0, 10);
          }
          // set page
          if (total_pages != -1) {
            this.$emit("handle-max-page", total_pages, page);
          }
          this.is_loading = false;
          this.fetched_news_finished = true;
          this.setLastRequestDate(Date.now());
          this.asset_filter_list = asset_list;
          this.fa_source_filter_list = fa_source_list;
          this.en_source_filter_list = en_source_list;
        });
      // TO DO
    },
    getRecentFetchedNews: function () {
      if (this.page === 1) {
        var data = {
          assets: this.asset_filter_list,
          sources:
            this.btn_lang_model == 1
              ? this.fa_source_filter_list
              : this.en_source_filter_list,
          page: this.page,
          query: this.search,
          sort_by: this.sort_by,
          lang: this.btnlang_to_locale[this.btn_lang_model],
          // last_news_date: this.news[0].published_date,
          last_request: this.getLastRequestDate(),
          category: this.$route.params.category,
        };
        this.$axios
          .post(this.api.news_recent_list, data, { withCredentials: true })
          .then((res) => {
            this.news = this.news.splice(0, 10 - res.data.data.length);
            this.news = res.data.data.concat(this.news);
            if (res.data.data.length > 0) {
              this.notifyMe(res.data.data[0].title);
            }
          })
          .catch((e) => {
            if (e.response.status === 401) {
              this.clearAuthCredit();
            }
          })
          .finally(() => {
            this.setLastRequestDate(Date.now());
          });
      }
    },
    getSlugNews: function (val) {
      this.$axios
        .post(this.api.news_info, { uid: val }, { withCredentials: true })
        .then((res) => {
          this.slug_news = res.data.data;
        })
        .catch((e) => {
          if (e.response.status === 401) {
            this.clearAuthCredit();
          }
          this.slug_news = null;
        })
        .finally(() => {
          this.setLastRequestDate(Date.now());
        });
    },
    notifyMe: function (first_news_title) {
      // Let's check if the browser supports notifications
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      }
      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        return new Notification(first_news_title, this.notif_opts);
      }
      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            return new Notification(first_news_title, this.notif_opts);
          }
        });
      }
    },
    isArrayEqual: function (a1, a2) {
      if (a1 === null || a2 === null || a2.length === 0) {
        return false;
      }
      var i = a1.length;
      while (i--) {
        if (a1[i] !== a2[i]) return false;
      }
      return true;
    },
    changePage: function (val) {
      this.page = val;
      if (
        val != 1 &&
        this.slug_news != null &&
        val !== parseInt(this.$route.query.page)
      ) {
        this.slug_news = null;
        this.$router.replace({
          name: this.$route.name,
          params: this.$route.params,
          query: this.$route.query,
        });
      }
    },

    pickInterest: function (interest) {
      if (interest.picked) interest.picked = !interest.picked;
      this.user_interests.push(interest);
    },
    openIneterestsDialog: function () {
      this.dialog_status = true;
    },
    pollData() {
      this.polling = setInterval(() => {
        this.getRecentFetchedNews();
      }, this.polling_interval);
    },
    currentRoute: function () {
      if (this.$route.query.news_locale) return this.$route.query.news_locale;
      else return this.btnlang_to_locale[this.btn_lang_model];
    },
  },
  computed: {
    isLoggedIn() {
      return localStorage.getItem("is_not_logged_in") == 0;
    },
    selectedItem: {
      get: function () {
        if (this.value != null) {
          return this.value.id;
        } else {
          return 0;
        }
      },
      set: function (newVal) {
        this.$emit(
          "input",
          this.items.find((item) => item.id === newVal)
        );
      },
    },
  },
  mounted() {
    this._keyListener = function (e) {
      if (e.key === "/") {
        e.preventDefault(); // present "Save Page" from getting triggered.
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        this.$refs.searchBar.focus();
        // this.saveNote();
      }
    };
    this._boundListener = this._keyListener.bind(this);
    document.addEventListener("keydown", this._boundListener);
    // set news locale from route
    if (this.$route.query.news_locale == "en") {
      this.btn_lang_model = 2;
    }
    // check if we have search query
    if (this.$route.query.search_query) {
      this.search = this.$route.query.search_query;
    }
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this._boundListener);
    clearInterval(this.polling);
  },
  watch: {
    search(val) {
      if (
        !this.is_searchbar_activated &&
        val !== this.$route.query.search_query
      ) {
        this.changePage(1);
        this.timer_value = SEARCHBAR_LATENCY_TIMER_VAL;
        this.is_searchbar_activated = !this.is_searchbar_activated;
        this.activateProgInterval();
      }
    },
    locale(val) {
      this.changeLocale(this.locale_to_btnlang[val]);
    },
  },
  created() {
    //
    this.pollData();
    //
    if (!this.hasLastRequestDate()) {
      this.setLastRequestDate(Date.now());
    }
    //
    this.btn_lang_model = parseInt(this.getNewsLocale());
  },
};
</script>

<style lang="scss" scoped>
$scrollbar-color: #20af9c;

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-color white;
}

/* Works on Chrome, Edge, and Safari */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;
  border-radius: 25px;
}

.no-letter-spacing {
  letter-spacing: 0;
}

.border {
  border: 2px dashed orange;
}

@media (max-width: 540px) {
  .lang-tabs-fa-text-md {
    font-size: 0.81rem;
  }

  .lang-tabs-en-text-md {
    font-size: 0.67rem;
  }
}

@media (min-width: 541px) {
  .lang-tabs-fa-text-lg {
    font-size: 0.93rem;
  }

  .lang-tabs-en-text-lg {
    font-size: 0.81rem;
  }
}

.hidden-by-lang {
  display: none;
}
</style>

