<template>
  <div>
    <input
      ref="input"
      type="file"
      :name="no_files"
      accept="image/*"
      @change="setImage"
    />
    <v-card flat>
      <v-sheet>
        <v-card-title v-if="$vuetify.rtl">
          <v-btn class="ml-4" icon @click="$emit('crop_dialog_status', false)">
            <v-icon> mdi-arrow-right </v-icon>
          </v-btn>
          <span class="filters-header-fa" v-text="'انتخاب تصویر'"> </span>
          <v-spacer />
          <v-btn icon @click.prevent="cropImageAndUpload" v-if="isimgSrcEmpty">
            <v-icon color="green"> mdi-check </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title v-else>
          <v-btn class="mr-4" icon @click="$emit('crop_dialog_status', false)">
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
          <span class="filters-header-en" v-text="'Pick Image'"> </span>
          <v-spacer />
          <v-btn icon @click.prevent="cropImageAndUpload" v-if="isimgSrcEmpty">
            <v-icon color="green"> mdi-check </v-icon>
          </v-btn>
        </v-card-title>
      </v-sheet>
      <v-card-text v-if="isimgSrcEmpty">
        <vue-cropper
          ref="cropper"
          :src="imgSrc"
          preview=".preview"
          :aspectRatio="1 / 1"
        />
      </v-card-text>
      <v-card-text v-else>
        <v-img :src="require('@/assets/zagrosdefaultprofile.svg')"></v-img>
      </v-card-text>
      <v-card-actions v-if="!is_mobilescreen">
        <v-spacer />
        <v-btn
          :disabled="imgSrc.length == 0 ? true : false"
          icon
          @click.prevent="zoom(0.2)"
        >
          <v-icon> mdi-magnify-plus-outline </v-icon>
        </v-btn>
        <v-btn
          :disabled="imgSrc.length == 0 ? true : false"
          icon
          @click.prevent="zoom(-0.2)"
        >
          <v-icon> mdi-magnify-minus-outline </v-icon>
        </v-btn>
        <v-btn
          :disabled="imgSrc.length == 0 ? true : false"
          icon
          @click.prevent="rotate(90)"
          style="transform: scaleX(-1)"
        >
          <v-icon> mdi-format-rotate-90 </v-icon>
        </v-btn>
        <v-btn
          :disabled="imgSrc.length == 0 ? true : false"
          icon
          @click.prevent="rotate(-90)"
        >
          <v-icon> mdi-format-rotate-90 </v-icon>
        </v-btn>
        <v-btn
          :disabled="imgSrc.length == 0 ? true : false"
          icon
          @click.prevent="reset"
        >
          <v-icon> mdi-restore </v-icon>
        </v-btn>
        <v-btn icon @click.prevent="showFileChooser">
          <v-icon> mdi-file-upload-outline </v-icon>
        </v-btn>
        <v-spacer />
      </v-card-actions>
      <!-- if it is mobile screen -->
      <v-card-actions v-else>
        <v-spacer />
        <v-slide-group>
          <v-slide-item>
            <v-btn
              :disabled="imgSrc.length == 0 ? true : false"
              icon
              @click.prevent="zoom(0.2)"
            >
              <v-icon> mdi-magnify-plus-outline </v-icon>
            </v-btn>
          </v-slide-item>
          <v-slide-item>
            <v-btn
              :disabled="imgSrc.length == 0 ? true : false"
              icon
              @click.prevent="zoom(-0.2)"
            >
              <v-icon> mdi-magnify-minus-outline </v-icon>
            </v-btn>
          </v-slide-item>
          <v-slide-item>
            <v-btn
              :disabled="imgSrc.length == 0 ? true : false"
              icon
              @click.prevent="rotate(90)"
              style="transform: scaleX(-1)"
            >
              <v-icon> mdi-format-rotate-90 </v-icon>
            </v-btn>
          </v-slide-item>
          <v-slide-item>
            <v-btn
              :disabled="imgSrc.length == 0 ? true : false"
              icon
              @click.prevent="rotate(-90)"
            >
              <v-icon> mdi-format-rotate-90 </v-icon>
            </v-btn>
          </v-slide-item>
          <v-slide-item>
            <v-btn
              :disabled="imgSrc.length == 0 ? true : false"
              icon
              @click.prevent="cropImage"
            >
              <v-icon> mdi-crop </v-icon>
            </v-btn>
          </v-slide-item>
          <v-slide-item>
            <v-btn
              :disabled="imgSrc.length == 0 ? true : false"
              icon
              @click.prevent="reset"
            >
              <v-icon> mdi-restore </v-icon>
            </v-btn>
          </v-slide-item>
          <v-slide-item>
            <v-btn icon @click.prevent="showFileChooser">
              <v-icon> mdi-file-upload-outline </v-icon>
            </v-btn>
          </v-slide-item>
        </v-slide-group>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
export default {
  props: ["user_profile_changed"],
  components: {
    VueCropper,
  },
  data() {
    return {
      imgSrc: "",
      cropImg: "",
      data: null,
      is_mobilescreen: false,
      picked_from_device: undefined,
      no_files: null,
    };
  },
  watch: {
    no_files(val) {
      console.log(val);
    },
    picked_from_device(val) {
      if (val === undefined) {
        this.$emit("crop_dialog_status", false);
        return;
      }
      const file = val;
      if (file.type.indexOf("image/") === -1) {
        // alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
  },
  computed: {
    isimgSrcEmpty() {
      return this.imgSrc !== null && this.imgSrc.length != 0;
    },
  },
  methods: {
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.user_profile_changed.url = this.cropImg;
      this.user_profile_changed.image = this.dataURItoBlob(this.cropImg);
      this.$emit("crop_dialog_status", false);
    },
    cropImageAndUpload() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.user_profile_changed.url = this.cropImg;
      this.user_profile_changed.image = this.dataURItoBlob(this.cropImg);
      this.$emit("crop_and_upload", false);
    },
    dataURItoBlob: function (dataURI) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);

      // create a view into the buffer
      var ia = new Uint8Array(ab);

      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      // write the ArrayBuffer to a blob, and you're done
      var blob = new Blob([ab], { type: mimeString });
      return blob;
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      let file = e.target.files;
      if (file.length !== 0) {
        file = file[0];
      } else {
        return;
      }
      if (file.type.indexOf("image/") === -1) {
        // alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper?.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      }
      //  else {
      //   alert("Sorry, FileReader API not supported");
      // }
    },
    showFileChooser() {
      // this.$refs.input.$refs.input.click();
      this.$refs.input.click();
      // this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
  mounted() {
    // this.imgSrc = this.user_profile;
    // bind the beforeinput
    this.is_mobilescreen = this.$vuetify.breakpoint.smAndDown;
    this.showFileChooser();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
input[type="file"] {
  display: none;
}

.v-file-input {
  display: none;
}

.content {
  display: flex;
  justify-content: space-between;
}
.cropper-area {
  width: 614px;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
textarea {
  width: 100%;
  height: 100px;
}
.preview-area {
  width: 307px;
}
.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}
.preview-area p:last-of-type {
  margin-top: 1rem;
}
.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}
.cropped-image img {
  max-width: 100%;
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.filters-header-fa {
  font-size: 1.2rem;
}

.filters-header-en {
  margin-top: 2px;
  font-size: 1.2rem;
}
</style>

