<template>
  <v-container
    fill-height
    v-if="$vuetify.rtl && $router.currentRoute.name.split('-')[0] === 'fa'"
  >
    <v-row class="ma-2 justify-center align-start">
      <v-card
        max-width="1000"
        outlined
        :class="{
          'custom-card-border-light': !$vuetify.theme.dark,
          'custom-card-border-dark': $vuetify.theme.dark,
        }"
      >
        <v-card-title class="about-title">{{ title.fa }}</v-card-title>
        <v-card-text class="about-text text-justify">
          <p
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"
                ><strong>به زاگرس لایو خوش آمدید</strong></span
              ></span
            >
          </p>
          <p
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
            "
          >
            <span>
              هدف ما ارائه آخرین اخبار بازار ارزهای دیجیتال از بهترین منابع خبری
              است. اخبار در سایت زاگرس لایو با فاصله زمانی کوتاهی از انتشار آن
              در منابع اصلی به نمایش گذاشته می‌شوند.
            </span>
          </p>
          <p
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"><strong>چرا زاگرس لایو؟</strong></span></span
            >
          </p>
          <p
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
            "
          >
            <span lang="fa-IR">بازارهای مالی دارای فراز و نشیب هستند</span>.
            <span lang="fa-IR"
              >رشته&zwnj;کوه بهترین نمادی بود که برای این مفهوم یافتیم و نام
              زاگرس را به عنوان یکی از رشته&zwnj;کوه&zwnj;های ایران انتخاب
              کردیم</span
            >.
            <span lang="fa-IR"
              >زاگرس رشته&zwnj;کوهی طولانی در ایران، شمال عراق و جنوب شرقی ترکیه
              است</span
            >.
            <span lang="fa-IR"
              >این رشته&zwnj;کوه در مجموع ۱۶۰۰ کیلومتر طول دارد</span
            >.
          </p>
          <p
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
            "
          >
            <span lang="fa-IR">چشم انداز ما پاسخ به این سوال است</span>:
            &quot;<span lang="fa-IR"
              >در دنیای ارزهای دیجیتال چه اتفاقی می&zwnj;افتد؟</span
            >&quot;.
            <span lang="fa-IR"
              >ماموریت این تیم ایجاد ویژگی&zwnj;های متنوع برای کاربران است تا به
              سرعت به آخرین اخبار مهم از منابع مختلف دسترسی پیدا کرده و آن را با
              دیگران به اشتراک بگذارند</span
            >.
          </p>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
  <v-container fill-height v-else>
    <v-row class="ma-2 justify-center align-start">
      <v-card
        max-width="1000"
        outlined
        :class="{
          'custom-card-border-light': !$vuetify.theme.dark,
          'custom-card-border-dark': $vuetify.theme.dark,
        }"
      >
        <v-card-title class="about-title">{{ title.en }}</v-card-title>
        <v-card-text class="about-text text-justify">
          <strong>Welcome to Zagros Live</strong> <br />
          <div class="mt-2"></div>
          The goal of Zagros Live is to give you the absolute best news sources
          for Cryptocurrencies. Our topics are carefully curated and constantly
          updated as we know the web moves fast so we try to as well.<br />
          <div class="mt-2"></div>
          <strong>Why Zagros Live?</strong> <br />
          <div class="mt-2"></div>
          Financial markets have ups and downs. The mountain range was the best
          symbol we found for this concept and we chose the name Zagros as one
          of the mountain ranges of Iran. The Zagros Mountains are a long
          mountain range in Iran, northern Iraq and southeastern Turkey. This
          mountain range has a total length of 1,600 km. <br />
          <div class="mt-2"></div>
          Our vision is answering the question: “What’s happening in the
          cryptocurrencies world?”. The team's mission is to create a variety of
          features for users to quickly access the latest important news from
          various sources and share it with others.
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$vuetify.rtl ? "درباره ما" : "About Us",
      meta: [
        {
          name: "twitter:image:src",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          name: "twitter:site",
          content: "Zagros Live",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:title",
          content: this.$vuetify.rtl ? "درباره ما" : "About Us",
        },
        {
          property: "og:image",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          property: "og:site_name",
          content: "Zagros Live",
        },
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: this.$vuetify.rtl ? "درباره ما" : "About Us",
        },
        { property: "og:site_name", content: "Zagros Live" },
        {
          property: "og:url",
          content: "https://zagros.live" + this.$router.currentRoute.path,
        },
      ],
    };
  },
  name: "about",
  data: () => ({
    title: {
      fa: "درباره ما",
      en: "About Us",
    },
  }),
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
$primary-light-color: #146a5f;
$primary-dark-color: #404548;

.about-text {
  line-height: 1.8;
  font-size: 1.1rem;
}

.about-title {
  font-size: 1.8rem;
}

.custom-card-border-light {
  border-color: $primary-light-color;
}

.custom-card-border-dark {
  border-color: $primary-dark-color;
}

p {
  font-size: 0.95rem;
}
</style>
