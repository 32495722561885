// const BASE_URL = process.env.VUE_APP_BASE_URL

export default {
  name: "people_and_categories",
  data: () => ({
    title: {
      categories: {
        fa: "دسته\u200cبندی",
        en: "categories",
      },
      people: {
        fa: "اینفلوئنسرها",
        en: "Influencers",
      }
    },
    categories: [{
        en: "Airdrop",
        fa: "ایردراپ",
        id: 8000,
        route_ref: "airdrop",
        img: "https://static.zagros.live/images/categories/tags/airdrop.png",
      },
      {
        en: "Blockchain",
        fa: "بلاک چین",
        id: 8001,
        route_ref: "blockchain",
        img: "https://static.zagros.live/images/categories/tags/blockchain.png",

      }, {
        en: "Crypto",
        fa: "رمزارز",
        id: 8002,
        route_ref: "crypto",
        img: "https://static.zagros.live/images/categories/tags/crypto.png",

      }, {
        en: "DeFi",
        fa: "دیفای",
        id: 8003,
        route_ref: "defi",
        img: "https://static.zagros.live/images/categories/tags/defi.png",

      }, {
        en: "Game",
        fa: "بازی",
        id: 8004,
        route_ref: "game",
        img: "https://static.zagros.live/images/categories/tags/game.png",

      }, {
        en: "Market",
        fa: "بازار",
        id: 8005,
        route_ref: "market",
        img: "https://static.zagros.live/images/categories/tags/market.png",

      }, {
        en: "Metaverse",
        fa: "متاورس",
        id: 8006,
        route_ref: "metaverse",
        img: "https://static.zagros.live/images/categories/tags/metaverse.png",

      }, {
        en: "NFT",
        fa: "ان اف تی",
        id: 8007,
        route_ref: "nft",
        img: "https://static.zagros.live/images/categories/tags/nft.png",

      }, {
        en: "Regulation",
        fa: "مقررات",
        id: 8008,
        route_ref: "regulation",
        img: "https://static.zagros.live/images/categories/tags/regulation.png",

      }, {
        en: "Whale",
        fa: "نهنگ",
        id: 8009,
        route_ref: "whale",
        img: "https://static.zagros.live/images/categories/tags/whale.png",

      }
    ],
    people: [{
        en: "Elon Musk",
        fa: "ایلان ماسک",
        id: 9000,
        route_ref: "elonmusk",
        img: "https://static.zagros.live/images/categories/influencers/elonmusk.png"
      },
      {
        en: "Vitalik Buterin",
        fa: "ویتالیک بوترین",
        id: 9001,
        route_ref: "vitalikbuterin",
        img: "https://static.zagros.live/images/categories/influencers/vitalikbuterin.png"
      }, {
        en: "Jack Dorsey",
        fa: "جک دورسی",
        id: 9002,
        route_ref: "jackdorsey",
        img: "https://static.zagros.live/images/categories/influencers/jackdorsey.png"
      }, {
        en: "Cathie Wood",
        fa: "کتی وود",
        id: 9003,
        route_ref: "cathiewood",
        img: "https://static.zagros.live/images/categories/influencers/cathiewood.png"
      }, {
        en: "Michael Saylor",
        fa: "مایکل سیلور",
        id: 9004,
        route_ref: "michaelsaylor",
        img: "https://static.zagros.live/images/categories/influencers/michaelsaylor.png"
      }, {
        en: "Sam Bankman-Fried",
        fa: "سم بنکمن فراید",
        id: 9005,
        route_ref: "sambankmanfried",
        img: "https://static.zagros.live/images/categories/influencers/sambankmanfried.png"
      }, {
        en: "Mike novogratz",
        fa: "مایک نووگراتز",
        id: 9006,
        route_ref: "mikenovogratz",
        img: "https://static.zagros.live/images/categories/influencers/mikenovogratz.png"
      }, {
        en: "Changpeng Zhao",
        fa: "چنگ پنگ ژائو",
        id: 9007,
        route_ref: "changpengzhao",
        img: "https://static.zagros.live/images/categories/influencers/changpengzhao.png"
      }, {
        en: "Mark Cuban",
        fa: "مارک کوبان",
        id: 9008,
        route_ref: "markcuban",
        img: "https://static.zagros.live/images/categories/influencers/markcuban.png"
      }, {
        en: "Raoul Pal",
        fa: "رائول پال",
        id: 9009,
        route_ref: "raoulpal",
        img: "https://static.zagros.live/images/categories/influencers/raoulpal.png"
      },
    ],
  }),
}
