<template>
  <v-container
    fill-height
    v-if="$vuetify.rtl && $router.currentRoute.name.split('-')[0] === 'fa'"
  >
    <v-row class="ma-2 justify-center">
      <v-card
        max-width="1000"
        outlined
        :class="{
          'custom-card-border-light': !$vuetify.theme.dark,
          'custom-card-border-dark': $vuetify.theme.dark,
        }"
      >
        <v-card-title class="about-title">{{ title.fa }}</v-card-title>
        <v-card-text
          class="about-text text-justify"
          style="font-family: IRanSans"
        >
          <p
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              font-size: 1.3rem;
            "
          >
            <strong>۱&nbsp;</strong
            ><span lang="ar-SA"
              ><span lang="fa-IR"
                ><strong>محدوده سیاست حفظ حریم خصوصی</strong></span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >تیم زاگرس لایو تعهدات خود بر اساس اصول حفظ حریم خصوصی را بسیار
              جدی می&zwnj;پذیرد و رویه&zwnj;ها و سیستم&zwnj;هایی را برای اطمینان
              از رعایت این قوانین اجرا کرده است</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >دامنه این خط&zwnj;مشی برای بازدیدکنندگان جدید، بازدیدکنندگان فعلی
              و مشترکین وب&zwnj;سایت&nbsp;</span
            >zagros.live <span lang="fa-IR">اعمال می&zwnj;شود</span>.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>۲&nbsp;</strong
            ><span lang="ar-SA"
              ><span lang="fa-IR"
                ><strong>جمع&zwnj;آوری اطلاعات</strong></span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-weight: bold;
              font-size: 1.1rem;
            "
          >
            2-1
            <span lang="ar-SA"
              ><span lang="fa-IR">انواع اطلاعات جمع آوری شده</span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-weight: bold;
              font-size: 1m;
            "
          >
            2-1-1
            <span lang="ar-SA"
              ><span lang="fa-IR">اطلاعات قابل شناسایی</span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">در روند کار وب&zwnj;سایت&nbsp;</span
            >zagros.live<span lang="fa-IR"
              >، ما طیف وسیعی از اطلاعات را در مورد مشترکین فعلی و آینده خود
              جمع&zwnj;آوری می&zwnj;کنیم</span
            >. <span lang="fa-IR">این اطلاعات شامل</span>:
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="ar-SA"
              ><span lang="fa-IR">نام و نام خانوادگی</span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="ar-SA"><span lang="fa-IR">آدرس ایمیل</span></span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="ar-SA"><span lang="fa-IR">شماره تلفن</span></span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="fa-IR"
              >اطلاعات نظرسنجی&zwnj;ها برای دریافت بازخورد در مورد خدمات زاگرس
              لایو که ممکن است از کاربران جمع&zwnj;آوری کنیم</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-weight: bold;
              font-size: 1rem;
            "
          >
            2-1-2
            <span lang="ar-SA"
              ><span lang="fa-IR">اطلاعات شناسایی نشده</span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >هدف از اطلاعات شناسایی&zwnj;شده که از شما جمع&zwnj;آوری
              می&zwnj;کنیم، تجزیه و تحلیل عمومی وب&zwnj;سایت و آمار
              بازدیدکنندگان روزانه&zwnj; است</span
            >.
            <span lang="fa-IR"
              >این داده&zwnj;ها از طریق کوکی&zwnj;های مرورگر جمع&zwnj;آوری
              می&zwnj;شوند که وب&zwnj;سایت ما روی مرورگر شما قرار می&zwnj;دهد تا
              بتواند نحوه استفاده خوانندگان از سایت ما را ردیابی کند</span
            >.
            <span lang="fa-IR"
              >جمع&zwnj;آوری داده&zwnj;های تجزیه و تحلیل غیرقابل شناسایی ما
              توسط&nbsp;</span
            >Google Analytics <span lang="fa-IR">انجام می&zwnj;شود</span>.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">این کوکی&zwnj;ها داده&zwnj;هایی مانند</span>:
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="ar-SA"
              ><span lang="fa-IR">زمان فعالیت در یک صفحه</span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="ar-SA"><span lang="fa-IR">موقعیت</span></span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="ar-SA"><span lang="fa-IR">جنسیت</span></span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="ar-SA"><span lang="fa-IR">سن</span></span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="fa-IR"
              >اگر بازدیدکننده جدید است یا پیش از آن نیز به وب سایت&nbsp;</span
            >zagros.live <span lang="fa-IR">ورود پیدا کرده باشد</span>.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="ar-SA"><span lang="fa-IR">صفحات بازدیدشده</span></span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="fa-IR">اگر بازدیدکننده یک یا چند صفحه را در&nbsp;</span
            >zagros.live <span lang="fa-IR">بررسی کرد</span>.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="fa-IR">وب سایتی که کاربر از آن مسیر وارد&nbsp;</span
            >zagros.live
            <span lang="fa-IR"
              >شده است، مانند شبکه&zwnj;های اجتماعی، نتایج جستجوی گوگل یا یک
              خبرنامه</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-weight: bold;
              font-size: 1.1rem;
            "
          >
            2-2
            <span lang="ar-SA"
              ><span lang="fa-IR"
                >چرا ما این اطلاعات را از شما جمع&zwnj;آوری می&zwnj;کنیم</span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >به منظور ارائه خدمات خود به شما به عنوان یک مشترک یا به عنوان
              خواننده&zwnj;ای که در وب سایت&nbsp;</span
            >zagros.live
            <span lang="fa-IR"
              >دوره آزمایشی را طی می&zwnj;کند، به این اطلاعات نیاز داریم تا
              بتوانیم</span
            >:
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="fa-IR"
              >یک حساب کاربری در بستر وب سایت ما ایجاد کنید تا بتوانید به خواندن
              محتوای موجود در آن دسترسی داشته باشید</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-weight: bold;
              font-size: 1.1rem;
            "
          >
            2-3
            <span lang="ar-SA"
              ><span lang="fa-IR">نحوه جمع&zwnj;آوری اطلاعات</span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-weight: bold;
              font-size: 1rem;
            "
          >
            2-3-1
            <span lang="ar-SA"
              ><span lang="fa-IR">اطلاعات قابل شناسایی</span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >تمام اطلاعات قابل شناسایی که در بالا توضیح داده شد از طریق فرم
              های وب در&nbsp;</span
            >zagros.live <span lang="fa-IR">جمع&zwnj;آوری می&zwnj;شود</span>.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-weight: bold;
              font-size: 1rem;
            "
          >
            2-3-2
            <span lang="ar-SA"
              ><span lang="fa-IR">اطلاعات غیرقابل شناسایی</span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >تمامی اطلاعات غیرقابل شناسایی به صورت خودکار هنگام بازدید از وب
              سایت&nbsp;</span
            >zagros.live <span lang="fa-IR">جمع&zwnj;آوری می&zwnj;شود</span>.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-weight: bold;
              font-size: 1rem;
            "
          >
            2-3-3
            <span lang="ar-SA"
              ><span lang="fa-IR">اطلاعات شخصی ناخواسته</span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >اگر اطلاعات شخصی شما را از شخص ثالثی دریافت کنیم بدون اینکه از
              شما درخواستی داشته باشیم، در یک زمان معقول، تعیین خواهیم کرد که
              آیا می&zwnj;توانستیم آن را به روش&zwnj;های ذکر شده در بالا
              جمع&zwnj;آوری کنیم یا خیر</span
            >.
            <span lang="fa-IR"
              >اگر تشخیص دهیم که نمی&zwnj;توانستیم به یکی از آن راه&zwnj;ها
              جمع&zwnj;آوری کنیم و انجام این کار قانونی نبوده است، در اسرع وقت
              اطلاعات را از بین می&zwnj;بریم</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-weight: bold;
              font-size: 1.1rem;
            "
          >
            2-4
            <span lang="ar-SA"
              ><span lang="fa-IR"
                >چگونه این داده&zwnj;ها ذخیره می&zwnj;شوند</span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >ما از مکانیزم‌های مختلف برای محافظت از داده‌هایی که شما ارائه
              می‌دهید به روشی امن استفاده می‌کنیم. به منظور حفاظت از داده‌های
              شما، ما از موارد زیر استفاده می‌کنیم:
            </span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="fa-IR">لایه سوکت امن&nbsp;</span>(SSL)
            <span lang="fa-IR">در&nbsp;</span>zagros.live
            <span lang="fa-IR"
              >داده&zwnj;های شما را که بین فرم وب سایت و سرورهای ما منتقل
              می&zwnj;شود، رمزگذاری می&zwnj;کند</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="fa-IR">فایروال&zwnj;ها از&nbsp;</span>zagros.live
            <span lang="fa-IR"
              >و سایر سیستم&zwnj;هایی محافظت می&zwnj;کنند که داده&zwnj;ها را
              شبانه&zwnj;روز ذخیره می&zwnj;کنند</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="fa-IR"
              >احراز هویت دوعاملی برای سیستم&zwnj;هایی استفاده می&zwnj;شود که
              داده&zwnj;های شما را ذخیره می&zwnj;کنند تا فقط کارمندان مجاز به آن
              دسترسی داشته باشند</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="fa-IR"
              >پشتیبان&zwnj;گیری&zwnj;های روزانه در سیستم که به&zwnj;طور ایمن
              در&nbsp;</span
            >Cloud
            <span lang="fa-IR"
              >ذخیره می&zwnj;شوند، روزانه برای محافظت از داده&zwnj;ها به عنوان
              جلوگیری از نابودی اطلاعات گرفته می&zwnj;شوند</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-weight: bold;
              font-size: 1.1rem;
            "
          >
            2-5
            <span lang="ar-SA"
              ><span lang="fa-IR">نحوه استفاده از داده&zwnj;ها</span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >ما از اطلاعات شما علاوه بر دسترسی نامحدود به خدمات سایت، برای
              بازاریابی مستقیم نیز استفاده می&zwnj;کنیم</span
            >.
            <span lang="fa-IR"
              >بازاریابی مستقیم، تبلیغ کالاها و خدمات به شما از طریق ایمیل
              است</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >زاگرس لایو تنها در صورتی از اطلاعات شما برای اهداف بازاریابی
              مستقیم استفاده می&zwnj;کند که اطلاعات را بطور مستقیم از شما
              جمع&zwnj;آوری کرده باشد</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-weight: bold;
              font-size: 1rem;
            "
          >
            2-5-1
            <span lang="ar-SA"
              ><span lang="fa-IR"
                >شرایطی که برای بازاریابی مستقیم به رضایت شما نیاز داریم</span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >مگر اینکه غیرعملی یا غیرمنطقی باشد، زمانی که</span
            >:
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="ar-SA"
              ><span lang="fa-IR"
                >جمع&zwnj;آوری اطلاعات شخصی شما از شخص ثالث به منظور بازاریابی
                مستقیم صورت گرفته باشد؛ یا</span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="fa-IR"
              >به طور منطقی انتظار ندارید که ایمیل تبلیغاتی دریافت کنید</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >اگر در هر زمانی می&zwnj;خواهید بدانید چه کسی اطلاعات شخصی شما را
              به ما ارائه کرده است، لطفا با استفاده از اطلاعات تماس در انتهای
              این صفحه با ما تماس بگیرید</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-weight: bold;
              font-size: 1rem;
            "
          >
            2-5-2
            <span lang="ar-SA"
              ><span lang="fa-IR">انصراف از بازاریابی مستقیم</span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >ما همیشه ابزار ساده&zwnj;ای را برای شما فراهم می&zwnj;کنیم تا از
              دریافت ایمیل تبلیغاتی خودداری کنید، که معمولا شامل
              پیوند&nbsp;</span
            >&laquo;<span lang="fa-IR">انصراف</span>&raquo;
            <span lang="fa-IR">در ایمیل&zwnj;ها می&zwnj;شود</span>.
            <span lang="fa-IR"
              >اگر در هر زمانی در آینده نمی&zwnj;خواهید ما مطالب بازاریابی
              مستقیم را برای شما ارسال کنیم، می&zwnj;توانید به سادگی با استفاده
              از جزئیات تماس زیر در انتهای این خط مشی، به مدیر حریم خصوصی ما
              اطلاع دهید</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>۳&nbsp;</strong
            ><span lang="ar-SA"
              ><span lang="fa-IR"><strong>حقوق شما</strong></span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">تمامی مشترکین&nbsp;</span>zagros.live
            <span lang="fa-IR">از حقوق زیر برخوردارند</span>:
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="ar-SA"
              ><span lang="fa-IR"
                >درخواست دسترسی به اطلاعات شخصی که زاگرس لایو در مورد شما
                دارد</span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="fa-IR"
              >فقط شخصی که داده&zwnj;ها را از طریق موارد ذکر شده در
              بند&nbsp;</span
            >2-3
            <span lang="fa-IR"
              >فوق ارائه کرده است می&zwnj;تواند درخواست دسترسی به داده&zwnj;های
              خود را داشته باشد</span
            >.
            <span lang="fa-IR"
              >دسترسی به داده&zwnj;های دیگران ممنوع خواهد بود مگر اینکه توسط
              مجری قانون الزامی شود</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="fa-IR"
              >درخواست اصلاح داده&zwnj;های شخصی، به عنوان مثال شما ایمیل را
              تغییر داده&zwnj;اید و تمام ارتباطات به آدرس ایمیل جدید شما ارسال
              شود</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >هنگام نوشتن ایمیل درخواست جهت دریافت یک نسخه از اطلاعات شخصی، ما
              به جزئیات زیر نیازمندیم</span
            >:
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="fa-IR">نام کامل&nbsp;</span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="fa-IR">آدرس ایمیل&nbsp;</span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            - <span lang="fa-IR">شماره تلفن&nbsp;</span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>۴&nbsp;</strong
            ><span lang="ar-SA"
              ><span lang="fa-IR"
                ><strong>شکایات حریم خصوصی</strong></span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >اگر نگرانی یا شکایتی در رابطه با رسیدگی ما به اطلاعات شخصی شما یا
              هرگونه نقض اصول حفظ حریم خصوصی دارید، لطفا یادداشتی کتبی با تشریح
              ماهیت شکایت با استفاده از اطلاعات تماس زیر در انتهای این خط مشی را
              برای مدیر حریم خصوصی ارسال کنید</span
            >. <span lang="fa-IR">ما تلاش خواهیم کرد تا ظرف&nbsp;</span>10
            <span lang="fa-IR"
              >روز کاری پس از دریافت به شکایت شما پاسخ دهیم</span
            >.
            <span lang="fa-IR"
              >در صورت حل نشدن، ممکن است شکایت به یک نهاد حل&zwnj;وفصل شکایات
              ارجاع شود</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>۵&nbsp;</strong
            ><span lang="ar-SA"
              ><span lang="fa-IR"><strong>خط&zwnj;مشی کوکی</strong></span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >کوکی&zwnj;ها و سایر شناسه&zwnj;ها شامل بخش&zwnj;هایی از کد نصب
              شده در مرورگر هستند که به مالک در ارائه خدمات طبق اهداف توضیح داده
              شده کمک می&zwnj;کنند</span
            >.
            <span lang="fa-IR"
              >برخی از اهدافی که کوکی&zwnj;ها برای آنها نصب می&zwnj;شوند ممکن
              است به رضایت کاربر نیز نیاز داشته باشند</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >در مواردی که نصب کوکی&zwnj;ها بر اساس رضایت باشد، چنین رضایتی
              می&zwnj;تواند در هر زمانی آزادانه پس از دستورالعمل&zwnj;های ارائه
              شده در این سند لغو شود</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">این برنامه از کوکی&zwnj;های به اصطلاح&nbsp;</span
            >&laquo;<span lang="fa-IR">فنی</span>&raquo;
            <span lang="fa-IR"
              >و سایر ردیاب&zwnj;های مشابه برای انجام فعالیت&zwnj;هایی استفاده
              می&zwnj;کند که برای عملیات یا ارائه سرویس کاملاً ضروری هستند</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-size: 1.3rem;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"><strong>6</strong></span
              ><strong>&nbsp;</strong
              ><span lang="fa-IR"
                ><strong>مدیر حریم خصوصی زاگرس لایو</strong></span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >اگر در مورد سیاست حفظ حریم خصوصی سؤالی دارید، لطفا از طریق ایمیل
              زیر با ما تماس داشته باشید</span
            >:
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR">مدیر حریم خصوصی زاگرس لایو</span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">ایمیل</span>:
            <u><a href="mailto:privacy@zagros.live">privacy@zagros.live</a></u>
          </p>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
  <v-container class="my-4" v-else>
    <v-row class="ma-2 justify-center align-start">
      <v-card
        max-width="1000"
        outlined
        :class="{
          'custom-card-border-light': !$vuetify.theme.dark,
          'custom-card-border-dark': $vuetify.theme.dark,
        }"
      >
        <v-card-title class="about-title">{{ title.en }}</v-card-title>
        <v-card-text class="about-text text-justify">
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>1 Privacy Policy Scope</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            Zagros Live Team takes its obligations under the privacy principles
            as contained very seriously, and has implemented procedures and
            systems to ensure we comply with those laws.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            The scope of this Policy applies to new visitors, current visitors,
            and subscribers of <strong>zagros.live</strong> website.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>2 Collection of your personal information&nbsp;</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
              font-weight: bold;
              font-size: 1.1rem;
            "
          >
            2-1 Types of information collected
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
              font-weight: bold;
              font-size: 1rem;
            "
          >
            2-1-1 Identifiable information
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            In the process of running the zagros.live website, we collect a
            range of personal information about our current and prospective
            subscribers. This information includes:
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - Full name
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - E-mail address
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - Telephone number
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - From time to time and as part of surveys to get feedback about
            Zagros Live service we might also collect from you
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
              font-weight: bold;
              font-size: 1rem;
            "
          >
            2-1-2 De-identified information
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            The purpose of De-identified information we collect from you is for
            website general analytics and statistics on our daily visitors. This
            data is collected via browser cookies that our website will drop on
            your browser so it can track how readers use our site. Our
            de-identifiable analytics data collection is done by Google
            Analytics.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            These cookies track data such as:
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - Time on a page
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - Location
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - Gender
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - Age
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - If the visitor is new or has been to zagros.live website before
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - Pages visited
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - If a visitor read one or more pages on zagros.live
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - Website from which a user arrived to zagros.live, such as social
            media, google search results or a newsletter.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
              font-weight: bold;
              font-size: 1.1rem;
            "
          >
            2-2 Why we collect this information from you
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            In order to provide our services to you as a paid subscriber or as a
            reader trialing a subscription with zagros.live website, we need
            this information so that we can:
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - Create a user account in our website platform so you can have
            access to read the content on it
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - Email you our notifications that are part of our subscription with
            zagros.live
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - Email you transactional emails from our system to remind you of
            your subscription expiring, advise you of subscription payment being
            declined or successful subscription payment.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - Some web cookies are necessary for the successful function of the
            website&rsquo;s platform so we can display the right content to you,
            for example, zagros.live website needs cookies to know if the user
            has an active subscription or not.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
              font-weight: bold;
              font-size: 1.1rem;
            "
          >
            2-3 How this information is collected
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
              font-weight: bold;
              font-size: 1rem;
            "
          >
            2-3-1 Identifiable Information
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            All identifiable information described above is collected via
            webforms on zagros.live
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
              font-weight: bold;
              font-size: 1rem;
            "
          >
            2-3-2 Non-identifiable information
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            All non-identifiable information is collected automatically when you
            visit zagros.live website.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
              font-weight: bold;
              font-size: 1rem;
            "
          >
            2-3-3 Unsolicited personal information
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            If we receive your personal information from a third party without
            having asked you for it, then within a reasonable time, we will
            determine whether we could have collected it in the ways outlined
            above. If we determine that it could not have been collected in one
            of those ways and it is legal and reasonable to do so, then as soon
            as practicable we will destroy the information or ensure that it is
            de-identified before storing in our systems
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
              font-weight: bold;
              font-size: 1.1rem;
            "
          >
            2-4 How this data is stored
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            We use strict computer and user access mechanisms to protect the
            data you provide in a secure way. For the purpose of protecting your
            data we use:
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - Secure Socket Layer (SSL) on zagros.live that will encrypt your
            data being transmitted between the web form on the site and our
            servers.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - Firewalls protect zagros.live and other systems storing data 24/7
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - Two factor authentication (2FA) is used for systems storing your
            data so that only authorized employees have access to this.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - Daily backups that are securely stored in the Cloud are taken
            daily to protect any data loss as part of a system failure.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
              font-weight: bold;
              font-size: 1.1rem;
            "
          >
            2-5 How this data is used
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            In addition to the purpose of a subscription or a free trial with
            Zagros Live, we also use your information for direct marketing.
            Direct Marketing is the promotion of goods and services directly to
            you through emails.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            Zagros Live will use your personal information for the purposes of
            direct marketing only if we have collected your personal information
            directly from you.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
              font-weight: bold;
              font-size: 1rem;
            "
          >
            2-5-1 Circumstances where we need your consent for direct marketing
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            Unless it would be impracticable or unreasonable, we need your
            consent when:
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - collecting your personal information from a third party for the
            purpose of direct marketing; or
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            - you would not reasonably expect to receive direct marketing.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            If at any time you want to know who provided us with your personal
            information, then please contact us using the contact details below
            at the end of this policy.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
              font-weight: bold;
              font-size: 1rem;
            "
          >
            2-5-2 Opting-out of direct marketing
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            We will always provide a simple means for you to
            &ldquo;opt-out&rdquo; from receiving direct marketing, which
            typically involves an &ldquo;opt-out&rdquo; link on emails.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            If at any time in the future you do not want us to send you direct
            marketing material, then you can simply inform our Privacy Manager
            using the contact details below at the end of this policy.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
              font-size: 1.3rem;
            "
          >
            <strong>3 Your Rights</strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            All subscribers of zagros.live have the rights to:
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - Request access to personal information that Zagros Live holds
            about you
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - Only the person that provided the data via the means stated in
            point 2-3 above can request access to their own data. Access to data
            of others will be denied unless it is required by law enforcement.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - Request personal data to be corrected, for example you have
            changed email and which all communication to be sent to your new
            email address.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            When writing to us requesting a copy of your personal information we
            need the following details:
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - Your full name
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - Your email address
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - Your phone number
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
              font-size: 1.3rem;
            "
          >
            <strong>4 Privacy Complaints</strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            If you have a concern or complaint relating to our handling of your
            personal information or any breaches of the Privacy Principles,
            please send a written note outlining the nature of the complaint to
            the Privacy Manager using the contact details below at the end of
            this policy. We will endeavour to respond to your complaint within
            10 business days of receipt. If unresolved, the complaint may be
            referred to an external complaints resolution entity.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
              font-size: 1.3rem;
            "
          >
            <strong>5 Cookie Policy</strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            Cookies and other Identifiers consist of portions of code installed
            in the browser that assist the Owner in providing the Service
            according to the purposes described. Some of the purposes for which
            Cookies are installed may also require the User&apos;s consent.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            Where the installation of Cookies is based on consent, such consent
            can be freely withdrawn at any time following the instructions
            provided in this document.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            This Application uses so-called &ldquo;technical&rdquo; Cookies and
            other similar Trackers to carry out activities that are strictly
            necessary for the operation or delivery of the Service.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
              font-size: 1.3rem;
            "
          >
            <strong>6 Zagros Live Privacy Manager</strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            If you have questions about this privacy policy, please contact us
            via email or mail:
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            Privacy Manager, Zagros Live
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            Email:
            <u><a href="mailto:privacy@zagros.live">privacy@zagros.live</a></u>
          </p>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$vuetify.rtl ? "حریم خصوصی" : "Privacy Policy",
      meta: [
        {
          name: "twitter:image:src",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          name: "twitter:site",
          content: "Zagros Live",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:title",
          content: this.$vuetify.rtl ? "حریم خصوصی" : "Privacy Policy",
        },
        {
          name: "twitter:description",
          content: "Privacy Policy",
        },
        {
          property: "og:image",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          property: "og:site_name",
          content: "Zagros Live",
        },
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: this.$vuetify.rtl ? "حریم خصوصی" : "Privacy Policy",
        },
        { property: "og:site_name", content: "Zagros Live" },
        {
          property: "og:description",
          content: "Privacy Policy",
        },
        {
          property: "og:url",
          content: "https://zagros.live" + this.$router.currentRoute.path,
        },
      ],
    };
  },
  name: "privacy_policy",
  data: () => ({
    title: {
      fa: "حریم خصوصی",
      en: "Privacy Policy",
    },
  }),
  methods: {
    isLangFa: function () {
      if (this.$router.currentRoute.name.split("-")[0] === "fa") {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
$primary-light-color: #146a5f;
$primary-dark-color: #404548;

.about-text {
  line-height: 1.8;
  font-size: 1.1rem;
}

.about-title {
  font-size: 1.8rem;
}

.custom-card-border-light {
  border-color: $primary-light-color;
}

.custom-card-border-dark {
  border-color: $primary-dark-color;
}

p {
  font-size: 0.95rem;
}
</style>
