<template>
  <!-- <div class="ma-0 pa-0"> -->
  <v-card v-if="news !== undefined" class="mb-3 flex-grow-1 flex-shrink-0" outlined :class="{
    'custom-card-border-light': !isDark,
    'custom-card-border-dark': isDark,
    'recent-post-box-shadow-fa':
      !$vuetify.breakpoint.smAndDown && news.source.locale === 'fa' && timeDifference,
    'recent-post-box-shadow-en':
      !$vuetify.breakpoint.smAndDown && news.source.locale === 'en' && timeDifference,
    'recent-post-box-shadow-mobile': $vuetify.breakpoint.smAndDown && timeDifference,
    'searched-post-box-shadow':
      $router.currentRoute.query.news_share_id === news.slug,
  }">
    <v-row class="justify-center align-center" :class="{
      'post-direction-en': locale === 'en',
      'post-direction-fa': locale === 'fa',
    }">
      <v-col cols="1" lg="1" xl="1" md="1" class="hidden-sm-and-down">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar v-bind="attrs" v-on="on" :size="avatarSize" class="logo-white-bg pa-1 text-center" :class="{
              'mr-xl-5 mr-lg-3 mr-md-3':
                news.source.locale === 'fa' && $vuetify.rtl === true,
              'ml-xl-5 ml-lg-3 ml-md-3':
                news.source.locale === 'en' && $vuetify.rtl === true,
              'me-xl-5 me-lg-3 me-md-3':
                news.source.locale === 'fa' && $vuetify.rtl === false,
              'ms-xl-5 ms-lg-3 ms-md-3':
                news.source.locale === 'en' && $vuetify.rtl === false,
            }" style="cursor: pointer">
              <v-img contain :src="getNewsSourceImage()"></v-img>
            </v-avatar>
          </template>
          <span>{{ getSourceName }}</span>
        </v-tooltip>
        <!-- </v-card-text> -->
      </v-col>
      <v-col cols="12" lg="11" xl="11" md="11">
        <v-card-actions style="padding: 5px" class="hidden-md-and-up">
          <template v-for="(tag, i) in TagsMobileFiltered">
            <div :key="i" class="mt-1" :class="{
              'mr-3': i === 0 && news.source.locale === 'fa',
              'mr-2': i != 0 && news.source.locale === 'fa',
              'ml-3': i === 0 && news.source.locale === 'en',
              'ml-2': i != 0 && news.source.locale === 'en',
            }" style="cursor: pointer">
              <v-avatar size="22">
                <v-img contain height="22" width="22" :src="`${staticImgUrl}/coins/${tag.symbol.toUpperCase()}.png`">
                </v-img>
              </v-avatar>
            </div>
          </template>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="copyToClipboard()">
            <v-icon size="15">mdi-share-variant</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="hidden-md-and-up post-title mb-md-2 mt-n3" :class="{
          'mr-1': news.source.locale === 'fa',
          'ml-1': news.source.locale === 'en',
        }" v-html="news.title + sourceAndDateHtml(news)" @click.stop="goToLink(news.url)">
        </v-card-text>
        <v-row class="
                          hidden-sm-and-down
                          text-center text-justify
                          mb-md-5
                          mt-1
                          align-start
                          justify-start
                        ">
          <v-col order="1" cols="10" xl="11" lg="10" :class="{
            'pr-5': news.source.locale === 'fa',
            'pl-5': news.source.locale === 'en',
          }">
            <div class="post-title" v-html="news.title" @click.stop="goToLink(news.url)"></div>
          </v-col>
          <v-col order="2" cols="1" lg="1" md="0" v-if="!$vuetify.breakpoint.xl">
            <v-spacer />
          </v-col>
          <v-col order="3" cols="1" class="py-0 pt-1" :class="{
            'mr-lg-n2': news.source.locale === 'fa',
            'ml-lg-n1': news.source.locale === 'en',
          }">
            <div :class="{
              'share-btn-pos-xl-fa':
                $vuetify.breakpoint.xl && news.source.locale === 'fa',
              'share-btn-pos-xl-en':
                $vuetify.breakpoint.xl && news.source.locale === 'en',
            }">
              <v-btn icon @click.stop="copyToClipboard()">
                <v-icon size="15">mdi-share-variant</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <!-- statics section -->
        <!-- xs screens -->
        <v-card-text class="hidden-md-and-up mt-n2">
          <v-row class="justify-space-around align-center">
            <v-col cols="2">
              <v-btn icon>
                <v-img contain alt="" title="" :height="view_size" :width="view_size" :src="
                  isDark
                    ? `${staticImgUrl}/logos/views-dark.png`
                    : `${staticImgUrl}/logos/views.png`
                "></v-img>
              </v-btn>
            </v-col>
            <v-col cols="4">
              <span :class="{
                'stats-font-fa': locale === 'fa',
                'stats-font-en': locale === 'en',
                'views-color-light': !isDark === true,
                'views-color-dark': isDark === true,
                'mr-2': news.source.locale === 'fa',
                'ml-2': news.source.locale === 'en',
              }">{{ shrinkText(news.views) }}</span>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click.stop="voteForNews(1, 1)">
                <v-img contain alt="" title="" :height="star_size" :width="star_size" :src="
                  isDark
                    ? `${staticImgUrl}/logos/star-dark.png`
                    : `${staticImgUrl}/logos/star.png`
                "></v-img>
              </v-btn>
            </v-col>
            <v-col cols="4">
              <span :class="{
                'stats-font-fa': locale === 'fa',
                'stats-font-en': locale === 'en',
                'mr-2': news.source.locale === 'fa',
                'ml-2': news.source.locale === 'en',
              }" class="importance-color">{{ shrinkText(news.importance) }}</span>
            </v-col>
          </v-row>
          <!--  -->
          <v-row class="justify-space-around align-center">
            <v-col cols="2">
              <v-btn icon @click.stop="voteForNews(2, 1)">
                <v-img contain alt="" title="" :height="bull_size" :width="bull_size" :src="
                  isDark
                    ? `${staticImgUrl}/logos/bull-dark.png`
                    : `${staticImgUrl}/logos/bull.png`
                ">
                </v-img>
              </v-btn>
            </v-col>
            <v-col cols="4">
              <span :class="{
                'stats-font-fa': locale === 'fa',
                'stats-font-en': locale === 'en',
                'mr-2': news.source.locale === 'fa',
                'ml-2': news.source.locale === 'en',
              }" class="bullish-color">{{ shrinkText(news.bullish) }}</span>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click.stop="voteForNews(2, -1)">
                <v-img contain alt="" title="" :height="bear_size" :width="bear_size" :src="
                  isDark
                    ? `${staticImgUrl}/logos/bear-dark.png`
                    : `${staticImgUrl}/logos/bear.png`
                "></v-img>
              </v-btn>
            </v-col>
            <v-col cols="4">
              <span :class="{
                'stats-font-fa': locale === 'fa',
                'stats-font-en': locale === 'en',
                'mr-2': news.source.locale === 'fa',
                'ml-2': news.source.locale === 'en',
              }" class="bearish-color">{{ shrinkText(news.bearish) }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- for larger than 400px width screens -->
        <v-card-actions class="mb-1 hidden-sm-and-down">
          <span v-text="pubDate(news.published_date, locale)" :class="{
            'post-date-fa-light-lg ms-2':
              locale === 'fa' && $vuetify.rtl === false && !isDark,
            'post-date-fa-dark-lg ms-2':
              locale === 'fa' && $vuetify.rtl === false && isDark,
            'post-date-en-light-lg me-2':
              locale === 'en' && $vuetify.rtl === false && !isDark,
            'post-date-en-dark-lg me-2':
              locale === 'en' && $vuetify.rtl === false && isDark,
            'post-date-fa-light-lg ml-2':
              locale === 'fa' && $vuetify.rtl === true && !isDark,
            'post-date-fa-dark-lg ml-2':
              locale === 'fa' && $vuetify.rtl === true && isDark,
            'post-date-en-light-lg mr-2':
              locale === 'en' && $vuetify.rtl === true && !isDark,
            'post-date-en-dark-lg mr-2':
              locale === 'en' && $vuetify.rtl === true && isDark,
          }" class="hidden-sm-and-down"></span>
          <span v-for="(tag, i) in TagsMobileFiltered" :key="i">
            <v-menu open-on-hover bottom offset-y :left="$vuetify.rtl ? true : false" transition="slide-y-transition"
              :content-class="
                $vuetify.theme.dark ? 'my-menu-dark' : 'my-menu-light'
              ">
              <template v-slot:activator="{ on }">
                <v-avatar size="22" v-on="on" class="mx-1" style="cursor: pointer">
                  <v-img :alt="tag.symbol" contain height="22" width="22"
                    :src="`${staticImgUrl}/coins/${tag.symbol.toUpperCase()}.png`"></v-img>
                </v-avatar>
              </template>
              <v-card flat class="px-1" :style="
                $vuetify.rtl
                  ? `left: ${27 + (tag.symbol.length - 3) * 3}%`
                  : `right: ${30 + (tag.symbol.length - 3) * 3}%`
              " color="primary">
                <v-list-item-content class="justify-center">
                  <div class="mx-2 text-center" v-text="tag.symbol"
                    style="font-size: 0.7rem; font-weight: 600; color: white"></div>
                </v-list-item-content>
              </v-card>
            </v-menu>
          </span>
          <span v-if="remainedTagsMobileLength > 0">
            <div class="mx-1">
              <v-avatar size="23" color="green lighten-3">
                <div v-text="'+\u200c' + remainedTagsMobileLength + '\u200c'" class="text-center post-tag-more-info"
                  style="color: green"></div>
              </v-avatar>
            </div>
          </span>
          <!-- <template v-if="timeDifference">
            <v-chip
              label
              color="red"
              small
              v-text="news.source.locale === 'fa' ? 'جدید' : 'NEW'"
            >
            </v-chip>
          </template> -->
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-img contain :alt="
              locale === 'fa'
                ? localization.fa.news.actions.view
                : localization.en.news.actions.view
            " :title="
  locale === 'fa'
    ? localization.fa.news.actions.view
    : localization.en.news.actions.view
" :height="view_size" :width="view_size" :src="
  isDark
    ? `${staticImgUrl}/logos/views-dark.png`
    : `${staticImgUrl}/logos/views.png`
"></v-img>
          </v-btn>
          <span :class="{
            'stats-font-fa': locale === 'fa',
            'stats-font-en': locale === 'en',
            'views-color-light': !isDark === true,
            'views-color-dark': isDark === true,
          }" class="views-color mx-3">{{ shrinkText(news.views) }}</span>
          <v-btn icon @click.stop="voteForNews(1, 1)">
            <v-img contain :alt="
              locale === 'fa'
                ? localization.fa.news.actions.star
                : localization.en.news.actions.star
            " :title="
  locale === 'fa'
    ? localization.fa.news.actions.star
    : localization.en.news.actions.star
" :height="star_size" :width="star_size" :src="
  isDark
    ? `${staticImgUrl}/logos/star-dark.png`
    : `${staticImgUrl}/logos/star.png`
"></v-img>
          </v-btn>
          <span :class="{
            'stats-font-fa': locale === 'fa',
            'stats-font-en': locale === 'en',
          }" class="importance-color mx-3">{{ shrinkText(news.importance) }}</span>
          <v-btn icon @click.stop="voteForNews(2, 1)">
            <v-img contain :alt="
              locale === 'fa'
                ? localization.fa.news.actions.bull
                : localization.en.news.actions.bull
            " :title="
  locale === 'fa'
    ? localization.fa.news.actions.bull
    : localization.en.news.actions.bull
" :height="bull_size" :width="bull_size" :src="
  isDark
    ? `${staticImgUrl}/logos/bull-dark.png`
    : `${staticImgUrl}/logos/bull.png`
">
            </v-img>
          </v-btn>
          <span :class="{
            'stats-font-fa': locale === 'fa',
            'stats-font-en': locale === 'en',
          }" class="bullish-color mx-3">{{ shrinkText(news.bullish) }}</span>
          <v-btn icon @click.stop="voteForNews(2, -1)">
            <v-img contain :alt="
              locale === 'fa'
                ? localization.fa.news.actions.bear
                : localization.en.news.actions.bear
            " :title="
  locale === 'fa'
    ? localization.fa.news.actions.bear
    : localization.en.news.actions.bear
" :height="bear_size" :width="bear_size" :src="
  isDark
    ? `${staticImgUrl}/logos/bear-dark.png`
    : `${staticImgUrl}/logos/bear.png`
"></v-img>
          </v-btn>
          <span :class="{
            'stats-font-fa': locale === 'fa',
            'stats-font-en': locale === 'en',
          }" class="bearish-color mx-3">{{ shrinkText(news.bearish) }}</span>
        </v-card-actions>
      </v-col>
    </v-row>
    <message-show ref="msg"></message-show>
  </v-card>
  <!-- </div> -->
</template>


<script>
import localeMixin from "../mixins/localeMixin";
import newsSources from "../mixins/newsSources";
import apiMixin from "../mixins/apiMixin";
import utils from "../mixins/utils";
import MessageShow from "./MessageShow.vue";


const BASE_URL = process.env.VUE_APP_BASE_URL;
//
export default {
  components: { MessageShow },
  props: ["news", "locale"],
  name: "NewsCard",
  mixins: [localeMixin, apiMixin, newsSources, utils],
  data: () => ({
    // locale: "fa",
    million: 1000000,
    thousand: 1000,
    bull_size: 35,
    bear_size: 35,
    view_size: 30,
    star_size: 30,
    base_url: BASE_URL,
  }),
  methods: {
    newsSourceLocaleTitle: function (news_source_id) {
      try {
        const news_list = this.sources.fa.items.filter(
          (item) => item.id === news_source_id
        )[0];
        return news_list.title;
      } catch (e) {
        return "";
      }
    },
    sourceAndDateHtml: function (news) {
      return news.source.locale === "en"
        ? `<br/><div class="mt-2">
      <span style='font-size: 0.8rem; font-weight: 600'>${news.source.title
        }</span><span class='mx-2'>${" · "}</span>
      <span style='font-size: 0.8rem; font-weight: 600'>${this.pubDate(
          news.published_date,
          news.source.locale
        )}</span></div>`
        : `<br/><div class="mt-2">
      <span style='font-size: 0.8rem; font-weight: 600'>${this.newsSourceLocaleTitle(
          news.source.custom_id
        )}</span><span class='mx-2'>${" · "}</span>
      <span style='font-size: 0.8rem; font-weight: 600; font-family:IRANSans'>${this.pubDate(
          news.published_date,
          news.source.locale
        )}</span></div>`;
    },
    goToLink: function (link) {
      var data = { id: this.news.id };
      this.$axios
        .put(this.api.news_views_edit, data, { withCredentials: true })
        .then((res) => {
          if (res.data.status === 1) {
            this.news.views += 1;
          }
        });
      window.open(link, "_blank");
    },
    shrinkText: function (number) {
      if (number >= this.million) {
        return this.locale === "en"
          ? `${(number / this.million).toFixed(1)} M`
          : `${(number / this.million).toFixed(1)}` + "M ";
      } else if (number >= this.thousand) {
        return this.locale === "en"
          ? `${(number / this.thousand).toFixed(1)} K`
          : `${(number / this.thousand).toFixed(1)}` + "K ";
      } else {
        return number;
      }
    },
    getNewsSourceImage: function () {
      if (this.news.source.locale === "en") {
        return `${this.staticImgUrl}/news_logo/EN/${this.news.source.title
          .replace(/\s/g, "")
          .toLowerCase()}.svg`;
      } else {
        return `${this.staticImgUrl}/news_logo/FA/${this.getFa2En(
          this.news.source.custom_id
        )}.svg`;
      }
    },
    getFa2En: function (fa_id) {
      for (let i = 0; i < this.sources.fa.items.length; i++) {
        if (this.sources.fa.items[i].id === fa_id) {
          return this.sources.fa.items[i].en;
        }
      }
    },
    copyToClipboard: function () {
      // /* Select the text field */
      /* Copy the text inside the text field */
      // development
      try {
        // general
        let text = `${this.base_url}/${this.$vuetify.rtl ? "fa" : "en"
          }?news_locale=${this.news.source.locale}&news_share_id=${this.news.slug
          }`;
        navigator.clipboard.writeText(text);

        /* Alert the copied text */
        // alert("Copied the text: " + text);
        this.$refs.msg.showMessage(
          this.$vuetify.rtl
            ? "لینک با موفقیت کپی شد"
            : "Link Copied Successfully",
          "success"
        );
      } catch (e) {
        this.$refs.msg.showMessage(
          this.$vuetify.rtl
            ? "در کپی کردن لینک مشکلی به وجود آمد"
            : "An Error occured while copying the link",
          "error"
        );
      }
    },
    voteForNews: function (vote_sub, vote_decision) {
      var data = {
        subject: vote_sub,
        decision: vote_decision,
        id: this.news.id,
      };
      this.$axios
        .put(this.api.news_votes, data, { withCredentials: true })
        .then((res) => {
          let data = res.data.data;
          if (res.data.status === 1) {
            this.$refs.msg.showMessage(
              this.$vuetify.rtl
                ? "رای شما با موفقیت ثبت شد"
                : "Your vote submitted successfully",
              "success"
            );
            this.news.views = data.views;
            this.news.importance = data.importance;
            this.news.bullish = data.bullish;
            this.news.bearish = data.bearish;
          } else {
            if (vote_sub === 1) {
              this.news.views = data.views;
              this.news.importance = data.importance;
              this.news.bullish = data.bullish;
              this.news.bearish = data.bearish;
              this.$refs.msg.showMessage(
                this.$vuetify.rtl
                  ? "رای شما با موفقیت حذف شد"
                  : "Your vote removed successfully",
                "success"
              );
            } else {
              this.$refs.msg.showMessage(
                this.$vuetify.rtl
                  ? "رای شما در سیستم ثبت شده است"
                  : "You have voted for this news already!",
                "error"
              );
            }
          }
        });
    },
  },
  computed: {
    staticImgUrl: function () {
      return process.env.VUE_APP_STATIC_IMG_URL;
    },
    isDark: function () {
      return this.$vuetify.theme.dark;
    },
    getSourceName: function () {
      if (this.news.source.locale === "en") {
        return this.news.source.title;
      } else {
        for (var i = 0; i < this.sources.fa.items.length; i++) {
          if (this.sources.fa.items[i].id === this.news.source.custom_id) {
            return this.sources.fa.items[i].title;
          }
        }
        return "";
      }
    },
    avatarSize: function () {
      if (this.$vuetify.breakpoint.xl) {
        return "48";
      } else if (this.$vuetify.breakpoint.width > 1500) {
        return "40";
      } else if (this.$vuetify.breakpoint.width > 1400) {
        return "38";
      } else if (this.$vuetify.breakpoint.width > 1000) {
        return "37";
      } else {
        return "34";
      }
    },
    TagsMobileFiltered: function () {
      return this.news.tags.slice(0, 3);
    },
    remainedTagsMobile: function () {
      return this.news.tags.slice(3);
    },
    remainedTagsMobileLength: function () {
      return this.news.tags.length - 3;
    },
    timeDifference: function () {
      return this.news.recent;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-light-color: #146a5f;
$primary-dark-color: #404548;
$primary-new-post-color: #ff0000;
$searched-news-border-color: #1abc9c;
$recent-post-box-shadow-color: #1abc9c;
// $primary-dark-color: #c0cecc;
$logo-bg-color: white;
$views-logo-light-color: #334a5e;
$views-logo-dark-color: #ced3d6;
$date-light-color: #616161;
$date-dark-color: #ced3d6;

@media only screen and (max-width: 800px) {
  .custom-hidden-sm-and-down {
    display: none !important;
  }
}

@media only screen and (min-width: 501px) {
  .custom-hidden-md-and-up {
    display: none !important;
  }
}

@media (max-width: 540px) {
  .post-date-fa-light-md {
    font-family: IRANSans;
    font-size: 0.8rem;
    font-weight: 400;
    color: $date-light-color;
  }

  .post-date-fa-dark-md {
    font-family: IRANSans;
    font-size: 0.8rem;
    font-weight: 400;
    color: $date-dark-color;
  }

  .post-date-en-light-md {
    font-size: 0.8rem;
    font-weight: 400;
    color: $date-light-color;
  }

  .post-date-en-dark-md {
    font-size: 0.8rem;
    font-weight: 400;
    color: $date-dark-color;
  }
}

@media (min-width: 541px) {
  .post-date-fa-light-lg {
    font-family: IRANSans;
    font-size: 0.78rem;
    font-weight: 400;
    color: $date-light-color;
  }

  .post-date-fa-dark-lg {
    font-family: IRANSans;
    font-size: 0.78rem;
    font-weight: 400;
    color: $date-dark-color;
  }

  .post-date-en-light-lg {
    font-size: 0.78rem;
    font-weight: 400;
    color: $date-light-color;
  }

  .post-date-en-dark-lg {
    font-size: 0.78rem;
    font-weight: 400;
    color: $date-dark-color;
  }
}

// .post-tag {
//   font-weight: 580;
//   margin-left: 3px;
//   margin-top: 1px;
//   font-size: 0.75rem;
// }

.post-tag-more-info {
  margin-top: 2px;
  font-size: 0.75rem;
  font-weight: 600;
}

.logo-white-bg {
  background-color: $logo-bg-color;
}

.custom-card-border-light {
  border-color: $primary-light-color;
}

.custom-card-border-dark {
  border-color: $primary-dark-color;
}

.recent-post-box-shadow-fa {
  box-shadow: 4px 0px $recent-post-box-shadow-color;
}

.recent-post-box-shadow-en {
  box-shadow: -4px 0px $recent-post-box-shadow-color;
}

.recent-post-box-shadow-mobile {
  box-shadow: 0px -4px $recent-post-box-shadow-color;
}

.searched-post-box-shadow {
  border-color: $searched-news-border-color;
  border-width: medium;
}

.post-title {
  cursor: pointer;
  font-size: 0.925rem;
  font-weight: 500;
}

.post-direction-en {
  direction: ltr;
}

.post-direction-fa {
  direction: rtl;
}

.stats-font-fa {
  font-family: IRANSans;
  font-weight: 700;
  font-size: 0.943rem;
}

.stats-font-en {
  font-weight: 700;
  font-size: 0.943rem;
  // margin-left: 10px;
}

.bullish-color {
  color: #1abc9c;
}

.bearish-color {
  color: #c92816;
}

.views-color-light {
  color: $views-logo-light-color;
}

.views-color-dark {
  color: $views-logo-dark-color;
}

.importance-color {
  color: #f9bf63;
}

.my-menu-light {
  margin-top: 12px;
  contain: initial;
  overflow: visible;
  box-shadow: none;
}

.my-menu-light::before {
  position: absolute;
  content: "";
  top: 1.2px;
  transform: translateY(-100%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $primary-light-color;
}

.my-menu-dark {
  margin-top: 12px;
  contain: initial;
  overflow: visible;
  box-shadow: none;
}

.my-menu-dark::before {
  position: absolute;
  content: "";
  top: 1.2px;
  transform: translateY(-100%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $primary-light-color;
}

.share-btn-pos-xl-fa {
  margin-right: 12px;
}

.share-btn-pos-xl-en {
  margin-left: 12px;
}
</style>