<template>
  <v-container
    fill-height
    v-if="$vuetify.rtl && $router.currentRoute.name.split('-')[0] === 'fa'"
  >
    <v-row class="ma-2 justify-center align-start">
      <v-card
        max-width="1000"
        outlined
        :class="{
          'custom-card-border-light': !$vuetify.theme.dark,
          'custom-card-border-dark': $vuetify.theme.dark,
        }"
      >
        <v-card-title class="about-title">{{ title.fa }}</v-card-title>
        <v-card-text
          class="about-text text-justify"
          style="font-family: IRanSans"
        >
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>1&nbsp;</strong
            ><span lang="ar-SA"
              ><strong><span lang="fa-IR">مقدمه</span></strong></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >با دسترسی به این وب سایت، موافقت می‌کنید که به این شرایط و ضوابط
              استفاده (قوانین و مقررات) متعهد خواهید بود. دسترسی شما به وب سایت
              و استفاده از آن منوط به این شرایط است.</span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <br />
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>2&nbsp;</strong
            ><span lang="ar-SA"
              ><strong><span lang="fa-IR">محتوای وب سایت</span></strong></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">
              وب سایت Zagros.live برای تسهیل دسترسی کاربران به اخبار بازار
              ارزهای دیجیتال طراحی شده است و هیچ نقشی در تولید اخبار ندارد.
              بنابراین وب سایت زاگرس لایو هیچ مسئولیتی در قبال محتوای بخش اخبار
              سایت ندارد و ناشران هر منبع خبری، بطور مستقیم مسئول اخبار منتشر
              شده هستند.
            </span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >مسئولیت محتوای آگهی&zwnj;ها بر عهده شرکت&zwnj;ها یا افرادی است که
              آگهی را به تیم زاگرس لایو ارسال می&zwnj;کنند</span
            >.
            <span lang="fa-IR"
              >بنابراین قبل از انجام هر فعالیتی، صحت و اعتبار آگهی&zwnj;دهنده را
              بررسی کنید</span
            >.
            <span lang="fa-IR"
              >استفاده از هرگونه اطلاعات، محصولات و خدمات با مسئولیت خودتان
              است</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >شما به این نکته آگاه هستید که با استفاده از وب&zwnj;سایت ممکن است
              در معرض محتوایی قرار بگیرید که شاید توهین&zwnj;آمیز، ناپسند یا
              اعتراض&zwnj;آمیز به نظرتان برسد</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <br />
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>3&nbsp;</strong
            ><span lang="ar-SA"
              ><strong><span lang="fa-IR">مالکیت معنوی</span></strong></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">وب سایت&nbsp;</span>Zagros.live
            <span lang="fa-IR"
              >مجوزی غیر انحصاری، غیرقابل انتقال و محدود برای مشاهده و استفاده
              از اطلاعاتی که بطور مستقیم از طریق وب سایت قابل دسترسی است به شما
              اعطا می کند</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >شما موافقت می&zwnj;کنید که مشروط به استفاده از وب سایت مطابق با
              مجوز محدودی که به شما اعطا شده است، مالکیت معنوی زاگرس لایو را نقض
              نخواهید کرد و هیچ بخشی از وب سایت را تغییر، کپی و توزیع نخواهید
              کرد</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >هر یک از علائم تجاری یا آرم&zwnj;های نمایش داده شده در وب سایت
              ممکن است علائم ثبت شده یا ثبت نشده&nbsp;</span
            >Zagros.live <span lang="fa-IR">باشد</span>.
            <span lang="fa-IR"
              >شما مجاز به استفاده از این علائم تجاری یا لوگوها بدون اجازه صریح
              تیم زاگرس لایو نیستید</span
            >.
            <span lang="fa-IR"
              >هرگونه استفاده غیرمجاز از علائم تجاری یا آرم اکیداً ممنوع
              است</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <br />
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>4&nbsp;</strong
            ><span lang="ar-SA"
              ><strong><span lang="fa-IR">محتوای کاربر</span></strong></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >شما موافقت می&zwnj;کنید که هیچ محتوایی را بارگزاری یا ارائه
              نمی&zwnj;کنید که</span
            >:
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="fa-IR"
              >افتراآمیز، کلاهبرداری، غیرقانونی، تهدیدآمیز، ارعاب کننده،
              آزاردهنده، مضر، نفرت انگیز، توهین آمیز، متجاوز، مبتذل، ناپسند،
              تجاوز به حریم خصوصی دیگران، جنسیت گرا، نژادپرستی، خشونت آمیز و
              تحقیرکننده است</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="ar-SA"
              ><span lang="fa-IR"
                >تجاوز به منافع معنوی یا دیگر مالکیت اشخاص ثالث؛</span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="fa-IR"
              >جعل هویت شخص یا نهاد دیگر، تلاش برای درخواست اطلاعات شخصی از
              کاربر دیگر، حاوی زبان یا تصاویر غیراخلاقی جنسی، تبلیغ یا فروش
              محصولات یا خدماتی مانند اسلحه گرم، دخانیات یا الکل، محصولات و
              خدمات بزرگسالان</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="fa-IR"
              >حاوی هرزنامه&zwnj;ها، طرح&zwnj;های فروش و بازاریابی هرمی،
              ویروس&zwnj;های رایانه&zwnj;ای، فایل&zwnj;ها یا برنامه&zwnj;ها یا
              سایر اجزای مضر است که برای قطع، تخریب، تغییر یا محدود کردن عملکرد
              وب&zwnj;سایت یا هر نرم&zwnj;افزار رایانه&zwnj;ای دیگر طراحی
              شده&zwnj;اند</span
            >.
            <span lang="fa-IR"
              >سخت افزار یا سایر تجهیزات الکترونیکی، اطلاعاتی که به هر نحوی بر
              استفاده یا عمل کاربر دیگر از وب سایت تأثیر بگذارد یا سایر کاربران
              را به نقض این شرایط تشویق یا ترغیب کند</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="fa-IR"
              >هر گونه قانون، اساسنامه یا مقررات را نقض کند</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="ar-SA"
              ><span lang="fa-IR"
                >جعل اطلاعات برای پنهان کردن منشا محتوای کاربر؛ و</span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            -
            <span lang="fa-IR"
              >هر فرد دیگری را به انجام هر یک از رفتارهای فوق تشویق یا تحریک
              کند</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            &nbsp;
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>5&nbsp;</strong
            ><span lang="ar-SA"
              ><strong><span lang="fa-IR">محدودیت مسئولیت</span></strong></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >شما موافقت می&zwnj;کنید که تیم زاگرس لایو مسئولیتی در قبال شما یا
              شخص دیگری و در قبال ضرر و زیان&nbsp;</span
            >(<span lang="fa-IR"
              >بدون محدودیت، هرگونه ضرر مستقیم، غیرمستقیم، خاص یا تبعی</span
            >)
            <span lang="fa-IR">ناشی از نقض این شرایط، در قبال جرم&nbsp;</span
            >(<span lang="fa-IR">از جمله سهل انگاری</span>)
            <span lang="fa-IR">ندارد</span>.
            <span lang="fa-IR">یا ناشی از یا در ارتباط با</span>:
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">الف&nbsp;</span>-
            <span lang="ar-SA"
              ><span lang="fa-IR">استفاده از وب سایت؛</span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">ب&nbsp;</span>-
            <span lang="fa-IR"
              >استفاده تیم زاگرس لایو از اطلاعات ارائه شده توسط شما
              به&nbsp;</span
            >zagros.live
            <span lang="ar-SA"><span lang="fa-IR">از طریق وب سایت؛</span></span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">ج&nbsp;</span>-
            <span lang="fa-IR"
              >عدم امکان دسترسی به وب سایت به هر دلیل و به هر نحوی که از جمله
              سهل انگاری&nbsp;</span
            >(<span lang="fa-IR">بدون محدودیت</span>).
            <span lang="ar-SA"><span lang="fa-IR">و</span></span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">د&nbsp;</span>-
            <span lang="fa-IR"
              >استفاده از رمز عبور وب سایت توسط شما یا هر شخص ثالثی که رمز عبور
              را در اختیار او قرار داده&zwnj;اید</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <br />
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>6&nbsp;</strong
            ><span lang="ar-SA"
              ><strong><span lang="fa-IR">غرامت</span></strong></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >شما در قبال هرگونه اقدام، ضرر، خسارت، رسیدگی و هزینه&nbsp;</span
            >(<span lang="fa-IR">شامل هزینه&zwnj;های حقوقی</span>)
            <span lang="fa-IR"
              >ناشی از نقض یا عدم رعایت مقررات مسئول هستید و باید به تیم زاگرس
              لایو بابت اعمال غیرقانونی پاسخگو باشید و در صورت نیاز غرامت
              دهید</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <br />
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>7&nbsp;</strong
            ><span lang="ar-SA"
              ><strong
                ><span lang="fa-IR">تعلیق یا خاتمه دسترسی</span></strong
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">وب سایت&nbsp;</span>Zagros.live
            <span lang="fa-IR"
              >این حق را برای خود محفوظ می&zwnj;دارد که بدون اطلاع قبلی از
              دسترسی یا استفاده از تمام یا بخشی از وب سایت توسط شما جلوگیری
              کند</span
            >.
            <span lang="fa-IR"
              >اگر هر گونه رفتاری را انجام دهید که تیم زاگرس لایو معتقد باشد به
              صلاحدید خود هر یک از مفاد این مقررات و حقوق تیم زاگرس لایو یا
              اشخاص ثالث را نقض &zwnj;کرده باشد و یا رفتاری نامناسب از سوی شما
              انجام شده باشد، از دسترسی شما به سایت جلوگیری خواهد شد و در صورت
              ادامه این رفتار با ساخت حساب کاربری جعلی، اطلاعات شما در اختیار
              مراجع قانونی قرار خواهد گرفت</span
            >.<br />&nbsp;
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>8&nbsp;</strong
            ><span lang="ar-SA"
              ><strong><span lang="fa-IR">پیوند</span></strong></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >این وب سایت حاوی پیوندهایی به وب سایت&zwnj;های شخص ثالث است</span
            >.
            <span lang="fa-IR"
              >درج لینک به این معنی نیست که تیم زاگرس لایو وب سایت&zwnj;های لینک
              شده را معتبر می&zwnj;داند یا تایید کرده است</span
            >.
            <span lang="fa-IR"
              >تیم زاگرس لایو هیچ ضمانت یا نمایندگی در رابطه با این لینک&zwnj;ها
              یا وب سایت&zwnj;های لینک شده ندارد و شما با مسئولیت خود وارد وب
              سایت&zwnj;های شخص ثالث می&zwnj;شوید</span
            >.
            <span lang="fa-IR"
              >مسئولیت بررسی هر گونه شرایط استفاده یا سیاست حفظ حریم خصوصی مربوط
              به چنین وب سایت&zwnj;هایی با شماست</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >وب سایت ممکن است تبلیغات شخص ثالث را به نمایش بگذارد یا نمایش
              دهد</span
            >.
            <span lang="fa-IR"
              >تبلیغ کنندگان شخص ثالث مسئول صحت کلیه اظهارات ارائه شده در آن
              تبلیغات هستند</span
            >.
            <span lang="fa-IR"
              >نه تیم زاگرس لایو، نه کارکنان آن یا نهادهای مرتبط، کالاها یا
              خدماتی را که ممکن است در وب&zwnj;سایت تبلیغ شود، توصیه یا تأیید
              نمی&zwnj;کنند، و همچنین کالاها یا خدماتی را برای فروش ارائه
              نمی&zwnj;کنند و هیچ گونه نمایندگی دیگری در مورد آنها ارائه
              نمی&zwnj;کنند</span
            >.
            <span lang="fa-IR"
              >اگر تصمیم به سفارش محصول یا خدماتی دارید که توسط شخص ثالث در وب
              سایت تبلیغ می&zwnj;شود، این کار را با مسئولیت خود انجام
              می&zwnj;دهید</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <br />
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>9&nbsp;</strong
            ><span lang="ar-SA"
              ><strong><span lang="fa-IR">بازاریابی</span></strong></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >هنگام ثبت نام، همچنین با دریافت ایمیل تبلیغاتی از وب
              سایت&nbsp;</span
            >zagros.live <span lang="fa-IR">موافقت می&zwnj;کنید</span>.
            <span lang="fa-IR"
              >می&zwnj;توانید در هر زمان با لغو اشتراک از هر ایمیل بازاریابی، از
              دریافت این دسته از ایمیل&zwnj;ها انصراف دهید</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            &nbsp;
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>10&nbsp;</strong
            ><span lang="ar-SA"
              ><strong><span lang="fa-IR">عمومی</span></strong></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >تیم زاگرس لایو این حق را برای خود محفوظ می&zwnj;دارد که در هر
              زمان بدون اطلاع شما، این شرایط استفاده را اصلاح کند</span
            >.
            <span lang="fa-IR"
              >ادامه استفاده شما از وب سایت پس از چنین تغییری به منزله موافقت
              شما برای رعایت شرایط استفاده، مطابق با اصلاحات است</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >اگر هر یک از این شرایط استفاده نامعتبر یا غیرقابل اجرا باشد، ممکن
              است حذف شوند و بقیه شرایط استفاده همچنان به قوت خود باقی خواهند
              ماند</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >هیچ چشم پوشی از هر گونه نقض این شرایط به عنوان چشم پوشی از سایر
              موارد نقض این شرایط تلقی نمی&zwnj;شود</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">این شرایط آخرین بار در&nbsp;</span>10
            <span lang="fa-IR">بهمن&nbsp;</span>1400
            <span lang="fa-IR">شمسی به روز رسانی شده است</span>.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >برای کسب اطلاعات بیشتر درباره شرایط استفاده از سایت زاگرس لایو،
              لطفا با ایمیل زیر در تماس باشید</span
            >:
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <u><a href="mailto:privacy@zagros.live">privacy@zagros.live</a></u>
          </p>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
  <v-container class="my-4" v-else>
    <v-row class="ma-2 justify-center align-start">
      <v-card
        max-width="1000"
        outlined
        :class="{
          'custom-card-border-light': !$vuetify.theme.dark,
          'custom-card-border-dark': $vuetify.theme.dark,
        }"
      >
        <v-card-title class="about-title">{{ title.en }}</v-card-title>
        <v-card-text class="about-text text-justify">
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
              font-size: 1.3rem;
            "
          >
            <strong>1 Introduction</strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            By accessing this Website, you agree to be bound by these Terms and
            Conditions of Use (Terms of Use). Your access to and use of the
            Website is subject to these Terms of Use.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
              font-size: 1.3rem;
            "
          >
            <strong>2 Website Content</strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            Zagros.live website is designed to facilitate users&apos; access to
            cryptocurrency market news and has no role in producing news. The
            website has no responsibility for the news content it shows from
            various Persian and English sources. Publishers are directly
            responsible for the news.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            The responsibility of the advertisements content with the companies
            or individuals who submit the advertisement to the Zagros Live team.
            Therefore, before performing any activity, check the authenticity
            and credibility of the advertiser. Using any information, products
            and services, at your own risk.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            You understand that by using the Website you may be exposed to
            Website Content that you may find offensive, indecent or
            objectionable.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
              font-size: 1.3rem;
            "
          >
            <strong>3 Intellectual Property</strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            Zagros.live website grants you a non-exclusive, non-transferable,
            limited licence to view and use information directly accessible
            through the Website.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            You agree that, subject to your use of the Website in accordance
            with the limited Licence granted to you, you will not infringe
            Zagros Live intellectual property in the Website and will not
            modify, copy, frame, distribute or communicate any part of the
            Website.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            Any of the trademarks or logos displayed on the Website may be
            registered or unregistered marks of Zagros.live. You may not use
            these trademarks or logos without the express permission of Zagros
            Live Team. Any unauthorized use of the trademarks or logos is
            strictly prohibited.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
              font-size: 1.3rem;
            "
          >
            <strong>4 User Content</strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            You agree that you will not upload or otherwise provide any User
            Content which:
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - is defamatory, fraudulent, unlawful, threatening, intimidating,
            harassing, harmful, hateful, abusive, tortious, vulgar, obscene,
            invasive of another&rsquo;s privacy, sexist, racist, homophobic,
            violent, degrading;
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - infringes the intellectual or other proprietary interests of third
            parties;
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - impersonates another person or entity, attempts to solicit
            personal information from another user, contains sexually explicit
            language or images, advertises or promotes the sale of products or
            services such as firearms, tobacco or alcohol, adult products and
            services.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - contains spam, chain letters, pyramid and other such selling and
            marketing schemes, computer viruses, computer code, files or
            programs or other harmful components that are designed to interrupt,
            destroy, change or limit the functionality of the Website or any
            other computer software, hardware or other electronic equipment,
            information which in any way impinges on another user&rsquo;s use or
            enjoyment of the Website or otherwise breaches or encourages other
            users to breach these Terms;
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - violates any law, statute or regulation;
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - forges information to disguise the origin of any User Content; and
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            - encourages or incites any other person to engage in any of the
            above behavior.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
              font-size: 1.3rem;
            "
          >
            <strong>5 Limitation of Liability</strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            You agree that Zagros Live Team is not liable to you or anyone else
            for any loss or damage (including, without limitation, any direct,
            indirect, special or consequential loss) arising as a result of
            breach of these Terms, in tort (including negligence) or otherwise
            arising out of, or in connection, with:
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            A - The use of the Website;
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            B - The use by Zagros Live Team of information provided by you to
            zagros.live through the Website;
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            C - Being unable to access the Website for whatever reason and
            however arising, including (without limitation) negligence; and
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            D - The use of your Website password by you or any third party to
            whom you have made the password available.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              font-size: 1.3rem;
              line-height: 168%;
            "
          >
            <strong>6 Indemnity</strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            You indemnify Zagros Live team against any action, liability, claim,
            loss, damage, proceeding, expense (including legal costs) suffered
            or incurred by Zagros Live team, arising from, or which is directly
            or indirectly, related to your breach or non-observance of any term
            of these Terms.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
              font-size: 1.3rem;
            "
          >
            <strong>7 Suspension or termination of access</strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            Zagros.live website reserves the right to deny you access to, or use
            of, all or part of the Website, without prior notice, if you engage
            in any conduct that Zagros Live team believes, in its sole
            discretion violates any provision of these Terms of Use, violates
            the rights of Zagros Live Team or third parties or is otherwise
            inappropriate.
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
              font-size: 1.3rem;
            "
          >
            <strong>8 Links</strong>
          </p>
          <p
            style="
              margin-bottom: 0.11in;
              line-height: 168%;
              text-align: left;
              background: transparent;
            "
          >
            This Website contains links to third-party websites. The inclusion
            of a link does not imply that Zagros Live team has endorsed or
            verified the linked websites. Zagros Live team makes no warranty or
            representation concerning these links or the linked websites and you
            enter any third-party websites at your own risk. It is your
            responsibility to review any terms of use or privacy policies
            relevant to such websites.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            The Website may feature or display third-party advertising. The
            third-party advertisers are responsible for the accuracy of all
            representations made in those advertisements. Neither Zagros Live
            team, nor its employees or related bodies corporate recommend or
            endorse the goods or services that may be advertised on the Website,
            nor do they offer the goods or services for the sale of making any
            other representation whatsoever about them. If you choose to order a
            product or service advertised by a third party on the Website, you
            do so at your own risk.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
              font-size: 1.3rem;
            "
          >
            <strong>9 Marketing</strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            When signing up you also agree to receive email marketing from
            zagros.live website. You can opt-out of marketing at any time by
            unsubscribing from any marketing email.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
              font-size: 1.3rem;
            "
          >
            <strong>10 General</strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            Zagros Live team reserves the right to amend these Terms of Use at
            any time without notice to you. Your continued use of the Website
            after such change constitutes an agreement by you to abide by the
            Terms of Use, as amended.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            If any of these Terms of Use are invalid or unenforceable, they may
            be struck out and the remaining Terms of Use will continue to be of
            full force and effect.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            No waiver of any breach of these Terms will be construed as a waiver
            of any other breach of these Terms.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            These Terms were last updated on 02 February 2022.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            For further information on using this site, please contact:
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 168%;
            "
          >
            <u><a href="mailto:privacy@zagros.live">privacy@zagros.live</a></u>
          </p>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$vuetify.rtl ? "قوانین و مقررات" : "Terms of Use",
      meta: [
        {
          name: "twitter:image:src",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          name: "twitter:site",
          content: "Zagros Live",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:title",
          content: this.$vuetify.rtl ? "قوانین و مقررات" : "Terms of Use",
        },
        {
          name: "twitter:description",
          content: "Terms of Service",
        },
        {
          property: "og:image",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          property: "og:site_name",
          content: "Zagros Live",
        },
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: this.$vuetify.rtl ? "قوانین و مقررات" : "Terms of Use",
        },
        {
          property: "og:description",
          content: "Terms of Service",
        },
        {
          property: "og:url",
          content: this.base_url + this.$router.currentRoute.path,
        },
      ],
    };
  },
  name: "terms_of_service",
  data: () => ({
    title: {
      fa: "قوانین و مقررات",
      en: "Terms of Use",
    },
    base_url: "https://zagros.live",
  }),
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    isLangFa: function () {
      if (this.$router.currentRoute.name.split("-")[0] === "fa") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-light-color: #146a5f;
$primary-dark-color: #404548;

.about-text {
  line-height: 1.8;
  font-size: 1.1rem;
}

.about-title {
  font-size: 1.8rem;
}

.custom-card-border-light {
  border-color: $primary-light-color;
}

.custom-card-border-dark {
  border-color: $primary-dark-color;
}

p {
  font-size: 0.95rem;
}
</style>
