import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  theme: {
    themes: {
      light: {
        primary: "#146A5F",
        secondary: "#20af9c",
        sort_by_item: "#146A50",
        searchbar: "#146A5F",
      },
      dark: {
        primary: "#146A5F",
        secondary: "#20af9c",
        sort_by_item: "#20af9c",
        searchbar: "#20af9c",
      },
    },
  },
});
