<template>
  <v-container>
    <coin-filter-box :title="assetLocale" key="`assets-side-filter`" ref="assetFilter"></coin-filter-box>
    <filter-box :title="faSourceLocale" key="`fa-sources-side-filter`" ref="faSourceFilter" :items="faSources"
      :class="{ 'hidden-by-lang': !this.$vuetify.rtl }">
    </filter-box>
    <filter-box :title="enSourceLocale" key="`en-sources-side-filter`" ref="enSourceFilter" :items="enSources">
    </filter-box>
    <v-btn color="primary" block dark :class="{
        'no-letter-spacing': $vuetify.rtl,
      }" @click="submitFilter()">
      {{
        locale === "fa"
        ? localization.fa.actions.filter
        : localization.en.actions.filter
      }}
    </v-btn>
    <!-- new -->
    <!-- save filter btn -->
    <v-btn color="red darken-4" block dark class="my-4" :class="{
          'no-letter-spacing': $vuetify.rtl,
        }" @click="saveUserFavouriteFilterItems()" v-if="is_logged_in">
      {{
        locale === "fa"
        ? localization.fa.actions.save_changes
        : localization.en.actions.save_changes
      }}
    </v-btn>
    <template v-if="$vuetify.breakpoint.md">
      <v-divider class="my-2" />
      <categories v-on:apply-filter="$emit('apply-categories-filter')">
      </categories>
    </template>
    <!-- show categories only for mobile view -->
    <v-divider class="ma-4" v-if="$vuetify.breakpoint.smAndDown"></v-divider>
    <categories v-if="$vuetify.breakpoint.smAndDown" v-on:apply-filter="$emit('apply-categories-filter')">
    </categories>
    <!-- end of categories section -->
    <!-- show dialog if limit is excessed -->
    <v-dialog v-model="limit_dialog" width="440">
      <v-card v-if="$vuetify.rtl">
        <!-- <v-icon dark>mdi-alert-circle-outline</v-icon> -->
        <v-img :src="require('@/assets/mesc/limit-banner.png')"></v-img>
        <v-card-text class="mt-5">
          <span style="font-size: 1rem">
            انتخاب نامحدود تنها پس از
            <strong style="font-weight: bold">{{
              $store.state.user_info !== undefined ? "شارژ حساب" : "ثبت نام"
            }}</strong>
            مقدور است </span><br />
          <br />
          <strong style="font-size: 0.95rem; font-weight: bold">محدودیت انتخاب</strong>
          <v-list class="pa-0 fa-warning">
            <v-list-item style="padding: inherit">
              <!-- <v-list-item-icon style="margin-right: 16px"
                ><v-icon small> mdi-record </v-icon></v-list-item-icon
              > -->
              <v-list-item-avatar>
                <v-img :src="require('@/assets/mesc/bullet-dark.png')" v-if="$vuetify.theme.dark"></v-img>
                <v-img :src="require('@/assets/mesc/bullet-light.png')" v-else></v-img>
              </v-list-item-avatar>
              <v-list-item-title>1 ارز دیجیتال</v-list-item-title>
            </v-list-item>
            <v-list-item style="padding: inherit">
              <!-- <v-list-item-icon style="margin-right: 16px"
                ><v-icon small> mdi-record </v-icon></v-list-item-icon
              > -->
              <v-list-item-avatar>
                <v-img :src="require('@/assets/mesc/bullet-dark.png')" v-if="$vuetify.theme.dark"></v-img>
                <v-img :src="require('@/assets/mesc/bullet-light.png')" v-else></v-img>
              </v-list-item-avatar>
              <v-list-item-title> 1 منبع خبری فارسی </v-list-item-title>
            </v-list-item>
            <v-list-item style="padding: inherit">
              <v-list-item-avatar>
                <v-img :src="require('@/assets/mesc/bullet-dark.png')" v-if="$vuetify.theme.dark"></v-img>
                <v-img :src="require('@/assets/mesc/bullet-light.png')" v-else></v-img>
              </v-list-item-avatar>
              <v-list-item-title> 1 منبع خبری انگلیسی</v-list-item-title>
            </v-list-item>
          </v-list>
          <br />
        </v-card-text>
        <v-card-actions>
          <v-btn dark :class="{
                'no-letter-spacing': $vuetify.rtl,
              }" @click.stop="limit_dialog = false" color="#D3092B">
            بی خیال
          </v-btn>
          <v-spacer />
          <v-btn :color="style.login.primary" :class="{
                'no-letter-spacing': $vuetify.rtl,
              }" dark @click="goToUserPage()">
            {{
              $store.state.user_info !== undefined ? "شارژ حساب" : "ثبت نام"
            }}</v-btn>
        </v-card-actions>
        <!--  -->
      </v-card>
      <v-card v-else>
        <v-img style="-webkit-transform: scaleX(-1); transform: scaleX(-1)"
          :src="require('@/assets/mesc/limit-banner.png')"></v-img>
        <v-card-text class="mt-5" style="font-size: 1rem">
          <span>
            Unlimited access after
            <strong>{{
              $store.state.user_info !== undefined
              ? "charging your account"
              : "sign up"
            }}</strong> </span><br />
          <br />
          <strong style="font-size: 0.95rem; font-weight: bold">Limit is</strong>
          <v-list class="pa-0">
            <v-list-item style="padding: inherit">
              <!-- <v-list-item-icon style="margin-right: 16px"
                ><v-icon small> mdi-record </v-icon></v-list-item-icon
              > -->
              <v-list-item-avatar>
                <v-img :src="require('@/assets/mesc/bullet-dark.png')" v-if="$vuetify.theme.dark"></v-img>
                <v-img :src="require('@/assets/mesc/bullet-light.png')" v-else></v-img>
              </v-list-item-avatar>
              <v-list-item-title> 1 Coins</v-list-item-title>
            </v-list-item>
            <v-list-item style="padding: inherit">
              <!-- <v-list-item-icon style="margin-right: 16px"
                ><v-icon small> mdi-record </v-icon></v-list-item-icon
              > -->
              <v-list-item-avatar>
                <v-img :src="require('@/assets/mesc/bullet-dark.png')" v-if="$vuetify.theme.dark"></v-img>
                <v-img :src="require('@/assets/mesc/bullet-light.png')" v-else></v-img>
              </v-list-item-avatar>
              <v-list-item-title> 1 Farsi Sources </v-list-item-title>
            </v-list-item>
            <v-list-item style="padding: inherit">
              <v-list-item-avatar>
                <v-img :src="require('@/assets/mesc/bullet-dark.png')" v-if="$vuetify.theme.dark"></v-img>
                <v-img :src="require('@/assets/mesc/bullet-light.png')" v-else></v-img>
              </v-list-item-avatar>
              <v-list-item-title> 1 English Sources </v-list-item-title>
            </v-list-item>
          </v-list>
          <br />
        </v-card-text>
        <v-card-actions>
          <v-btn dark @click.stop="limit_dialog = false" color="#D3092B">
            Skip
          </v-btn>
          <v-spacer />
          <v-btn :color="style.login.primary" dark @click="goToUserPage()">{{
            $store.state.user_info !== undefined ? "Charge Account" : "Sign up"
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end of dialog -->
    <!-- <v-btn
      color="purple"
      block
      dark
      class="mb-2"
      :class="{
        'no-letter-spacing': locale === 'fa',
        'mb-4': isLoggedIn,
        'mb-2': !isLoggedIn,
      }"
      v-if="isLoggedIn"
    >
      {{
        locale === "fa"
          ? localization.fa.side_filters.actions.save_changes
          : localization.en.side_filters.actions.save_changes
      }}
    </v-btn> -->
    <!-- ads -->
    <!-- <side-commercial
      v-if="!$vuetify.breakpoint.smAndDown"
      :locale="$vuetify.rtl ? 'fa' : 'en'"
      :page="1"
      :max_ads="3"
      :offset="2"
    ></side-commercial> -->
    <!-- message for showing that the filter is saved successfully -->
    <message-show ref="msg"></message-show>
    <!--  -->
  </v-container>
</template>

<script>
import styleMixin from "../mixins/styleMixin";
import apiMixin from "../mixins/apiMixin";
import utils from "../mixins/utils";
import newsSources from "../mixins/newsSources";
import FilterBox from "./FilterBox.vue";
// import SideCommercial from "../views/commercial/SideCommercial.vue";
import CoinFilterBox from "./CoinFilterBox.vue";
import MessageShow from "./MessageShow.vue";
import Categories from "./Categories.vue";

// defining constant
const USER_FAVORITE_FILTERS_KEY = "user_favorite_filters";
const USER_IS_LOGGED_IN_KEY = "user_is_logged_in";
const APPLY_FILTER_KEY = "apply-filter";
const IS_FILTER_INIT_KEY = "filter_init";
const LAST_REQUEST_DATE_KEY = "user_last_request_date";
const EN_SOURCE_ID_OFFSET = 2000;

export default {
  name: "SideFilterBox",
  props: ["locale"],
  mixins: [styleMixin, newsSources, apiMixin, utils],
  components: { FilterBox, CoinFilterBox, MessageShow, Categories },
  data: () => ({
    // dialog_status: false,
    // kerm_dialog: false,
    assets_filter: [],
    fa_sources_filter: [],
    en_sources_filter: [],
    limit_dialog: false,
    coin_limit: 1,
    source_limit: 1,
    is_logged_in: false,
    save_filter_success_msg: {
      fa: "فیلتر شما با موفقیت بروزرسانی شد",
      en: "Your filter updated successfully",
    },
    save_filter_error_msg: {
      fa: "بروزرسانی فیلتر شما با مشکل مواجه شد",
      en: "there was a problem updating your filter",
    },
    fetched_news_sources: null,
    // locale: "fa",
  }),
  methods: {
    getLastRequestDate: function () {
      return localStorage.getItem(LAST_REQUEST_DATE_KEY);
    },
    setLastRequestDate: function (val) {
      localStorage.setItem(LAST_REQUEST_DATE_KEY, val);
    },
    goToUserPage: function () {
      this.limit_dialog = false;
      if (
        this.$router.currentRoute.name != "signup" &&
        this.$router.currentRoute.name != "dashboard"
      ) {
        this.$router.push({ name: "signup" });
      }
    },
    getNewsSources: async function () {
      return await this.$axios.get(this.api.news_sources, { withCredentials: true })
    },
    initSubmitFilter: function () {
      const state = this.$store.state;
      if (
        state.assets.length > this.coin_limit ||
        state.fa_sources.length > this.source_limit ||
        state.en_sources.length > this.source_limit
      ) {
        // check if user is logged in or not
        // first check if user is logged in from local storage
        if (
          localStorage.getItem(USER_IS_LOGGED_IN_KEY) === "true"
          // &&
          // state.user_subs_days != 0
        ) {
          // check log in status from backend too
          this.$axios
            .get(this.user_api.is_signed_in, { withCredentials: true })
            .then((res) => {
              if (res.data.status === 1) {
                localStorage.setItem(USER_IS_LOGGED_IN_KEY, true);
              } else {
                this.limit_dialog = true;
                this.is_logged_in = false;
                localStorage.setItem(USER_IS_LOGGED_IN_KEY, false);
                localStorage.setItem("user_access_token", "");
              }
            })
            .catch(() => {
              this.is_logged_in = false;
              localStorage.setItem(USER_IS_LOGGED_IN_KEY, false);
              localStorage.setItem("user_access_token", "");
            })
            .finally(() => {
              if (localStorage.getItem(USER_IS_LOGGED_IN_KEY) === "true") {
                this.is_logged_in = true;
              }

              this.$emit(APPLY_FILTER_KEY, [
                state.assets,
                state.fa_sources,
                state.en_sources,
                parseInt(this.$route.query.page),
              ]);
            });
        } else {
          this.limit_dialog = true;
        }
      } else {
        this.$emit(APPLY_FILTER_KEY, [
          state.assets,
          state.fa_sources,
          state.en_sources,
          parseInt(this.$route.query.page),
        ]);
      }
    },
    submitFilter: function () {
      this.assets_filter = this.$refs.assetFilter.getSelectedList();
      this.fa_sources_filter = this.$refs.faSourceFilter.getSelectedList();
      this.en_sources_filter = this.$refs.enSourceFilter.getSelectedList();
      //
      if (
        this.assets_filter.length > this.coin_limit ||
        this.fa_sources_filter.length > this.source_limit ||
        this.en_sources_filter.length > this.source_limit
      ) {
        // check if user is logged in or not
        // first check if user is logged in from local storage
        if (
          localStorage.getItem(USER_IS_LOGGED_IN_KEY) === "true"
          // &&
          // this.$store.state.user_subs_days != 0
        ) {
          // check log in status from backend too
          this.$axios
            .get(this.user_api.is_signed_in, { withCredentials: true })
            .then((res) => {
              if (res.data.status === 1) {
                localStorage.setItem(USER_IS_LOGGED_IN_KEY, true);
                this.$emit(APPLY_FILTER_KEY, [
                  this.assets_filter,
                  this.fa_sources_filter,
                  this.en_sources_filter,
                  1,
                ]);
              } else {
                this.limit_dialog = true;
                this.is_logged_in = false;
                localStorage.setItem(USER_IS_LOGGED_IN_KEY, false);
                localStorage.setItem("user_access_token", "");
              }
            })
            .catch(() => {
              this.is_logged_in = false;
              localStorage.setItem(USER_IS_LOGGED_IN_KEY, false);
              localStorage.setItem("user_access_token", "");
            })
            .finally(() => {
              if (localStorage.getItem(USER_IS_LOGGED_IN_KEY) === "true") {
                this.is_logged_in = true;
              }
            });
        } else {
          this.limit_dialog = true;
        }
      }
      else {
        this.$emit(APPLY_FILTER_KEY, [
          this.assets_filter,
          this.fa_sources_filter,
          this.en_sources_filter,
          1,
        ]);
      }
    },
    areEqual: function (arr1, arr2) {
      let n = arr1.length;
      let m = arr2.length;

      // If lengths of array are not equal means
      // array are not equal
      if (n != m) return false;

      // Sort both arrays
      arr1.sort();
      arr2.sort();

      // Linearly compare elements
      for (let i = 0; i < n; i++) if (arr1[i] != arr2[i]) return false;

      // If all elements were same.
      return true;
    },
    saveUserFavouriteFilterItems() {
      const prev_filter_items = JSON.parse(
        localStorage.getItem(USER_FAVORITE_FILTERS_KEY)
      );
      const prev_assets = prev_filter_items.assets;
      const prev_fa_sources = prev_filter_items.sources.filter(
        (el) => el < EN_SOURCE_ID_OFFSET
      );
      const prev_en_sources = prev_filter_items.sources.filter(
        (el) => el >= EN_SOURCE_ID_OFFSET
      );
      this.assets_filter = this.$refs.assetFilter.getSelectedList();
      this.fa_sources_filter = this.$refs.faSourceFilter.getSelectedList();
      this.en_sources_filter = this.$refs.enSourceFilter.getSelectedList();
      // check if user is logged in or not
      // first check if user is logged in from local storage
      if (
        this.is_logged_in &&
        (!this.areEqual(this.assets_filter, prev_assets) ||
          !this.areEqual(this.fa_sources_filter, prev_fa_sources) ||
          !this.areEqual(this.en_sources_filter, prev_en_sources))
      ) {
        let data = {
          assets: this.assets_filter,
          sources: this.fa_sources_filter.concat(this.en_sources_filter),
        };
        this.$axios
          .put(this.user_api.user_favorite_filters, data, {
            withCredentials: true,
          })
          .then((res) => {
            if (res.data.status === 1) {
              this.$refs.msg.showMessage(
                this.$vuetify.rtl
                  ? this.save_filter_success_msg.fa
                  : this.save_filter_success_msg.en,
                "success"
              );
              // save them to local storage
              this.saveFiltersToLocalStorage();
            } else {
              this.$refs.msg.showMessage(
                this.$vuetify.rtl
                  ? this.save_filter_error_msg.fa
                  : this.save_filter_error_msg.en,
                "error"
              );
            }
          })
          .catch(() => {
            this.$refs.msg.showMessage(
              this.$vuetify.rtl
                ? this.save_filter_error_msg.fa
                : this.save_filter_error_msg.en,
              "error"
            );
          })
          .finally(() => {
            this.submitFilterAfterSavingChanges();
          });
      } else {
        this.submitFilter();
      }
    },
    submitFilterAfterSavingChanges() {
      this.$emit(APPLY_FILTER_KEY, [
        this.assets_filter,
        this.fa_sources_filter,
        this.en_sources_filter,
        1,
      ]);
    },
    saveToStateManager(assets, fa_sources, en_sources) {
      this.$store.commit("changeAssets", assets);
      this.$store.commit("changeFaSources", fa_sources);
      this.$store.commit("changeEnSources", en_sources);
    },
    saveFiltersToLocalStorage() {
      let filters = {
        assets: this.assets_filter,
        sources: this.fa_sources_filter.concat(this.en_sources_filter),
      };
      localStorage.setItem(USER_FAVORITE_FILTERS_KEY, JSON.stringify(filters));
      // also save them to vuex
      this.saveToStateManager(
        this.assets_filter,
        this.fa_sources_filter,
        this.en_sources_filter
      );
    },
    // new
    generateFilters() {
      // first try if
      // it exists in localStorage
      try {
        let filter_vals = JSON.parse(
          localStorage.getItem(USER_FAVORITE_FILTERS_KEY)
        );
        //
        this.$refs.assetFilter.preprocessSelectedList(filter_vals.assets);
        this.$refs.faSourceFilter.preprocessSelectedList(
          filter_vals.sources.filter((el) => el < EN_SOURCE_ID_OFFSET)
        );
        this.$refs.enSourceFilter.preprocessSelectedList(
          filter_vals.sources.filter((el) => el >= EN_SOURCE_ID_OFFSET)
        );
      } catch {
        // save filter vals
        const filter_vals = {
          assets: [],
          sources: [],
        };
        // set item
        localStorage.setItem(
          USER_FAVORITE_FILTERS_KEY,
          JSON.stringify(filter_vals)
        );
        this.saveToStateManager([], [], []);
      }
    },
    generateLimitedFilters() {
      try {
        let filter_vals = JSON.parse(
          localStorage.getItem(USER_FAVORITE_FILTERS_KEY)
        );
        //
        const limited_assets = filter_vals.assets.slice(0, 1);
        this.$refs.assetFilter.preprocessSelectedList(limited_assets);
        const limited_fasources = filter_vals.sources
          .filter((el) => el < EN_SOURCE_ID_OFFSET)
          .slice(0, 1);
        this.$refs.faSourceFilter.preprocessSelectedList(limited_fasources);
        const limited_ensources = filter_vals.sources
          .filter((el) => el >= EN_SOURCE_ID_OFFSET)
          .slice(0, 1);
        this.$refs.enSourceFilter.preprocessSelectedList(limited_ensources);
      } catch {
        // save filter vals
        const filter_vals = {
          assets: [],
          sources: [],
        };
        // set item
        localStorage.setItem(
          USER_FAVORITE_FILTERS_KEY,
          JSON.stringify(filter_vals)
        );
      }
    },
  },
  computed: {
    assetLocale() {
      if (this.locale === "fa") {
        return "ارزهای دیجیتال";
      } else {
        return "Coins";
      }
    },
    faSourceLocale() {
      if (this.locale === "fa") {
        return this.localization.fa.fa_sources.title;
      } else {
        return this.localization.en.fa_sources.title;
      }
    },
    faSources() {
      if (this.fetched_news_sources !== null) {
        return this.sources.fa.items.filter((el) => el.id < EN_SOURCE_ID_OFFSET && this.fetched_news_sources.includes(el.id))
      } else {
        return this.sources.fa.items
      }
    },
    enSourceLocale() {
      if (this.locale === "fa") {
        return this.localization.fa.en_sources.title;
      } else {
        return this.localization.en.en_sources.title;
      }
    },
    enSources() {
      if (this.fetched_news_sources !== null) {
        return this.sources.en.items.filter((el) => el.id >= EN_SOURCE_ID_OFFSET && this.fetched_news_sources.includes(el.id))
      } else {
        return this.sources.en.items
      }
    },
    isLoggedIn() {
      return this.is_logged_in;
    },
  },
  mounted() {
    // fetch source news
    const resp = this.getNewsSources()
    resp.then((res) => {
      this.fetched_news_sources = res.data.data.items
    })

    // 
    if (
      localStorage.getItem(IS_FILTER_INIT_KEY) !== "true" ||
      (this.$route.query.news_slug !== undefined &&
        this.$route.query.news_slug !== null)
    ) {
      // if (this.getUserLogInState()) {
      this.$axios
        .get(this.user_api.user_favorite_filters, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.status === 1) {
            // save filter vals
            const filter_vals = res.data.data;
            // set item
            localStorage.setItem(
              USER_FAVORITE_FILTERS_KEY,
              JSON.stringify(filter_vals)
            );
            // preprocess sources
            const fa_srcs = filter_vals.sources.filter((el) => el < EN_SOURCE_ID_OFFSET);
            const en_srcs = filter_vals.sources.filter((el) => el >= EN_SOURCE_ID_OFFSET);
            // apply preprocessing
            // and save them to
            this.$refs.assetFilter.preprocessSelectedList(filter_vals.assets);
            this.$refs.faSourceFilter.preprocessSelectedList(fa_srcs);
            this.$refs.enSourceFilter.preprocessSelectedList(en_srcs);
            // save them to vuex
            this.saveToStateManager(filter_vals.assets, fa_srcs, en_srcs);
          } else {
            this.is_logged_in = false;
            localStorage.setItem(USER_IS_LOGGED_IN_KEY, false);
            localStorage.setItem("user_access_token", "");
            // save filter vals
            const filter_vals = {
              assets: [],
              sources: [],
            };
            // set item
            localStorage.setItem(
              USER_FAVORITE_FILTERS_KEY,
              JSON.stringify(filter_vals)
            );
            //
            this.saveToStateManager([], [], []);
          }
        })
        .catch(() => {
          this.is_logged_in = false;
          localStorage.setItem(USER_IS_LOGGED_IN_KEY, false);
          localStorage.setItem("user_access_token", "");
          // save filter vals
          const filter_vals = {
            assets: [],
            sources: [],
          };
          // set item
          localStorage.setItem(
            USER_FAVORITE_FILTERS_KEY,
            JSON.stringify(filter_vals)
          );
          this.saveToStateManager([], [], []);
        })
        .finally(() => {
          localStorage.setItem(IS_FILTER_INIT_KEY, true);
          if (localStorage.getItem(USER_IS_LOGGED_IN_KEY) === "true") {
            this.is_logged_in = true;
          }
          this.initSubmitFilter();
        });
      // set if the filter is submitted
      localStorage.setItem(IS_FILTER_INIT_KEY, true);
      // }

    }
    // check if user is
    // finally logged in or not
    if (localStorage.getItem(USER_IS_LOGGED_IN_KEY) === "true") {
      this.is_logged_in = true;
      //
      this.$refs.assetFilter.preprocessSelectedList(this.$store.state.assets);
      this.$refs.faSourceFilter.preprocessSelectedList(
        this.$store.state.fa_sources
      );
      this.$refs.enSourceFilter.preprocessSelectedList(
        this.$store.state.en_sources
      );
    } else {
      this.is_logged_in = false;
    }
  },
};
</script>

<style scoped>
.no-letter-spacing {
  letter-spacing: 0;
}

.fa-warning {
  font-family: IRanSans;
}

.hidden-by-lang {
  display: none;
}
</style>
