var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"app"}},[_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":"","dir":"rtl"}},[_c('div',{staticClass:"d-flex align-center"},[(
          _vm.$router.currentRoute.name !== 'dashboard'
        )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.goToUserPage.apply(null, arguments)}}},[_c('v-icon',[_vm._v(" mdi-account-circle ")])],1):(
          _vm.$router.currentRoute.name === 'dashboard'
        )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.goToUserPage.apply(null, arguments)}}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-icon',[_vm._v(" mdi-logout-variant ")]):_c('v-icon',[_vm._v(" mdi-account-circle ")])],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown && _vm.$route.name.includes('home'))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mobile_drawer = !_vm.mobile_drawer}}},[_c('v-icon',[_vm._v(" mdi-filter ")])],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.mobile_drawer),callback:function ($$v) {_vm.mobile_drawer=$$v},expression:"mobile_drawer"}},[_c('v-card',{attrs:{"flat":"","dir":_vm.locale === 'fa' ? 'rtl' : 'ltr'}},[_c('v-sheet',[(_vm.locale === 'fa')?_c('v-card-title',[_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mobile_drawer = !_vm.mobile_drawer}}},[_c('v-icon',[_vm._v(" mdi-arrow-right ")])],1),_c('span',{staticClass:"filters-header-fa",domProps:{"textContent":_vm._s('فیلترها')}})],1):_c('v-card-title',[_c('v-btn',{staticClass:"mr-4",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mobile_drawer = !_vm.mobile_drawer}}},[_c('v-icon',[_vm._v(" mdi-arrow-left ")])],1),_c('span',{staticClass:"filters-header-en",domProps:{"textContent":_vm._s('Filters')}})],1)],1),_c('side-filter-box',{attrs:{"locale":_vm.locale},on:{"apply-filter":_vm.handleFilter,"apply-categories-filter":_vm.handleCategoriesFilter}})],1)],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.changeThemeBrightness.apply(null, arguments)}}},[_c('v-icon',[_vm._v(" mdi-brightness-4 ")])],1),[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"bottom":"","offset-y":"","auto":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","dark":""}},on),[_c('v-icon',[_vm._v("mdi-web")])],1)]}}])},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary","mandatory":""},model:{value:(_vm.selectedLang),callback:function ($$v) {_vm.selectedLang=$$v},expression:"selectedLang"}},_vm._l((_vm.locales),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){$event.preventDefault();return _vm.changeLocale(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"dir":item.is_rtl ? 'rtl' : 'ltr'},domProps:{"textContent":_vm._s(item.title)}})],1)],1)}),1)],1)],1)],1)]],2),_c('v-spacer'),_c('a',{attrs:{"href":"https://zagros.live/"}},[_c('div',{staticClass:"d-flex align-center clickable"},[_c('v-img',{staticClass:"shrink",attrs:{"alt":"Logo","contain":"","src":"https://static.zagros.live/images/logos/zagros_logo.svg","transition":"scale-transition","width":"50","height":"50"}})],1)])],1),_c('v-main',{style:(("background-color: " + (_vm.$vuetify.theme.dark ? _vm.style.main.bg_color.dark : _vm.style.main.bg_color.light)))},[_c('router-view',{ref:"home"})],1),_c('v-footer',{staticClass:"mt-4 mt-lg-2",attrs:{"dark":"","padless":""}},[_c('v-card',{staticClass:"flex white--text",attrs:{"flat":"","tile":"","color":"primary"}},[_c('v-card-text',{staticClass:"white--text py-0 px-6 px-xl-8"},[_c('v-row',{attrs:{"justify-md":"space-between","justify-sm":"space-between","justify":"center","align":"center","no-gutters":""}},[_c('div',{staticClass:"ms-md-n5 ms-xl-n3"},_vm._l((_vm.links),function(link){return _c('v-btn',{key:_vm.$vuetify.rtl ? link.fa : link.en,staticClass:"mx-1 py-0 px-2 mx-sm-0",class:{
                  'no-letter-spacing': _vm.$vuetify.rtl,
                },style:(("font-size: " + (_vm.$vuetify.rtl ? '0.9' : '0.67') + "rem")),attrs:{"color":"white","text":"","href":_vm.$vuetify.rtl
    ? (_vm.base_url + "/" + (link.route.fa))
    : (_vm.base_url + "/" + (link.route.en))}},[_vm._v(" "+_vm._s(_vm.$vuetify.rtl ? link.fa : link.en)+" ")])}),1),_c('div',{staticClass:"py-2 mb-2 py-md-0 mb-sm-0",domProps:{"innerHTML":_vm._s(
              "<span style='color: white;'>Powered by </span><strong style='color: white;'>Zagros Live\n                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          "
            )}})])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }