<template>
  <v-card class="mx-auto" outlined v-if="$vuetify.breakpoint.width >= 1265">
    <v-tabs fixed-tabs icons-and-text dark background-color="primary" v-model="tabs_model">
      <v-tabs-slider></v-tabs-slider>
      <v-tab :class="{ 'no-letter-spacing': $vuetify.rtl }">
        {{ $vuetify.rtl ? title.categories.fa : title.categories.en }}
        <v-icon> mdi-shape</v-icon>
      </v-tab>
      <v-tab :class="{ 'no-letter-spacing': $vuetify.rtl }">
        {{ $vuetify.rtl ? title.people.fa : title.people.en }}
        <v-icon> mdi-account-star</v-icon>
      </v-tab>
    </v-tabs>
    <v-card-text v-if="tabs_model === 0" class="py-0 px-2">
      <v-list two-line flat class="px-0">
        <template v-for="category in categories">
          <v-list-item :key="category.id" @click.prevent="goToTag(category.id, category.route_ref)" class="px-1">
            <v-list-item-avatar tile size="34">
              <v-img :src="category.img" :class="{ 'invert-avatar': $vuetify.theme.dark }"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="$vuetify.rtl ? category.fa : category.en"
              :class="{ 'selected-category-light': $route.params.category === category.route_ref && !$vuetify.theme.dark, 'selected-category-dark': $route.params.category === category.route_ref && $vuetify.theme.dark }"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
    <v-card-text v-else class="py-0 px-2">
      <v-list two-line flat>
        <template v-for="person in people">
          <v-list-item :key="person.id" @click.prevent="goToTag(person.id, person.route_ref)" class="px-1">
            <v-list-item-avatar tile>
              <v-img :src="person.img"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="$vuetify.rtl ? person.fa : person.en"
                :class="{ 'selected-category-light': $route.params.category === person.route_ref && !$vuetify.theme.dark, 'selected-category-dark': $route.params.category === person.route_ref && $vuetify.theme.dark }"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
  <!-- mobile view -->
  <div v-else>
    <v-card class="mx-auto mb-4 item-pointer" outlined>
      <v-card-actions class="dense-card-actions" @click.stop="show = !show">
        <span v-text="$vuetify.rtl ? 'برچسب' : 'Tag'" class="ms-2"></span>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show">
          <v-virtual-scroll :items="people.concat(categories)" :item-height="45" height="300">
            <template v-slot:default="{ item }">
              <v-list-item :key="item.id" @click.prevent="goToTag(item.id, item.route_ref)">
                <v-list-item-avatar tile size="30">
                  <v-img :src="item.img" :class="{
                      'invert-avatar': $vuetify.theme.dark && item.id < 9000,
                    }"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="$vuetify.rtl ? item.fa : item.en"
                  :class="{ 'selected-category-light': $route.params.category === item.route_ref && !$vuetify.theme.dark, 'selected-category-dark': $route.params.category === item.route_ref && $vuetify.theme.dark }"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import categories from "@/mixins/categories.js";

const BASE_URL = process.env.VUE_APP_BASE_URL;
const APPLY_FILTER_KEY = 'apply-filter'

export default {
  name: "categories_component",
  mixins: [categories],
  data: () => ({
    base_url: BASE_URL,
    tabs_model: 0,
    category_search: "",
    people_search: "",
    show: false,
  }),
  mounted() {
    if (this.$route.path.includes("influencer")) {
      this.tabs_model = 1;
    } else {
      this.tabs_model = 0;
    }

  },
  methods: {
    goToTag: function (item_id, item_route) {
      if (this.selectedCategory !== item_route) {
        if (item_id < 9000) this.$router.push({ name: "searched-category", params: { category: item_route } })
        else this.$router.push({ name: "searched-influencer", params: { people: item_route } })
        this.$emit(APPLY_FILTER_KEY)
      }

    },
    getCategory: function () {
      return this.$route.params.category;
    }
  },
  computed: {
    filteredCategories() {
      return this.categories.filter((item) => {
        return item[this.$vuetify.rtl ? 'fa' : 'en']
          .toLowerCase()
          .includes(this.category_search.toLowerCase());
      });
    },
    filteredPeople() {
      return this.people.filter((item) => {
        return item[this.$vuetify.rtl ? 'fa' : 'en']
          .toLowerCase()
          .includes(this.people_search.toLowerCase());
      });
    },
    selectedCategory() {
      return this.getCategory();
    }
  },
};
</script>

<style scoped lang="scss">
$scrollbar-color: #20af9c;
$active-category-light: #146A5F;
$active-category-dark: #20af9c;

.no-letter-spacing {
  letter-spacing: 0;
}

.invert-avatar {
  filter: invert(100%);
}

.dense-card-actions {
  padding: 6px;
}

.item-pointer {
  cursor: pointer;
}

.selected-category-light {
  color: $active-category-light;
  font-weight: 530;
}

.selected-category-dark {
  color: $active-category-dark;
  font-weight: 530;
}

* {
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-color white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;
  border-radius: 25px;
}
</style>
