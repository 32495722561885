<template>
  <v-container class="fill-height" v-if="!switch_pages">
    <v-layout row align-center justify-space-around>
      <v-flex xs12 sm12 md6 lg6 xl4>
        <!-- Login form here -->
        <v-card flat tile :color="$vuetify.theme.dark ? style.main.bg_color.dark : style.main.bg_color.light">
          <h1 :style="`color: ${$vuetify.theme.dark
            ? style.signup.primary_dark
  : style.signup.primary
  };
            font-size: 2.3rem;
                      font-weight: 700;
                      text-align: center;`" class="my-6">
            {{
              $vuetify.rtl
              ? localization.fa.signup.title
              : localization.en.signup.title
            }}
          </h1>
          <v-form class="mx-3" ref="form" lazy-validation>
            <v-text-field type="text" v-model="fa_first_name" :label="localization.fa.signup.first_name" outlined required
              :rules="requiredRules" :color="style.login.secondary" :loading="is_loading" v-if="$vuetify.rtl" />
            <v-text-field type="text" v-model="en_first_name" :label="localization.en.signup.first_name" outlined required
              :rules="requiredRules" :color="style.login.secondary" :loading="is_loading" class="text-capitalize"
              v-else />
            <v-text-field type="text" v-model="fa_last_name" :label="localization.fa.signup.last_name" outlined required
              :rules="requiredRules" :color="style.login.secondary" :loading="is_loading" v-if="$vuetify.rtl" />
            <v-text-field type="text" v-model="en_last_name" :label="localization.en.signup.last_name" outlined required
              :rules="requiredRules" :color="style.login.secondary" :loading="is_loading" class="text-capitalize"
              v-else />
            <v-text-field type="text" v-model="fa_email" :label="localization.fa.signup.email" outlined required
              :rules="emailRules" :color="style.login.secondary" prepend-inner-icon="mdi-email" :loading="is_loading"
              v-if="$vuetify.rtl" />
            <v-text-field type="text" v-model="en_email" :label="localization.en.signup.email" outlined required
              :rules="emailRules" :color="style.login.secondary" prepend-inner-icon="mdi-email" :loading="is_loading"
              class="text-capitalize" v-else />
            <!-- username section -->
            <v-text-field type="text" v-model="fa_username" :label="localization.fa.signup.username" outlined required
              :rules="usernameRules" :color="style.login.secondary" prepend-inner-icon="mdi-account" :loading="is_loading"
              class="text-capitalize" v-if="$vuetify.rtl" />
            <v-text-field type="text" v-model="en_username" :label="localization.en.signup.username" outlined required
              :rules="usernameRules" :color="style.login.secondary" prepend-inner-icon="mdi-account" :loading="is_loading"
              class="text-capitalize" v-else />
            <v-text-field v-model="fa_password" :label="localization.fa.signup.password" outlined required
              :rules="passwordRules" :color="style.login.secondary" prepend-inner-icon="mdi-key"
              :append-icon="hide_password ? 'mdi-eye' : 'mdi-eye-off'" :type="hide_password ? 'password' : 'text'"
              @click:append="hide_password = !hide_password" :loading="is_loading" v-if="$vuetify.rtl" />
            <v-text-field :type="hide_password ? 'password' : 'text'" class="text-capitalize" v-model="en_password"
              :label="localization.en.signup.password" outlined required :rules="passwordRules"
              :color="style.login.secondary" prepend-inner-icon="mdi-key"
              :append-icon="hide_password ? 'mdi-eye' : 'mdi-eye-off'" :loading="is_loading"
              @click:append="hide_password = !hide_password" v-else />
            <!-- sexuality section -->
            <v-select v-model="sex" :items="sex_options" :item-text="$vuetify.rtl ? 'fa' : 'en'" item-value="abbr"
              :label="$vuetify.rtl ? 'جنسیت' : 'Sex'" outlined :loading="is_loading"></v-select>
            <v-btn block depressed dark :color="style.login.primary" :loading="is_loading" v-on:click="signUpUser"
              :class="{ 'no-letter-spacing': $vuetify.rtl }">{{
                $vuetify.rtl
                ? localization.fa.signup.title
                : localization.en.signup.title
              }}</v-btn>
          </v-form>
          <br />
          <br />
        </v-card>
      </v-flex>
    </v-layout>
    <message-show ref="MsgShow"></message-show>
  </v-container>
  <!-- if the confirmation email is send -->
  <sign-up-confirmation-page :user="{
    username: fa_username.length ? fa_username : en_username,
    email: fa_email.length ? fa_email : en_email,
  }" v-else></sign-up-confirmation-page>
</template>

<script>
import APIMixin from "../../../mixins/apiMixin";
import AuthMixin from "../../../mixins/authMixin";
import styleMixin from "../../../mixins/styleMixin";
import utils from "../../../mixins/utils";
// import MessageShow from "../../../components/MessageShow";
import localizationMixin from "../../../mixins/localeMixin";
import SignUpConfirmationPage from "./SignUpConfirmationPage.vue";
import MessageShow from "../../../components/MessageShow.vue";

//
export default {
  metaInfo() {
    return {
      title: this.$vuetify.rtl ? "ثبت نام" : "Sign up",
      meta: [
        {
          name: "twitter:image:src",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          name: "twitter:site",
          content: "Zagros Live",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:title",
          content: this.$vuetify.rtl ? "ثبت نام" : "Sign up",
        },
        {
          name: "twitter:description",
          content: "Sign Up",
        },
        {
          property: "og:image",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          property: "og:site_name",
          content: "Zagros Live",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: this.$vuetify.rtl ? "ثبت نام" : "Sign up",
        },
        {
          property: "og:description",
          content: "Sign up page",
        },
        {
          property: "og:url",
          content: "https://zagros.live" + this.$router.currentRoute.path,
        },
      ],
    };
  },
  components: { SignUpConfirmationPage, MessageShow },
  mixins: [APIMixin, AuthMixin, localizationMixin, styleMixin, utils],
  data: () => ({
    rules: {
      required: (value) => !!value || true,
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "";
      },
      username: (value) => {
        const pattern = /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
        return pattern.test(value) || "";
      },
      phone: (value) => {
        const pattern = /^([09]){2}([0-9]){9}$/;
        return (
          pattern.test(value) ||
          "شماره همراه تنها باید شامل اعداد 0 تا 9 و 11 رقم باشد"
        );
      },
    },
    sex_options: [
      { fa: "نامشخص", en: "Unknown", abbr: "U" },
      { fa: "مرد", en: "Male", abbr: "M" },
      { fa: "زن", en: "Female", abbr: "F" },
    ],
    fa_first_name: "",
    fa_last_name: "",
    fa_username: "",
    fa_email: "",
    fa_password: "",
    en_username: "",
    en_email: "",
    en_password: "",
    en_first_name: "",
    en_last_name: "",
    sex: "U",
    is_loading: false,
    switch_pages: false,
    hide_password: true,
  }),
  methods: {
    goToDashboardPage: function () {
      this.$router.push({ name: "dashboard" });
    },
    goToSignUpConfirmationPage: function () {
      this.$router.push({ name: "signup_confirmation" });
    },
    signUpUser: function () {
      if (this.$refs.form.validate()) {
        this.is_loading = true;
        const data = {
          first_name: this.$vuetify.rtl
            ? this.fa_first_name
            : this.en_first_name,
          last_name: this.$vuetify.rtl ? this.fa_last_name : this.en_last_name,
          username: this.$vuetify.rtl ? this.fa_username : this.en_username,
          email: this.$vuetify.rtl ? this.fa_email : this.en_email,
          password: this.$vuetify.rtl ? this.fa_password : this.en_password,
          lang: this.$vuetify.rtl ? "fa" : "en",
          sex: this.sex,
        };
        this.$axios
          .post(this.user_api.signup, data, { withCredentials: true })
          .then((res) => {
            if (res.data.status) {
              this.switch_pages = true;
            } else {
              this.$refs.MsgShow.showMessage(
                this.$vuetify.rtl
                  ? "قبلا یک حساب کاربری با این ایمیل یا نام کاربری ساخته شده است "
                  : "we already found an account with the provided credentials",
                "error"
              );
            }
          })
          .catch(() => {
            this.is_loading = false;
            this.$refs.MsgShow.showMessage(
              this.$vuetify.rtl
                ? "متاسافانه ارتباط با سرور برقرار نشد "
                : "there was an error while connecting to the server",
              "error"
            );
          })
          .finally(() => {
            this.is_loading = false;
          });
      }
    },
    clear: function () {
      this.username = "";
      this.email = "";
      this.password = "";
    },
    emailErrors: function () {
      return this.$vuetify.rtl
        ? "ایمیل به درستی وارد نشده است"
        : "email format is not correct";
    },
    usernameErrors: function () {
      return this.$vuetify.rtl
        ? "نام کاربری باید شامل حداقل ۸ کاراکتر و شامل ?، !، @ و - نباشد "
        : "username should at least contain 8 characters except ? ! - @ ";
    },
    requiredErrors: function () {
      return this.$vuetify.rtl
        ? "این فیلد اجباری است"
        : "this field is required";
    },
    passwordErrors: function () {
      return this.$vuetify.rtl
        ? "رمزعبور باید بیشتر از ۸ کاراکتر و شامل حروف کوچک و بزرگ، اعداد و کاراکترهای خاص باشد "
        : "password should contain small and capital letters, numbers and special characters";
    },
  },
  computed: {
    requiredRules() {
      const rule = (value) => !!value || this.requiredErrors();
      return [rule];
    },
    emailRules() {
      const rules = [];
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const rule = (value) => pattern.test(value) || this.emailErrors();
      const reqRule = (value) => !!value || this.requiredErrors();
      //
      rules.push(rule);
      rules.push(reqRule);
      //
      return rules;
    },
    usernameRules() {
      const rules = [];
      const pattern = /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
      const rule = (value) => pattern.test(value) || this.usernameErrors();
      const reqRule = (value) => !!value || this.requiredErrors();
      rules.push(rule);
      rules.push(reqRule);
      //
      return rules;
    },
    passwordRules() {
      const rules = [];
      const pattern =
        /^(?=.*([A-Z]){1,})(?=.*[!@#$&*]{1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,100}$/;
      const rule = (value) => pattern.test(value) || this.passwordErrors();
      const reqRule = (value) => !!value || this.requiredErrors();
      rules.push(rule);
      rules.push(reqRule);
      //
      return rules;
    },
    // checkboxErrors () {
    //   const errors = []
    //   if (!this.$v.checkbox.$dirty) return errors
    //   !this.$v.checkbox.checked && errors.push('You must agree to continue!')
    //   return errors
    // },
    // selectErrors () {
    //   const errors = []
    //   if (!this.$v.select.$dirty) return errors
    //   !this.$v.select.required && errors.push('Item is required')
    //   return errors
    // },
  },
  created() {
    if (this.getLocale() == "en") {
      this.$vuetify.rtl = false;
    } else {
      this.$vuetify.rtl = true;
    }
    if (this.isAuthenticated()) {
      this.goToDashboardPage();
    }
  },
};
</script>

<style scoped>
.no-letter-spacing {
  letter-spacing: 0;
}
</style>
