/* eslint-disable no-console */

import Vue from "vue";
import APIMixin from "../mixins/apiMixin";
const USER_ACCESS_TOKEN_Key = "user_access_token";
const IS_LOGGED_IN_KEY = "user_is_logged_in";
const USER_CSRF_TOKEN_Key = "csrftoken";
const USER_SESSION_ID_Key = "sessionid";
const LAST_REQUEST_DATE_Key = "user_last_request_date";

export default {
  methods: {
    getUserAuthenticationToken: function () {
      return localStorage.getItem(USER_ACCESS_TOKEN_Key);
    },
    setUserAuthenticationToken: function (auth_token) {
      localStorage.setItem(USER_ACCESS_TOKEN_Key, auth_token);
    },
    getLastRequestDate: function () {
      return localStorage.getItem(LAST_REQUEST_DATE_Key);
    },
    setLastRequestDate: function (val) {
      localStorage.setItem(LAST_REQUEST_DATE_Key, val);
    },
    getSessionID: function () {
      return Vue.$cookies.get(USER_SESSION_ID_Key);
      // return localStorage.getItem(USER_SESSION_ID_Key);
    },
    setSessionID: function (session_id) {
      Vue.$cookies.set(USER_SESSION_ID_Key, session_id);
      // localStorage.setItem(USER_SESSION_ID_Key, session_id);
    },
    getCSRFToken: function () {
      return Vue.$cookies.get(USER_CSRF_TOKEN_Key);
      // return localStorage.getItem(USER_CSRF_TOKEN_Key);
    },
    setCSRFToken: function (csrftoken) {
      Vue.$cookies.set(USER_CSRF_TOKEN_Key, csrftoken);
      // localStorage.setItem(USER_CSRF_TOKEN_Key, csrftoken);
    },
    getUserIsLoggedIn: function () {
      if (localStorage.getItem(IS_LOGGED_IN_KEY) == "true") {
        return true;
      } else {
        return false;
      }
    },
    setUserIsLoggedIn: function (login_status) {
      localStorage.setItem(IS_LOGGED_IN_KEY, login_status);
    },
    isAuthenticated: function () {
      return this.getUserIsLoggedIn();
    },
    hasSession: function () {
      if (this.getSessionID()) {
        return true;
      } else {
        return false;
      }
    },
    hasLastRequestDate: function () {
      if (this.getLastRequestDate()) {
        return true;
      } else {
        return false;
      }
    },
    checkLogigStatus: function () {
      this.$axios
        .post(APIMixin.data().api.login_status)
        .then((response) => {
          return response.data.data.login == "YES";
        })
        .catch(() => {
          this.logout();
          return false;
        });
    },
    checkAuthenticationStatus: function () {
      // console.log(this.isAuthenticated())
      if (this.isAuthenticated()) {
        this.$router.push({
          name: "dashboard"
        });
      }
    },
    checkAuthenticationStatusForDashboard: function () {
      // console.log(this.isAuthenticated())
      if (!this.isAuthenticated()) {
        this.$router.push({
          name: "login"
        });
      }
    },
    generateSessionFromServer: function () {
      // fetch(this.user_api.session);
      // set csrf token
      // if (!this.getCSRFToken()) {
      //   axios.get(this.user_api.csrf, {
      //     withCredentials: true
      //   }).then((res) => {
      //     console.log(res);
      //   });
      // }
      // set authentication session
      if (!this.getSessionID()) {
        this.$axios.get(this.user_api.session, {
          withCredentials: true
        }).then((res) => {
          this.setSessionID(res.data.session);
        });
      }

    },
    clearAuthCredit: function () {
      this.setUserAuthenticationToken("");
      this.setUserIsLoggedIn(false);
    },
    logout: function () {
      // if they exist
      const locale = localStorage.getItem("service_lang");
      var final_route = locale !== undefined ? `${locale}-home` : '';
      // else
      this.$axios.get(this.user_api.logout, {
        withCredentials: true
      }).then((res) => {
        if (res.data.status) {
          this.setUserIsLoggedIn(false);
          this.$store.commit("changeUserInfo", undefined);
          if (final_route.length && !this.$route.name.includes(final_route)) {
            this.$router.push({ name: final_route })
          } else {
            this.$router.go();
          }

        }
      }).catch((e) => {
        if (e.response.status == 401 || e.response.status == 500) {
          this.setUserAuthenticationToken("");
          this.setUserIsLoggedIn(false);
          this.$store.commit("changeUserInfo", undefined);
          if (final_route.length && !this.$route.name.includes(final_route)) {
            this.$router.push({ name: final_route })
          } else {
            this.$router.go();
          }
        }
      });
    },
  },
};
