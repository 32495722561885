<template>
  <v-container class="fill-height" v-if="user != null" :fluid="$vuetify.breakpoint.md ? true : false"
    :class="{ 'fa-user-card': $vuetify.rtl }">
    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md3 lg3 xl2 :align-self-center="$vuetify.breakpoint.mdAndUp"
        :align-self-start="!$vuetify.breakpoint.mdAndUp" grow>
        <v-card :height="$vuetify.breakpoint.mdAndUp ? '500' : '100%'" :flat="!$vuetify.breakpoint.mdAndUp">
          <v-layout column justify-center>
            <v-flex align-self-center>
              <v-hover v-slot="{ hover }">
                <v-avatar size="120" class="my-4" @click.prevent="
                  hover && nav_model === 0
                    ? (crop_dialog = true)
                    : (crop_dialog = false)
                ">
                  <v-img contain v-if="user_changes.selectedProfilePic.image != null"
                    :src="user_changes.selectedProfilePic.url">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate :color="style.primary"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-img contain v-else-if="user.profile_image != null" :src="user.profile_image">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate :color="style.primary"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <!-- <v-img v-else :src="require('@/assets/default_user.png')"> -->
                  <v-img v-else src="@/assets/zagrosdefaultprofile.svg">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate :color="style.primary"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-fade-transition>
                    <v-overlay v-if="hover && nav_model === 0" absolute color="grey lighten-2">
                      <v-spacer> </v-spacer>
                      <v-btn @click.stop="crop_dialog = true" fab color="grey darken-2" small>
                        <v-icon> mdi-camera </v-icon>
                      </v-btn>
                      <v-spacer> </v-spacer>
                    </v-overlay>
                  </v-fade-transition>
                </v-avatar>
              </v-hover>
              <!--  -->
              <v-dialog v-model="crop_dialog" max-width="600px" scrollable persistent>
                <v-card outlined max-width="600">
                  <CustomCropper :user_profile_changed="user_changes.selectedProfilePic"
                    v-on:crop_dialog_status="crop_dialog = $event" @crop_and_upload="
                      crop_dialog = $event;
                    editUserInfo();
                                                                                      " />
                </v-card>
              </v-dialog>
              <!--  -->
            </v-flex>
            <!-- name -->
            <v-flex xs12 sm12 md12 align-self-center class="mb-2 mt-2" :style="`max-width: ${$vuetify.breakpoint.mdAndUp
              ? 210
              : $vuetify.breakpoint.width * 0.85
              }px`">
              <v-col cols="12" class="pa-0">
                <span>
                  {{ user.username }}
                </span>
              </v-col>
            </v-flex>

            <!-- list of setting options and users name -->
            <v-col cols="12" sm="12" md="12" align-self-center class="mt-5 mb-3">
              <v-list dense class="hidden-sm-and-down mx-6">
                <v-list-item-group v-model="nav_model" :color="$vuetify.theme.dark ? style.secondary : style.primary">
                  <v-list-item v-for="(item, index) in nav_items" :key="index">
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title v-text="$vuetify.rtl ? item.fa : item.en"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-tabs v-model="nav_model" class="hidden-md-and-up pa-0" fixed-tabs
                :dark="$vuetify.theme.dark ? true : false" :color="$vuetify.theme.dark ? style.secondary : style.primary">
                <v-tab v-for="(item, index) in nav_items" :key="index" :class="{ 'no-letter-spacing': $vuetify.rtl }"
                  :style="`font-size: ${$vuetify.rtl ? 0.875 : 0.575}rem`">
                  {{ $vuetify.rtl ? item.fa : item.en }}
                  <!-- <v-icon v-text="item.icon"></v-icon> -->
                </v-tab>
              </v-tabs>
            </v-col>
            <!--  -->
          </v-layout>
          <!-- actions -->
          <v-card-actions class="mt-md-5 mt-lg-7 mt-xl-7 hidden-sm-and-down">
            <v-spacer />
            <v-btn :color="$vuetify.theme.dark ? style.secondary : style.primary" depressed dark style="letter-spacing: 0"
              :class="{
                'no-letter-spacing': $vuetify.rtl,
              }" @click.stop="logout()">
              <v-icon left> mdi-logout-variant </v-icon>
              {{
                $vuetify.rtl
                ? localization.fa.dashboard.logout
                : localization.en.dashboard.logout
              }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
          <!-- mobile screen part -->
          <div class="hidden-md-and-up">
            <!-- personal information form -->
            <v-card-text v-if="nav_model === 0">
              <v-row class="justify-space-around">
                <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                  <v-text-field v-model="user_changes.new_first_name" outlined disabled
                    :label="$vuetify.rtl ? 'نام' : 'First Name'" :placeholder="$vuetify.rtl ? 'نام' : 'First Name'"
                    :color="
                      $vuetify.theme.dark ? style.secondary : style.primary
                    "></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                  <v-text-field v-model="user_changes.new_last_name" outlined disabled
                    :label="$vuetify.rtl ? 'نام خانوادگی' : 'Last Name'"
                    :placeholder="$vuetify.rtl ? 'نام خانوادگی' : 'Last Name'" :color="
                      $vuetify.theme.dark ? style.secondary : style.primary
                    "></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-space-around">
                <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                  <v-text-field v-model="user_changes.new_username" outlined
                    :label="$vuetify.rtl ? 'نام کاربری' : 'Username'" readonly disabled dir="ltr"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                  <v-text-field v-model="user_changes.new_email" outlined :label="$vuetify.rtl ? 'ایمیل' : 'Email'"
                    :placeholder="$vuetify.rtl ? 'ایمیل' : 'Email'" type="email" :rules="[rules.email]" :color="
                      $vuetify.theme.dark ? style.secondary : style.primary
                    " dir="ltr">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-space-around">
                <v-col cols="12" sm="12" md="5" lg="5" xl="5" align-self="center">
                  <custom-tel-input outlined v-model="user_changes.new_phone"
                    :label="$vuetify.rtl ? 'شماره همراه' : 'Phone Number'"
                    :placeholder="$vuetify.rtl ? 'شماره همراه' : 'Phone Number'" :color="
                      $vuetify.theme.dark ? style.secondary : style.primary
                    " mode="international" dir="ltr"></custom-tel-input>
                </v-col>
                <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                  <v-text-field outlined :placeholder="
                    $vuetify.rtl ? 'لینک شبکه اجتماعی' : 'Social Media'
                  " :color="
  $vuetify.theme.dark ? style.secondary : style.primary
" v-model="user_changes.new_social_media_link" dir="ltr">
                    <template v-slot:prepend-inner v-if="!$vuetify.rtl">
                      <v-icon size="20" :color="social_medias[userSocialMedia - 1].color" class="pe-1"
                        v-if="userSocialMedia != -1">{{ social_medias[userSocialMedia - 1].icon }}</v-icon>
                    </template>
                    <template v-slot:append v-else>
                      <v-icon size="20" :color="social_medias[userSocialMedia - 1].color" class="ps-1"
                        v-if="userSocialMedia != -1">{{ social_medias[userSocialMedia - 1].icon }}</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <!-- if it is subscription form -->
            <v-card-text v-if="nav_model === 17">
              <v-row class="justify-center align-center text-center">
                <v-col cols="8" v-if="
                  chart_config.dataset.data[0] != 0 ||
                  chart_config.dataset.data[1] != 0
                ">
                  <!-- <v-img contain :src="require('@/assets/free_subscription.svg')">
                </v-img> -->
                  <custom-chart :chart_type="chart_config.type" :labels="
                    this.$vuetify.rtl
                      ? [
                        this.credit_msg.days_left.fa,
                        this.credit_msg.used_days.fa,
                      ]
                      : [
                        this.credit_msg.days_left.en,
                        this.credit_msg.used_days.en,
                      ]
                  " :dataset="chart_config.dataset" verbose="user-subscription"></custom-chart>
                </v-col>
                <v-col cols="12" v-else>
                  {{
                    $vuetify.rtl
                    ? "اعتبار شما تمام شده است"
                    : "Your subscription has expired"
                  }}
                </v-col>
              </v-row>
              <v-row class="justify-center align-start mt-6" v-if="
                chart_config.dataset.data[0] != 0 ||
                chart_config.dataset.data[1] != 0
              ">
                {{ subscriptionNotificationMsg }}
              </v-row>
              <!-- <v-row>
              <v-col v-for="n in 3" :key="n">
                <v-hover v-slot="{ hover }">
                  <v-card
                    height="330"
                    rounded
                    outlined
                    :class="{
                      'subscription-card-fa': $vuetify.rtl,
                      'subscription-card-en': !$vuetify.rtl,
                      'subscription-card-light-fa-hover':
                        !$vuetify.theme.dark && $vuetify.rtl && hover,
                      'subscription-card-light-en-hover':
                        !$vuetify.theme.dark && !$vuetify.rtl && hover,
                      'subscription-card-dark-fa-hover':
                        $vuetify.theme.dark && $vuetify.rtl && hover,
                      'subscription-card-dark-en-hover':
                        $vuetify.theme.dark && !$vuetify.rtl && hover,
                    }"
                    style="cursor: pointer"
                  >
                    <v-card-text class="text-center" style="font-weight: 500">
                      {{
                        $vuetify.rtl
                          ? subscription_plans[n - 1].fa
                          : subscription_plans[n - 1].en
                      }}
                    </v-card-text>
                    <v-card-text
                      class="text-center"
                      style="font-weight: 680; font-size: 1.05rem"
                    >
                      {{
                        $vuetify.rtl
                          ? subscription_plans[n - 1].rial_price + " تومان"
                          : "$ " + subscription_plans[n - 1].dollar_price
                      }}
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn :class="{ 'no-letter-spacing': $vuetify.rtl }">{{
                        $vuetify.rtl ? "خرید" : "Buy"
                      }}</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row> -->
            </v-card-text>
            <!-- change password -->
            <v-card-text v-if="nav_model === 1">
              <v-text-field v-model="user_changes.current_pass" outlined
                :label="$vuetify.rtl ? 'رمزعبور فعلی' : 'Current Password'" :placeholder="
                  $vuetify.rtl ? 'رمزعبور فعلی' : 'Current Password'
                " :color="$vuetify.theme.dark ? style.secondary : style.primary"></v-text-field>
              <v-text-field v-model="user_changes.new_pass" :append-icon="hide_password ? 'mdi-eye' : 'mdi-eye-off'"
                :type="hide_password ? 'password' : 'text'" outlined :rules="passwordRules"
                :label="$vuetify.rtl ? 'رمزعبور جدید' : 'New Password'"
                :placeholder="$vuetify.rtl ? 'رمزعبور جدید' : 'New Password'"
                :color="$vuetify.theme.dark ? style.secondary : style.primary"
                @click:append="hide_password = !hide_password"></v-text-field>
              <v-text-field v-model="user_changes.new_pass_confirmation"
                :append-icon="hide_password ? 'mdi-eye' : 'mdi-eye-off'" :type="hide_password ? 'password' : 'text'"
                outlined :label="
                  $vuetify.rtl
                    ? 'تایید رمزعبور جدید'
                    : 'New Password Confirmation'
                " :placeholder="
  $vuetify.rtl
    ? 'تایید رمزعبور جدید'
    : 'New Password Confirmation'
" :color="$vuetify.theme.dark ? style.secondary : style.primary" @click:append="hide_password = !hide_password"
                :rules="passwordRules"></v-text-field>
            </v-card-text>
            <!--  -->
            <v-card-actions :class="update_btn_class[nav_model]">
              <v-spacer />
              <v-btn :color="$vuetify.theme.dark ? style.secondary : style.primary" dark depressed
                :loading="update_btn_loading" :class="{
                  'no-letter-spacing': $vuetify.rtl,
                  'mb-4': $vuetify.breakpoint.mdAndDown,
                }" @click="editUserInfo" v-if="nav_model != 2">
                {{ $vuetify.rtl ? "بروزرسانی" : "Update" }}
              </v-btn>
              <v-spacer v-if="$vuetify.breakpoint.mdAndDown" />
            </v-card-actions>
          </div>
          <!--  -->
        </v-card>
      </v-flex>
      <!-- pages -->
      <v-flex xs12 sm12 md8 lg8 xl6 align-self-center v-if="$vuetify.breakpoint.mdAndUp">
        <v-card height="500" :flat="$vuetify.breakpoint.mdAndUp ? false : true">
          <v-card-title class="mb-5 hidden-sm-and-down">
            {{
              $vuetify.rtl ? nav_items[nav_model].fa : nav_items[nav_model].en
            }}
          </v-card-title>
          <!-- personal information form -->
          <v-card-text v-if="nav_model === 0">
            <v-row class="justify-space-around">
              <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                <v-text-field v-model="user_changes.new_first_name" disabled outlined
                  :label="$vuetify.rtl ? 'نام' : 'First Name'" :placeholder="$vuetify.rtl ? 'نام' : 'First Name'"
                  :color="$vuetify.theme.dark ? style.secondary : style.primary"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                <v-text-field v-model="user_changes.new_last_name" disabled outlined
                  :label="$vuetify.rtl ? 'نام خانوادگی' : 'Last Name'"
                  :placeholder="$vuetify.rtl ? 'نام خانوادگی' : 'Last Name'"
                  :color="$vuetify.theme.dark ? style.secondary : style.primary"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="justify-space-around">
              <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                <v-text-field v-model="user_changes.new_username" outlined
                  :label="$vuetify.rtl ? 'نام کاربری' : 'Username'" disabled readonly dir="ltr"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                <v-text-field v-model="user_changes.new_email" outlined :label="$vuetify.rtl ? 'ایمیل' : 'Email'"
                  :placeholder="$vuetify.rtl ? 'ایمیل' : 'Email'" type="email" :rules="[rules.email]"
                  :color="$vuetify.theme.dark ? style.secondary : style.primary" dir="ltr">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="justify-space-around">
              <v-col cols="12" sm="12" md="5" lg="5" xl="5" align-self="center">
                <custom-tel-input outlined v-model="user_changes.new_phone"
                  :label="$vuetify.rtl ? 'شماره همراه' : 'Phone Number'"
                  :placeholder="$vuetify.rtl ? 'شماره همراه' : 'Phone Number'"
                  :color="$vuetify.theme.dark ? style.secondary : style.primary" mode="international" dir="ltr">
                </custom-tel-input>
              </v-col>
              <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                <v-text-field outlined :placeholder="
                  $vuetify.rtl ? 'لینک شبکه اجتماعی' : 'Social Media'
                " :color="$vuetify.theme.dark ? style.secondary : style.primary"
                  v-model="user_changes.new_social_media_link" dir="ltr">
                  <template v-slot:prepend-inner v-if="!$vuetify.rtl">
                    <v-icon size="20" :color="social_medias[userSocialMedia - 1].color" class="pe-1"
                      v-if="userSocialMedia != -1">{{ social_medias[userSocialMedia - 1].icon }}</v-icon>
                  </template>
                  <template v-slot:append v-else>
                    <v-icon size="20" :color="social_medias[userSocialMedia - 1].color" class="ps-1"
                      v-if="userSocialMedia != -1">{{ social_medias[userSocialMedia - 1].icon }}</v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- if it is subscription form -->
          <v-card-text v-if="nav_model === 17">
            <v-row class="justify-center align-center">
              <v-col cols="6" v-if="
                chart_config.dataset.data[0] != 0 ||
                chart_config.dataset.data[1] != 0
              ">
                <!-- <v-img contain :src="require('@/assets/free_subscription.svg')">
                </v-img> -->
                <custom-chart :chart_type="chart_config.type" :labels="
                  this.$vuetify.rtl
                    ? [
                      this.credit_msg.days_left.fa,
                      this.credit_msg.used_days.fa,
                    ]
                    : [
                      this.credit_msg.days_left.en,
                      this.credit_msg.used_days.en,
                    ]
                " :dataset="chart_config.dataset" verbose="user-subscription-md-and-up"></custom-chart>
              </v-col>
              <v-col cols="12" v-else>
                {{
                  $vuetify.rtl
                  ? "اعتبار شما تمام شده است"
                  : "Your subscription has expired"
                }}
              </v-col>
            </v-row>
            <v-row class="justify-center align-start mt-6" v-if="
              chart_config.dataset.data[0] != 0 ||
              chart_config.dataset.data[1] != 0
            ">
              {{ subscriptionNotificationMsg }}
            </v-row>
            <!-- <v-row>
              <v-col v-for="n in 3" :key="n">
                <v-hover v-slot="{ hover }">
                  <v-card
                    height="330"
                    rounded
                    outlined
                    :class="{
                      'subscription-card-fa': $vuetify.rtl,
                      'subscription-card-en': !$vuetify.rtl,
                      'subscription-card-light-fa-hover':
                        !$vuetify.theme.dark && $vuetify.rtl && hover,
                      'subscription-card-light-en-hover':
                        !$vuetify.theme.dark && !$vuetify.rtl && hover,
                      'subscription-card-dark-fa-hover':
                        $vuetify.theme.dark && $vuetify.rtl && hover,
                      'subscription-card-dark-en-hover':
                        $vuetify.theme.dark && !$vuetify.rtl && hover,
                    }"
                    style="cursor: pointer"
                  >
                    <v-card-text class="text-center" style="font-weight: 500">
                      {{
                        $vuetify.rtl
                          ? subscription_plans[n - 1].fa
                          : subscription_plans[n - 1].en
                      }}
                    </v-card-text>
                    <v-card-text
                      class="text-center"
                      style="font-weight: 680; font-size: 1.05rem"
                    >
                      {{
                        $vuetify.rtl
                          ? subscription_plans[n - 1].rial_price + " تومان"
                          : "$ " + subscription_plans[n - 1].dollar_price
                      }}
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn :class="{ 'no-letter-spacing': $vuetify.rtl }">{{
                        $vuetify.rtl ? "خرید" : "Buy"
                      }}</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row> -->
          </v-card-text>
          <!-- change password -->
          <v-card-text v-if="nav_model === 1">
            <v-text-field v-model="user_changes.current_pass" outlined
              :label="$vuetify.rtl ? 'رمزعبور فعلی' : 'Current Password'"
              :placeholder="$vuetify.rtl ? 'رمزعبور فعلی' : 'Current Password'"
              :color="$vuetify.theme.dark ? style.secondary : style.primary"></v-text-field>
            <v-text-field v-model="user_changes.new_pass" :append-icon="hide_password ? 'mdi-eye' : 'mdi-eye-off'"
              :type="hide_password ? 'password' : 'text'" outlined :rules="passwordRules"
              :label="$vuetify.rtl ? 'رمزعبور جدید' : 'New Password'"
              :placeholder="$vuetify.rtl ? 'رمزعبور جدید' : 'New Password'"
              :color="$vuetify.theme.dark ? style.secondary : style.primary"
              @click:append="hide_password = !hide_password"></v-text-field>
            <v-text-field v-model="user_changes.new_pass_confirmation"
              :append-icon="hide_password ? 'mdi-eye' : 'mdi-eye-off'" :type="hide_password ? 'password' : 'text'"
              outlined :label="
                $vuetify.rtl
                  ? 'تایید رمزعبور جدید'
                  : 'New Password Confirmation'
              " :placeholder="
  $vuetify.rtl
    ? 'تایید رمزعبور جدید'
    : 'New Password Confirmation'
" :color="$vuetify.theme.dark ? style.secondary : style.primary" @click:append="hide_password = !hide_password"
              :rules="passwordRules"></v-text-field>
          </v-card-text>
          <!--  -->
          <v-card-actions :class="update_btn_class[nav_model]">
            <v-spacer />
            <v-btn :color="$vuetify.theme.dark ? style.secondary : style.primary" dark depressed
              :loading="update_btn_loading" :class="{
                'no-letter-spacing': $vuetify.rtl,
              }" @click="editUserInfo" v-if="nav_model != 2">
              {{ $vuetify.rtl ? "بروزرسانی" : "Update" }}
            </v-btn>
            <v-spacer v-if="$vuetify.breakpoint.mdAndDown" />
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- new -->
    <v-overlay :value="is_loading">
      <v-progress-circular indeterminate size="64" color="#146A5F">
      </v-progress-circular>
    </v-overlay>
    <Message ref="Message" />
  </v-container>
  <!--  -->
</template>

<script>
import CustomCropper from "../../components/CustomCropper.vue";
import LocalizationMixin from "../../mixins/localeMixin";
import AuthMixin from "../../mixins/authMixin";
import APIMixin from "../../mixins/apiMixin";
import styleMixin from "../../mixins/styleMixin";
import utils from "../../mixins/utils";
import Message from "../../components/MessageShow.vue";
import CustomTelInput from "../../components/CustomTelInput.vue";
import CustomChart from "../../components/CustomChart.vue";

export default {
  metaInfo() {
    return {
      title: this.$vuetify.rtl ? "داشبورد" : "Dashboard",
      meta: [
        {
          name: "description",
          content: "User Dashboard",
        },
        {
          property: "og:title",
          content: this.$vuetify.rtl ? "داشبورد" : "Dashboard",
        },
        {
          property: "og:description",
          content: "User Dashboard",
        },
        {
          property: "og:url",
          content: "https://zagros.live" + this.$router.currentRoute.path,
        },
      ],
    };
  },
  name: "Dashboard",
  // props: {
  //   source: String,
  // },
  components: {
    Message,
    CustomCropper,
    // VueTelInputVuetify,
    CustomTelInput,
    CustomChart,
  },
  mixins: [AuthMixin, LocalizationMixin, APIMixin, styleMixin, utils],
  data: () => ({
    rules: {
      required: (value) => !!value || "پر کردن این فیلد اجباری است",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "ایمیل به درستی وارد نشده است";
      },
      username: (value) => {
        const pattern = /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
        return pattern.test(value) || "فرم نام کاربری صحیح نمی\u200cباشد";
      },
      phone: (value) => {
        const pattern = /^([09]){2}([0-9]){9}$/;
        return (
          pattern.test(value) ||
          "شماره همراه تنها باید شامل اعداد 0 تا 9 و 11 رقم باشد"
        );
      },
    },
    crop_dialog: false,
    user: null,
    usernames: null,
    hide_password: true,
    final_dial_code: "",
    user_changes: {
      selectedProfilePic: { image: null, url: null },
      new_pass: "",
      new_pass_confirmation: "",
      new_first_name: "",
      new_last_name: "",
      new_username: "",
      new_email: "",
      new_phone: "",
      new_social_media_link: "",
    },
    user_lang: { id: 1, title: "فارسی" },
    avatar_size: "115",
    nav_items: [
      {
        fa: "اطلاعات شخصی",
        en: "Personal Information",
        icon: "mdi-information",
      },
      // {
      //   fa: "اشتراک",
      //   en: "Subscription",
      //   icon: "mdi-card-account-details-star",
      // },
      {
        fa: "تغییر رمزعبور",
        en: "Change Password",
        icon: "mdi-key",
      },
    ],
    nav_model: 0,
    update_btn_loading: false,
    update_btn_class: [
      "me-xl-10 mt-xl-2 me-lg-7 mt-lg-2 me-md-7 ",
      "me-xl-2 mt-xl-10 me-lg-2 mt-lg-8 me-md-2 mt-md-6",
      "me-xl-2 mt-xl-10 me-lg-2 mt-lg-8 me-md-2 mt-md-6",
    ],
    chart_config: {
      type: "doughnut",
      dataset: {
        label: "",
        data: [],
        hoverOffset: 4,
      },
      labels: [],
    },
    social_medias: [
      {
        icon: "mdi-linkedin",
        color: "blue",
      },
      {
        icon: "mdi-instagram",
        color: "purple",
      },
      {
        icon: "mdi-twitter",
        color: "#31A4F1",
      },
      {
        icon: "mdi-facebook",
        color: "#1877F2",
      },
      {
        icon: "mdi-youtube",
        color: "#FF1A1A",
      },
    ],
    subscription_plans: [
      {
        en: "3 Months",
        fa: "سه ماهه",
        rial_price: "۳۰,۰۰۰",
        dollar_price: 2.99,
      },
      {
        en: "6 Months",
        fa: "شش ماهه",
        rial_price: "۵۰,۰۰۰",
        dollar_price: 4.99,
      },
      {
        en: "yearly",
        fa: "یک ساله",
        rial_price: "۱۰۰,۰۰۰",
        dollar_price: 9.99,
      },
    ],
    is_mobilescreen: false,
    is_newusernamevalid: true,
    credit_msg: {
      used_days: {
        fa: "اعتبار مصرف شده",
        en: "used days",
      },
      days_left: {
        fa: "اعتبار باقی مانده",
        en: "days left",
      },
    },
    is_loading: false, // new
  }),
  methods: {
    // goToLogout: function () {
    //   this.logout();
    // },
    setUserInfo: function () {
      this.user = this.$store.state.user_info;
      this.user_changes.new_first_name = this.user.first_name;
      this.user_changes.new_last_name = this.user.last_name;
      this.user_changes.new_username = this.user.username;
      this.user_changes.new_email = this.user.email;
      this.user_changes.new_phone = this.user.phone_number;
      this.user_changes.new_social_media_link = this.user.social_media_link;
      let subs_plan_data = this.setupSubscriptionPlansCharts(
        this.user.subscription_set
      );
      this.chart_config.dataset.data.push(subs_plan_data[0]);
      this.chart_config.dataset.data.push(subs_plan_data[1]);
      this.$store.commit("changeUserSubsDays", subs_plan_data[0]);
    },
    getUserInfo: function () {
      this.is_loading = true;
      this.$axios
        .get(this.user_api.user_info, { withCredentials: true })
        .then((res) => {
          if (!res.data.status) {
            this.logout();
          } else {
            this.$store.commit("changeUserInfo", res.data.data);
            this.user = res.data.data;
            this.user_changes.new_first_name = this.user.first_name;
            this.user_changes.new_last_name = this.user.last_name;
            this.user_changes.new_username = this.user.username;
            this.user_changes.new_email = this.user.email;
            this.user_changes.new_phone = this.user.phone_number;
            this.user_changes.new_social_media_link =
              this.user.social_media_link;
            let subs_plan_data = this.setupSubscriptionPlansCharts(
              this.user.subscription_set
            );
            this.chart_config.dataset.data.push(subs_plan_data[0]);
            this.chart_config.dataset.data.push(subs_plan_data[1]);
            this.$store.commit("changeUserSubsDays", subs_plan_data[0]);
          }
        })
        .catch(() => {
          this.logout();
          this.is_loading = false;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    getUserProfileImage: function () {
      if (this.user != null) {
        if (this.user.profile_image != null) {
          return this.user.profile_image;
        }
      }
    },
    editUserInfo: function () {
      // changing user profile pic
      if (this.user_changes.selectedProfilePic.image != null) {
        this.update_btn_loading = true;
        let formData = new FormData();
        formData.append(
          "files",
          this.user_changes.selectedProfilePic.image,
          `${this.user.username}-profpic`
        );
        // copy one into a new variable
        const info_changes = (({
          current_pass,
          new_pass,
          new_pass_confirmation,
          new_first_name,
          new_last_name,
          new_username,
          new_email,
          new_phone,
          new_social_media_link,
        }) => ({
          current_pass,
          new_pass,
          new_pass_confirmation,
          new_first_name,
          new_last_name,
          new_username,
          new_email,
          new_phone,
          new_social_media_link,
        }))(this.user_changes);
        // append it to formData variable
        formData.append("changes", JSON.stringify(info_changes));
        // send it with this.$axios
        this.$axios
          .put(this.user_api.user_info, formData, { withCredentials: true })
          .then((res) => {
            if (res.data.status) {
              this.$refs.Message.showMessage(
                this.$vuetify.rtl
                  ? "تغییرات با موفقیت انجام شد"
                  : "Changes updated successfully",
                "success"
              );
              this.$store.commit("changeUserInfo", res.data.data.user);
              // this.$refs.sideBar.getUserInfo();
            }
          })
          .catch(() => {
            this.$refs.Message.showMessage(
              this.$vuetify.rtl
                ? "تغییرات با موفقیت انجام نشد. دوباره تلاش کنید"
                : "There was something wrong with updating your profile",
              "success"
            );
          })
          .finally(() => {
            this.update_btn_loading = false;
          });
      }
      // changing user change
      else if (
        this.changeUserInfoValidation() &&
        this.validateFormConstraints()
      ) {
        this.update_btn_loading = true;
        this.$axios
          .put(this.user_api.user_info, this.user_changes, {
            withCredentials: true,
          })
          .then((res) => {
            const payload = res.data.data.payload;
            // show message if the changed user name is already taken
            if (payload.username_status == 0) {
              this.user_changes.new_username = this.user.username;
            } else {
              this.$refs.Message.showMessage(
                this.$vuetify.rtl
                  ? "تغییرات با موفقیت انجام شد"
                  : "Changes updated successfully",
                "success"
              );
            }
            // TO DO
            // logout if pass or username is changed
            if (payload.pass_status === 1 || payload.username_status === 1) {
              this.logout();
            }
          })
          .finally(() => {
            this.update_btn_loading = false;
          });
      }
    },
    // chooseFiles: function() {
    //   let fileUploader = this.$refs.uploader;
    //   fileUploader.click();
    // },
    // onFileChange: function(e) {
    //   var files = e.target.files || e.dataTransfer.files;
    //   if (!files.length) return;
    //   this.user_changes.selectedProfilePic.image = files[0];
    //   this.user_changes.selectedProfilePic.url = URL.createObjectURL(files[0]);
    // },
    changeUserInfoValidation: function () {
      return (
        this.user_changes.new_first_name != this.user.first_name ||
        this.user_changes.new_last_name != this.user.last_name ||
        this.user_changes.new_username != this.user.username ||
        (this.user_changes.new_pass.length &&
          this.user_changes.new_pass ===
          this.user_changes.new_pass_confirmation) ||
        this.user_changes.new_email != this.user.email ||
        this.user_changes.new_phone != this.user.phone_number ||
        (this.user_changes.new_social_media_link !=
          this.user.social_media_link &&
          this.userSocialMedia != -1)
      );
    },
    validateFormConstraints: function () {
      // first check the username
      if (
        this.user_changes.new_username != this.user.username &&
        this.user_changes.new_username.length
      ) {
        if (
          !/^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/.test(
            this.user_changes.new_username
          )
        ) {
          this.$refs.Message.showMessage(
            "نام کاربری وارد شده تنها باید شامل حروف انگلیسی اعداد و _ باشد",
            "error"
          );
          return false;
        }
      }

      // check the email
      if (
        this.user_changes.new_email != this.user.email &&
        this.user_changes.new_email.length
      ) {
        if (
          !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(
            this.user_changes.new_email
          )
        ) {
          this.$refs.Message.showMessage(
            "ایمیل به درستی وارد نشده است",
            "error"
          );
          return false;
        }
      }

      // check the phone number
      // if (
      //   this.user_changes.new_phone != this.user.phone_number &&
      //   this.user_changes.new_phone.length
      // ) {
      //   if (!/^([09]){2}([0-9]){9}$/.test(this.user_changes.new_phone)) {
      //     this.$refs.Message.showMessage(
      //       "شماره همراه تنها باید شامل اعداد 0 تا 9 و 11 رقم باشد",
      //       "error"
      //     );
      //     return false;
      //   }
      // }

      // setTimeout(function () {
      //   //your code to be executed after 1 second
      // }, 800);
      return true;
    },
    // getPreoccupiedUsernames: function() {
    //   this.$axios.get(this.api.usernames).then((res) => {
    //     this.usernames = res.data.data;
    //   }).catch((err) => {
    //     if (err.response.status == 401) {
    //       this.logout();
    //     }
    // });
    // },
    passwordErrors: function () {
      return this.$vuetify.rtl
        ? "رمزعبور باید بیشتر از ۸ کاراکتر و شامل حروف کوچک و بزرگ، اعداد و کاراکترهای خاص باشد "
        : "password should contain small and capital letters, numbers and special characters";
    },
    requiredErrors: function () {
      return this.$vuetify.rtl
        ? "این فیلد اجباری است"
        : "this field is required";
    },
  },
  computed: {
    userSocialMedia() {
      const linkedin_pattern =
        /^(?:https?:)?\/\/(?:[\w]+\.)?linkedin\.com\/in\//;
      const instagram_pattern = /^(?:https?:)?\/\/(?:[\w]+\.)?instagram\.com\//;
      const twitter_pattern = /^(?:https?:)?\/\/(?:[\w]+\.)?twitter\.com\//;
      const facebook_pattern = /^(?:https?:)?\/\/(?:[\w]+\.)?facebook\.com\//;
      const youtube_pattern =
        /^(?:https?:)?\/\/(?:[\w]+\.)?youtube\.com\/channel\//;
      if (linkedin_pattern.test(this.user_changes.new_social_media_link)) {
        return 1;
      } else if (
        instagram_pattern.test(this.user_changes.new_social_media_link)
      ) {
        return 2;
      } else if (
        twitter_pattern.test(this.user_changes.new_social_media_link)
      ) {
        return 3;
      } else if (
        facebook_pattern.test(this.user_changes.new_social_media_link)
      ) {
        return 4;
      } else if (
        youtube_pattern.test(this.user_changes.new_social_media_link)
      ) {
        return 5;
      } else {
        return -1;
      }
    },
    passwordRules() {
      const rules = [];
      const pattern =
        /^(?=.*([A-Z]){1,})(?=.*[!@#$&*]{1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,100}$/;
      const rule = (value) => pattern.test(value) || this.passwordErrors();
      const reqRule = (value) => !!value || this.requiredErrors();
      rules.push(rule);
      rules.push(reqRule);
      //
      return rules;
    },
    subscriptionNotificationMsg() {
      const diff = this.chart_config.dataset.data[0];
      if (this.$vuetify.rtl) {
        return `اعتبار باقی مانده : ${diff} روز`;
      } else {
        // return "You have a free trial for a year";
        return `subscription expires in ${diff} days`;
      }
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.avatar_size = "90";
    } else {
      this.avatar_size = "115";
    }
  },
  created() {
    if (!this.isAuthenticated()) {
      this.$router.push({ name: "login" });
    }
    // now check the lang
    if (this.getLocale() === "fa") {
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }
    if (this.$store.state.user_info === undefined) this.getUserInfo();
    else this.setUserInfo();

    // this.getPreoccupiedUsernames();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.no-letter-spacing {
  letter-spacing: 0;
}

.primary-chip-class {
  color: #2b64b9;
  font-weight: 570;
}

.error-chip-class {
  color: #f44336;
  font-weight: 570;
}

.success-chip-class {
  color: #4caf50;
  font-weight: 570;
}

.pink-chip-class {
  color: #e91e63;
  font-weight: 570;
}

.logout-btn-fa {
  position: absolute;
  bottom: 15%;
  left: 40%;
}

.logout-btn-en {
  position: absolute;
  bottom: 15%;
  right: 30%;
}

.update-btn-fa {
  position: absolute;
  bottom: 15%;
  left: 40%;
}

.update-btn-en {
  position: absolute;
  bottom: 15%;
  right: 30%;
}

.subscription-card-fa {
  // cursor: pointer;
  -webkit-box-shadow: 0px 0px rgb(32 33 37 / 10%);
  box-shadow: 0px 0px rgb(32 33 37 / 10%);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.subscription-card-en {
  // cursor: pointer;
  -webkit-box-shadow: 0px 0px rgb(32 33 37 / 10%);
  box-shadow: 0px 0px rgb(32 33 37 / 10%);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.subscription-card-light-fa-hover {
  // cursor: pointer;
  -webkit-box-shadow: -8px 8px rgb(32 33 37 / 10%);
  box-shadow: -8px 8px rgb(32 33 37 / 5%);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.subscription-card-light-en-hover {
  // cursor: pointer;
  -webkit-box-shadow: 8px 8px rgb(32 33 37 / 10%);
  box-shadow: 8px 8px rgb(32 33 37 / 5%);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.subscription-card-dark-fa-hover {
  // cursor: pointer;
  -webkit-box-shadow: -6px 6px rgb(32 33 37 / 10%);
  box-shadow: -6px 6px rgb(32 33 37 / 5%);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.subscription-card-dark-en-hover {
  // cursor: pointer;
  -webkit-box-shadow: 6px 6px rgb(32 33 37 / 10%);
  box-shadow: 6px 6px rgb(32 33 37 / 5%);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.phone-outlined {
  border: solid 1px;
}

.fa-user-card {
  font-family: IRanSans;
}

// .v-card:not(.subscription-card-light-fa) {
//   box-shadow: -4px 4px #cf7777;
// }

// .v-card:not(.subscription-card-light-en) {
//   box-shadow: 4px 4px #cf7777;
// }
</style>

