var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('live-crypto-price',{staticClass:"mt-4"})],1)],1),_c('v-row',{staticClass:"justify-center align-center",class:{ 'mb-2': !_vm.$vuetify.rtl }},[_c('v-col',{attrs:{"cols":"12","lg":"8","xl":"9"}},[_c('v-text-field',{ref:"searchBar",attrs:{"append-icon":"mdi-magnify","placeholder":_vm.locale === 'fa'
          ? _vm.localization.fa.search_bar
          : _vm.localization.en.search_bar + ' (press /)',"outlined":"","hide-details":"","color":"searchbar"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getFilteredNews()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","xl":"3"}},[_c('v-select',{staticClass:"custom-scrollbar",attrs:{"outlined":"","hide-details":"","item-text":"title","item-value":"sort_index","item-color":"sort_by_item","color":"primary","placeholder":_vm.locale === 'fa'
            ? _vm.localization.fa.news.sort.title
            : _vm.localization.en.news.sort.title,"label":_vm.locale === 'fa'
    ? _vm.localization.fa.news.sort.title
    : _vm.localization.en.news.sort.title,"items":_vm.locale === 'fa'
    ? _vm.localization.fa.news.sort.items
    : _vm.localization.en.news.sort.items},on:{"change":function($event){return _vm.getFilteredNews()}},model:{value:(_vm.sort_by),callback:function ($$v) {_vm.sort_by=$$v},expression:"sort_by"}})],1)],1),_c('v-row',{staticClass:"justify-space-around align-center mb-2",class:{ 'hidden-by-lang': !_vm.$vuetify.rtl }},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{class:{
    'no-letter-spacing lang-tabs-fa-text-md lang-tabs-fa-text-lg':
      _vm.locale === 'fa',
    'lang-tabs-en-text-md lang-tabs-en-text-lg': _vm.locale === 'en',
  },style:(((_vm.checkDarkAndLocale(1)) + ";")),attrs:{"block":"","dark":_vm.btn_lang_model === 1 ? true : false,"color":_vm.currentRoute() === 'fa' ? 'primary' : '#F5F5F5'},domProps:{"textContent":_vm._s(_vm.locale === 'fa'
          ? _vm.localization.fa.news.lang_tabs.fa
          : _vm.localization.en.news.lang_tabs.fa
        )},on:{"click":function($event){$event.preventDefault();return _vm.changeLocale(1)}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{class:{
    'no-letter-spacing lang-tabs-fa-text-md lang-tabs-fa-text-lg':
      _vm.locale === 'fa',
    'lang-tabs-en-text-md lang-tabs-en-text-lg': _vm.locale === 'en',
  },style:(((_vm.checkDarkAndLocale(2)) + ";")),attrs:{"block":"","dark":_vm.btn_lang_model === 2 ? true : false,"color":_vm.currentRoute() === 'en' ? 'primary' : '#F5F5F5'},domProps:{"textContent":_vm._s(_vm.locale === 'fa'
          ? _vm.localization.fa.news.lang_tabs.en
          : _vm.localization.en.news.lang_tabs.en
        )},on:{"click":function($event){$event.preventDefault();return _vm.changeLocale(2)}}})],1)],1),(_vm.fetched_news_finished && _vm.news.length)?_vm._l((_vm.news),function(item,i){return _c('v-container',{key:i,staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[(item.source.locale === _vm.currentRoute() && item != undefined)?_c('news-card',{attrs:{"news":item,"locale":_vm.NewsTabLang()}}):_vm._e()],1)}):_vm._e(),((!_vm.fetched_news_finished && _vm.is_loading) || _vm.news === null)?_vm._l((10),function(n){return _c('v-skeleton-loader',{key:n,staticClass:"mb-4",attrs:{"type":"list-item-avatar-three-line","dir":_vm.NewsTabLang() === 'fa' ? 'rtl' : 'ltr'}})}):[(_vm.news.length == 0)?_c('div',[(_vm.locale === 'fa')?_c('v-container',{attrs:{"fluid":""}},[_c('v-alert',{attrs:{"outlined":"","type":"error","prominent":"","border":"left"}},[_c('div',[_vm._v(" "+_vm._s(_vm.localization.fa.news.not_found_msg)+" ")])])],1):_c('v-container',{attrs:{"fluid":""}},[_c('v-alert',{attrs:{"outlined":"","type":"error","prominent":"","border":"right"}},[_c('div',{staticStyle:{"margin-left":"15px"}},[_vm._v(" "+_vm._s(_vm.localization.en.news.not_found_msg)+" ")])])],1)],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }