import { en } from 'vuetify/lib/locale'

export default {
  ...en,
  categories: "Categories",
  people: "Influencers",
  next: "next",
  back: "previous",
  login: {
    title: "Login",
    username_or_email: "Username or Email",
    password: "password",
    no_account_msg: "New to Zagros? ",
    forgot_password_msg: "Forgot Password",
    signup_msg: "Sign Up"
  },
  reset_password: {
    title: "Reset Password",
  },
  signup: {
    title: "Sign Up",
    username: "username",
    email: "email",
    password: "password",
    first_name: "first name",
    last_name: "last name",
    resend_confirmation_title: "Activation account email has been sent to your email.",
    resend_confirmation_msg: "Didn't receive any mails?",
    resend_confirmation_action: "resend",
  },
  news: {
    lang_tabs: {
      fa: "Farsi News",
      en: "English News"
    },
    not_found_msg: "Not Found",
    actions: {
      view: "Views",
      star: "Importance",
      bull: "Bullish",
      bear: "Bearish",
    },

    sort: {
      title: "Sort by",
      items: [{
        sort_index: 3,
        title: "Bullish",
      },
      // "Bullish Descending",
      {
        sort_index: 2,
        title: "Bearish",
      },
      // "Bearish Descending",
      {
        sort_index: 1,
        title: "Importance",
      },
      // "Importance Descending",
      {
        sort_index: 0,
        title: "Views",
      },
      // "Views Descending",
      {
        sort_index: null,
        title: "Latest News",
      },
        // 
      ],
    },
  },
  search_bar: "Search by title",
  dashboard: {
    main_page: "main page",
    title: "speech therapy",
    form_templates: "forms",
    form_answers: "create form for patient",
    image_gallery: "Gallery",
    patient_sign_up: "Booking",
    setting: "Setting",
    logout: "Logout",
    patients_list: "Patients List",
    patients_form_answers_list: "Patients Forms",
    practices_list: "Patients Practices",
    completed_practices: "Accomplished Practices",
  },
  auth: {
    login_failed: "Username or Password is wrong!",
    login_succed: "Welcome!",
    confirmation_email_expired: "This email is expired",
    reset_password_succed: "Your password changed successfully",
    reset_password_failed: "There was a problem with changing your password",
    already_active_msg: "This account is already activated",
  },
}