import { fa } from 'vuetify/lib/locale'

export default {
  ...fa,
  categories: "دسته\u200cبندی",
  people: "اینفلوئنسرها",
  next: "بعدی",
  back: "قبلی",
  login: {
    title: "ورود",
    username_or_email: "نام کاربری یا ایمیل",
    password: "رمز عبور",
    no_account_msg: "حساب کاربری ندارید؟",
    forgot_password_msg: "رمز عبور خود را فراموش کرده\u200cاید؟",
    signup_msg: "ثبت نام کنید"
  },
  reset_password: {
    title: "تغییر رمز عبور",

  },
  signup: {
    title: "ثبت نام",
    username: "نام کاربری",
    email: "ایمیل",
    password: "رمز عبور",
    first_name: "نام",
    last_name: "نام خانوادگی",
    resend_confirmation_title: "لینک فعال سازی حساب به ایمیل شما ارسال شد",
    resend_confirmation_msg: "ایمیلی دریافت نکردید؟",
    resend_confirmation_action: "ارسال مجدد",
  },
  news: {
    lang_tabs: {
      fa: "اخبار فارسی",
      en: "اخبار انگلیسی"
    },
    not_found_msg: "خبری یافت نشد",
    actions: {
      view: "بازدید",
      star: "مهم",
      bull: "سیگنال مثبت",
      bear: "سیگنال منفی",
    },
    sort: {
      title: "مرتب سازی بر اساس",
      items: [{
        sort_index: 3,
        title: "سیگنال مثبت",
      },
      // "Bullish Descending",
      {
        sort_index: 2,
        title: "سیگنال منفی",
      },
      // "Bearish Descending",
      {
        sort_index: 1,
        title: "مهم\u200cترین خبر",
      },
      // "Importance Descending",
      {
        sort_index: 0,
        title: "بازدید",
      },
      {
        sort_index: null,
        title: "آخرین اخبار",
      },
      ],
    },
  },
  search_bar: "جستجو بر اساس عنوان",
  dashboard: {
    main_page: "صفحه اصلی",
    title: "گفتار درمانی",
    form_templates: "فرم\u200cها",
    form_answers: "فرم\u200cها",
    image_gallery: "تصاویر",
    patient_sign_up: "تاریخچه\u200cگیری",
    archive: "آرشیو",
    inbox: "صندوق پیام",
    setting: "تنظیمات",
    logout: "خروج",
    patients_list: "مراجعان",
    healed_patients_list: "مراجعان ترخیص\u200cشده",
    patients_form_answers_list: "فرم مراجعان",
    practices_list: "تمرین\u200cها",
    completed_practices: "تمرین\u200cهای انجام شده",
  },
  auth: {
    login_failed: "نام کاربری یا رمز عبور شما اشتباه است",
    login_succed: "خوش آمدید",
    confirmation_email_expired: "این ایمیل منقضی شده است",
    reset_password_succed: "رمز عبور شما با موفقیت تغییر یافت",
    reset_password_failed: "مشکلی در تغییر رمز عبور شما پیش آمد",
    already_active_msg: "فعال سازی این حساب صورت گرفته است",
  },
}