import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    assets: [],
    fa_sources: [],
    en_sources: [],
    user_info: undefined,
    user_subs_days: 0,
  },
  mutations: {
    changeAssets(state, payload) {
      state.assets = payload
    },
    changeFaSources(state, payload) {
      state.fa_sources = payload
    },
    changeEnSources(state, payload) {
      state.en_sources = payload
    },
    changeUserInfo(state, payload) {
      state.user_info = payload
    },
    changeUserSubsDays(state, payload) {
      state.user_subs_days = payload
    },
    incrementUserSubsDays(state, payload) {
      state.user_subs_days += payload
    },
    decrementUserSubsDays(state, payload) {
      state.user_subs_days -= payload
    },
  },
  actions: {},
  modules: {}
})
