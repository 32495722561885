/* eslint-disable no-console */

const BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  data() {
    return {
      user_api: {
        login: BASE_URL + "/v1/user/login/",
        auth_login: BASE_URL + "/v1/user/auth/", // new
        is_signed_in: BASE_URL + "/v1/user/login/status/",
        session: BASE_URL + "/v1/user/session/",
        csrf: BASE_URL + "/v1/user/csrf/",
        logout: BASE_URL + "/v1/user/logout/",
        signup: BASE_URL + "/v1/user/signup/",
        signup_confirmation: BASE_URL + "/v1/user/signup/confirm/",
        signup_confirmation_resend: BASE_URL + "/v1/user/signup/confirm/resend/",
        user_info: BASE_URL + "/v1/user/info/",
        password_reset: BASE_URL + "/v1/user/password/reset/",
        user_favorite_filters: BASE_URL + "/v1/user/favorites/side_filters/", // new
        user_favorite_news: BASE_URL + "/v1/user/favorites/news/", // new
      },
      api: {
        base_url: BASE_URL,
        news_list: BASE_URL + "/v1/news/",
        news_info: BASE_URL + "/v1/news/info/",
        news_recent_list: BASE_URL + "/v1/news/recent/",
        news_views_edit: BASE_URL + "/v1/news/views/",
        news_votes: BASE_URL + "/v1/news/votes/",
        news_sort: BASE_URL + "/v1/news/sort/",
        news_filter: BASE_URL + "/v1/news/filter/",
        news_sources: BASE_URL + "/v1/news/sources/"
        // news_pagination
      },
      geo_api: {
        user_location: 'https://api.freegeoip.app/json/?apikey=ed186460-3e3d-11ec-9b5b-31b4c4bace68'
      },
    };
  },
  methods: {
    requestErrorHandler: function () {
      // if (error.response.status === 401) {
      //   this.$router.push("/login");
      // }
    },
  },
};
