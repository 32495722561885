<template>
  <v-card class="mx-auto mb-4 item-pointer" outlined>
    <v-card-actions class="dense-card-actions" @click.stop="show = !show">
      <span v-text="title" class="ms-2"></span>
      <v-tooltip v-model="tooltip_show" :left="$vuetify.rtl" :right="!$vuetify.rtl">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon size="17" color="grey lighten-1" :class="{ 'persian-question-mark': $vuetify.rtl }">
              mdi-help-circle-outline
            </v-icon>
          </v-btn>
        </template>
        <span v-html="$vuetify.rtl ? limit_choice_msg_fa : limit_choice_msg_en"></span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-btn icon @click.stop="show = !show">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>

    <div class="pa-0" v-if="selected.length !== 0">
      <v-divider class="mx-4"></v-divider>
      <v-card-subtitle class="py-2">
        <v-chip-group column>
          <template v-for="item in subtitleSelectedItems">
            <v-chip pill small :key="`subtitle-chip-${item}`">
              {{ item }}
            </v-chip>
          </template>
        </v-chip-group>
      </v-card-subtitle>
    </div>

    <v-expand-transition>
      <div v-show="show">
        <v-divider class="mx-2 mb-2"></v-divider>
        <v-text-field class="mx-2" hide-details dense outlined v-model="search" prepend-inner-icon="mdi-magnify"
          :placeholder="$vuetify.rtl ? 'جستجوی منبع خبر' : 'Search source...'" color="#20af9c">
        </v-text-field>
        <v-divider class="mx-2 mt-2"></v-divider>
        <!-- <v-card-text> -->
        <v-virtual-scroll :items="filteredItems" :item-height="45" height="300" v-if="filteredItems.length != 0">
          <template v-slot:default="{ item }">
            <v-row :key="item.title" justify-lg="space-around" justify-md="start" justify-sm="start" no-gutters>
              <v-col cols="2" sm="2" md="2" lg="1" xl="2" align-self="center">
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox color="#20af9c" :value="item.id" v-model="selected">
                    </v-checkbox>
                  </v-list-item-action>
                </v-list-item>
              </v-col>
              <v-col cols="10" sm="10" md="10" lg="10" align-self="center">
                <v-list-item @click.prevent="addToSelected(item.id)">
                  <v-list-item-action-text>
                    <span v-text="item.title" style="font-size: 0.875rem"></span>
                  </v-list-item-action-text>
                  <v-spacer></v-spacer>
                  <v-list-item-avatar style="background-color: white" size="32">
                    <v-img contain height="22" width="22" :src="item.img" :alt="item.title" :title="item.title"></v-img>
                  </v-list-item-avatar>
                </v-list-item>
              </v-col>
            </v-row>
          </template>
        </v-virtual-scroll>
        <v-list dense v-if="filteredItems.length === 0">
          <v-list-item>
            <v-list-item-title>
              <span v-text="$vuetify.rtl
                  ? 'موردی یافت نشد'
                  : 'yeah, there was nothing to show...'
                " style="font-size: 0.785rem; font-weight: 580"></span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
// import coins from "../mixins/coins";

export default {
  props: ["title", "items"],
  // mixins: [coins],
  data: () => ({
    show: false,
    tooltip_show: false,
    limit: 1,
    limit_choice_msg_fa: `<span>
    انتخاب نامحدود تنها پس از ثبت نام مقدور است
    </span><br/><span style="font-family: IRanSans">
    محدودیت انتخاب: 1 منبع
    </span>`,
    limit_choice_msg_en:
      "<span> Unlimited access after signing up </span><br/><span> Limit is 1 source</span>",
    search: "",
    selected: [],
  }),
  methods: {
    getSelectedList: function () {
      return this.selected;
    },
    addToSelected: function (title) {
      let index = this.selected.findIndex((el) => el === title);
      if (index === -1) {
        this.selected.push(title);
      } else {
        this.selected.splice(index, 1);
      }
      // this.selected = [...new Set(this.selected)];
    },
    preprocessSelectedList: function (list) {
      this.selected = list;
    },
    getIcon: function () {
      return "coin";
    },
    getImgUrl(item) {
      try {
        let images = require.context("@/assets/news_logo/EN/", false, /\.svg$/);
        return images(
          "./" + item.title.replace(/\s/g, "").toLowerCase() + ".svg"
        );
      } catch (e) {
        try {
          let images = require.context(
            "@/assets/news_logo/FA/",
            false,
            /\.svg$/
          );
          return images("./" + item.en.toLowerCase() + ".svg");
        } catch (e) {
          console.log("");
        }
      }
    },
  },
  computed: {
    itemsLength() {
      return this.items.length;
    },
    filteredItems() {
      return this.items.filter((el) => {
        return el.title.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    subtitleSelectedItems() {
      return this.items.filter(el => this.selected.includes(el.id)).map((el) => { return el.title })
    }
  },
};
</script>

<style lang="scss" scoped>
$scrollbar-color: #20af9c;

.persian-question-mark {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.dense-card-actions {
  padding: 6px;
}

.custom-overflow {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
}

.item-pointer {
  cursor: pointer;
}

* {
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-color white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;
  border-radius: 25px;
}
</style>
