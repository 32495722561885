<template>
  <v-container class="fill-height">
    <v-row class="justify-center align-center" v-if="!is_link_expired">
      <v-progress-circular indeterminate :size="$vuetify.rtl ? '40' : '50'"
        :color="$vuetify.theme.dark ? 'green accent-2' : 'green darken-2'" class="me-6" v-if="is_loading">
      </v-progress-circular>
      {{
        $vuetify.rtl
        ? "چند لحظه صبر کنید"
        : "Please wait while your account is being confirmed"
      }}
    </v-row>
    <v-row class="justify-center align-center" v-else>
      {{
        $vuetify.rtl
        ? "در حال انتقال به صفحه\u200cی اصلی"
        : "ًRedirecting to home page"
      }}
    </v-row>
    <!-- <v-overlay :value="is_loading">
      <v-progress-circular
        indeterminate
        color="red accent-2"
      ></v-progress-circular>
    </v-overlay> -->
    <message-show ref="msg" />
  </v-container>
</template>

<script>
import APIMixin from "../../../mixins/apiMixin";
import AuthMixin from "../../../mixins/authMixin";
import styleMixin from "../../../mixins/styleMixin";
import utils from "../../../mixins/utils";
import localeMixin from "../../../mixins/localeMixin";
import MessageShow from "../../../components/MessageShow.vue";

//
export default {
  name: "AccountActivationPage",
  components: { MessageShow },
  mixins: [APIMixin, AuthMixin, styleMixin, localeMixin, utils],
  data: () => ({
    is_loading: false,
    is_link_expired: false,
  }),
  methods: {
    goToDashboardPage: function () {
      this.$router.push({ name: "dashboard" });
    },
    goToHomePage: function () {
      if (this.$vuetify.rtl) {
        this.$router.push({ name: "fa-home-fa-news" });
      } else {
        this.$router.push({ name: "en-home-en-news" });
      }
    },
    confirmUser: function () {
      this.is_loading = true;
      const data = { token: this.$router.currentRoute.params.token };
      this.$axios
        .post(this.user_api.signup_confirmation, data, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.status) {
            this.$refs.msg.showMessage(
              this.$vuetify.rtl
                ? this.localization.fa.auth.login_succed
                : this.localization.en.auth.login_succed,
              "success"
            );
            this.setUserIsLoggedIn(true);
            this.goToDashboardPage();
          } else {
            this.$refs.msg.showMessage(
              this.$vuetify.rtl
                ? this.localization.fa.auth.confirmation_email_expired
                : this.localization.en.auth.confirmation_email_expired,
              "error"
            );
            this.is_link_expired = true;
          }
        })
        .catch(() => {
          this.is_loading = false;
        })
        .finally(() => {
          this.is_loading = false;
          if (this.is_link_expired) {
            this.goToHomePage();
          }
        });
    },
  },
  created() {
    if (this.getLocale() == "en") {
      this.$vuetify.rtl = false;
    } else {
      this.$vuetify.rtl = true;
    }
    // send confirmation to backend
    this.confirmUser();
  },
};
</script>

<style scoped>
.no-letter-spacing {
  letter-spacing: 0;
}
</style>