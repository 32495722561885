<template>
  <div class="marquee" v-if="$vuetify.rtl">
    <div class="marquee__inner" aria-hidden="true" :dir="'ltr'" ref="priceBoard">
      <span v-for="(coin, i) in live_broadcast" :key="i" class="mx-2 d-flex">
        <span class="mx-1 text-uppercase" :class="{
          'coin-symbol-light': !$vuetify.theme.dark,
          'coin-symbol-dark': $vuetify.theme.dark,
        }">
          {{ coin.symbol }}
        </span>
        <span class="mx-1 coin-price" :class="{
          'price-wrapper-positive': coin.price_change_percentage_24h > 0,
          'price-wrapper-negative': coin.price_change_percentage_24h < 0,
        }">
          {{ `$${coin.current_price}` }}
        </span>
        <span class="mx-2 coin-price" :class="{
          'price-wrapper-positive': coin.price_change_percentage_24h > 0,
          'price-wrapper-negative': coin.price_change_percentage_24h < 0,
        }">
          {{ `${coin.price_change_percentage_24h.toFixed(1)}%` }}
        </span>
      </span>
    </div>
  </div>
  <div class="marqueeen" v-else>
    <div class="marqueeen__inner" aria-hidden="true" :dir="'ltr'" ref="priceBoard">
      <span v-for="(coin, i) in live_broadcast" :key="i" class="mx-2 d-flex">
        <span class="mx-1 text-uppercase" :class="{
          'coin-symbol-light': !$vuetify.theme.dark,
          'coin-symbol-dark': $vuetify.theme.dark,
        }">
          {{ coin.symbol }}
        </span>
        <span class="mx-1 coin-price" :class="{
          'price-wrapper-positive': coin.price_change_percentage_24h > 0,
          'price-wrapper-negative': coin.price_change_percentage_24h < 0,
        }">
          {{ `$${coin.current_price}` }}
        </span>
        <span class="mx-2 coin-price" :class="{
          'price-wrapper-positive': coin.price_change_percentage_24h > 0,
          'price-wrapper-negative': coin.price_change_percentage_24h < 0,
        }">
          {{ `${coin.price_change_percentage_24h.toFixed(1)}%` }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: "LiveCryptoPrice",
  data: () => ({
    live_broadcast: [],
  }),
  methods: {
    checkLivePriceApi: function () {
      this.$axios
        .get(
          "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=20&page=1&sparkline=false"
        )
        .then((res) => {
          this.live_broadcast = res.data;
        });
    },
  },
  mounted() {
    this.checkLivePriceApi();
    // this.liveSlideShow();
  },
};
</script>

<style lang="scss" scoped>
$positive-change: #00d964;
$negative-change: #ff0000;

.coin-symbol-dark {
  font-weight: 600;
  font-size: 0.85rem;
  color: white;
}

.coin-symbol-light {
  font-weight: 600;
  font-size: 0.85rem;
  color: black;
}

.coin-price {
  font-weight: 600;
  font-size: 0.85rem;
}

.price-wrapper-positive {
  color: $positive-change;
}

.price-wrapper-negative {
  color: $negative-change;
}

.marquee {
  position: relative;
  overflow: hidden;
  --offset: 10vw;
  --move-initial: calc(100% + var(--offset));
  --move-final: calc(-40% + var(--offset));
}

.marquee__inner {
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 40s linear infinite;
  animation-play-state: running;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

.marqueeen {
  position: relative;
  overflow: hidden;
  --offset: 10vw;
  --move-initial: calc(40% + var(--offset));
  --move-final: calc(-100% + var(--offset));
}

.marqueeen__inner {
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 40s linear infinite;
  animation-play-state: running;
}

@keyframes marqueeen {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

// .marquee-container {
//   display: flex;
//   align-items: center;
//   background: transparent;
//   overflow: hidden;
// }

// .marquee {
//   font-size: 100px;
//   line-height: 1.3;
//   padding: 20px 0;
//   color: #fff;
//   white-space: nowrap;
//   animation: marquee 15s infinite linear; /* notice the infinite */
// }

// // .marquee:after {
// //   content: "   ";
// // }

// @media only screen and (max-width: 500px) {
//   @keyframes marquee {
//     0% {
//       transform: translateX(100%);
//     }
//     100% {
//       transform: translateX(-100%);
//     }
//   }
// }

// @media only screen and (min-width: 501px) {
//   @keyframes marquee {
//     0% {
//       transform: translateX(100%);
//     }
//     100% {
//       transform: translateX(-100%);
//     }
//   }
// }
</style>
