<template>
  <v-container class="fill-height">
    <v-row class="justify-center align-center">
      <v-progress-circular indeterminate :size="$vuetify.rtl ? '40' : '50'"
        :color="$vuetify.theme.dark ? 'green accent-2' : 'green darken-2'" class="me-6"></v-progress-circular>
      {{ $vuetify.rtl ? "چند لحظه صبر کنید" : "Please wait" }}
    </v-row>
  </v-container>
</template>

<script>
import apiMixin from "@/mixins/apiMixin.js";
import authMixin from "@/mixins/authMixin.js";

export default {
  name: "AuthCallback",
  data: () => ({
    res: "",
  }),
  mixins: [apiMixin, authMixin],
  methods: {},
  mounted() {
    this.$axios
      .post(this.user_api.auth_login, this.$router.currentRoute.query)
      .then((res) => {
        this.setUserIsLoggedIn(true);
        this.setUserAuthenticationToken(res.data.data.token);
        this.$router.push("/");
      })
      .catch((e) => {
        // this.logout();
        if (e.response.status === 400) {
          this.is_loading = false;
          this.$refs.MessageShow.showMessage(
            this.localization.fa.auth.login_failed,
            "error"
          );
        }
      })
      .finally(() => {
        this.is_loading = false;
      });
  },
};
</script>

<style></style>
