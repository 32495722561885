<template>
  <v-container
    fill-height
    v-if="$vuetify.rtl && $router.currentRoute.name.split('-')[0] === 'fa'"
  >
    <v-row class="ma-2 justify-center align-start">
      <v-card
        max-width="1000"
        outlined
        :class="{
          'custom-card-border-light': !$vuetify.theme.dark,
          'custom-card-border-dark': $vuetify.theme.dark,
        }"
      >
        <v-card-title class="about-title">{{ title.fa }}</v-card-title>
        <v-card-text class="about-text text-justify">
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"
                ><strong>چرا در زاگرس لایو تبلیغ کنیم؟</strong></span
              ></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >برند و پیام&zwnj;های شما روزانه توسط معامله&zwnj;گران ارزهای
              دیجیتال، فعالان و اینفلوئنسرهایی دیده می&zwnj;شود که&nbsp;</span
            >Zagros Live
            <span lang="fa-IR"
              >را مطالعه می&zwnj;کنند، تبلیغات در زاگرس لایو قابل تامل است و
              هرگز آزاردهنده نیست</span
            >.
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"><strong>تبلیغات بنری</strong></span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR"
              >اولین گزینه ما برای تبلیغ‌کنندگان، بنرهایی است که به طور مداوم در
              نوارهای سایت ظاهر می‌شوند. هزینه ماهانه برای این تبلیغات بسته به
              موقعیت نمایش متفاوت است. برای اطلاع از قیمت یا سایر سوالات، با
              نشانی </span
            ><u
              ><a href="mailto:advertise@zagros.live"
                >advertise@zagros.live</a
              ></u
            >
            <span lang="fa-IR"> در تماس باشید.</span>
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="ar-SA"
              ><span lang="fa-IR"><strong>تبلیغات متنی</strong></span></span
            >
          </p>
          <p
            data-darkreader-inline-bgcolor=""
            data-darkreader-inline-bgimage=""
            dir="rtl"
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
              --darkreader-inline-bgimage: initial;
              --darkreader-inline-bgcolor: transparent;
            "
          >
            <span lang="fa-IR">
              دومین پیشنهاد ما برای تبلیغ‌کنندگان، درج پیام‌ تبلیغاتی کوتاه در
              بخش نمایش اخبار است که تبلیغ‌کننده می‌خواهد مخاطبان خود را بصورت
              دقیق مورد هدف قرار دهد. برای کسب اطلاعات بیشتر با نشانی </span
            ><u
              ><a href="mailto:advertise@zagros.live">
                advertise@zagros.live
              </a></u
            >
            <span lang="fa-IR"> در تماس باشید.</span>
          </p>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
  <v-container fill-height v-else>
    <v-row class="ma-2 justify-center align-start">
      <v-card
        max-width="1000"
        outlined
        :class="{
          'custom-card-border-light': !$vuetify.theme.dark,
          'custom-card-border-dark': $vuetify.theme.dark,
        }"
      >
        <v-card-title class="about-title">{{ title.en }}</v-card-title>
        <v-card-text class="about-text text-justify">
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 178%;
            "
          >
            <strong> Why advertise on Zagros Live? </strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 178%;
            "
          >
            Your brand and messages will be seen daily by cryptocurrency
            traders, activists, and influencers who read Zagros Live. Ads on
            Zagros Live are thought-provoking, and never annoying.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 178%;
            "
          >
            <strong>Sponsor Banners</strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 178%;
            "
          >
            Our first option for advertisers has branded banners that appear
            continuously on the site bars. This is the perfect opportunity for
            companies that have invested in public users. The monthly cost for
            this placement ranges depending on the position.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 128%;
            "
          >
            For pricing or other inquiries, email
            <u
              ><a href="mailto:advertise@zagros.live"
                >advertise@zagros.live</a
              ></u
            >
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 188%;
            "
          >
            <strong>Contextual Ads</strong>
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 178%;
            "
          >
            Our second offering for advertisers is the insertion of short ad
            messages immediately under the special news box (specific coins or
            news sources) that an advertiser wants to target their audiences.
          </p>
          <p
            style="
              text-align: justify;
              background: transparent;
              margin-bottom: 0.11in;
              line-height: 178%;
            "
          >
            For more information, email
            <u
              ><a href="mailto:advertise@zagros.live"
                >advertise@zagros.live</a
              ></u
            >
          </p>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$vuetify.rtl ? "تبلیغات" : "Advertisement",
      meta: [
        {
          name: "twitter:image:src",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          name: "twitter:site",
          content: "Zagros Live",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:title",
          content: this.$vuetify.rtl ? "تبلیغات" : "Advertisement",
        },
        {
          name: "twitter:description",
          content: "Advertise with Zagros",
        },
        {
          property: "og:image",
          content: "https://ucarecdn.com/35ace230-0053-47d6-aa47-1495e24648af/",
        },
        {
          property: "og:site_name",
          content: "Zagros Live",
        },
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "og:title",
          content: this.$vuetify.rtl ? "تبلیغات" : "Advertisement",
        },
        { property: "og:site_name", content: "Zagros Live" },
        {
          property: "og:description",
          content: "Advertise with Zagros",
        },
        {
          property: "og:url",
          content: "https://zagros.live" + this.$router.currentRoute.path,
        },
      ],
    };
  },
  name: "advertise",
  data: () => ({
    title: {
      fa: "تبلیغات",
      en: "Advertisement",
    },
  }),
  methods: {
    isLangFa: function () {
      if (this.$router.currentRoute.name.split("-")[0] === "fa") {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
$primary-light-color: #146a5f;
$primary-dark-color: #404548;

.about-text {
  line-height: 1.8;
  font-size: 1.1rem;
}

.about-title {
  font-size: 1.8rem;
}

.custom-card-border-light {
  border-color: $primary-light-color;
}

.custom-card-border-dark {
  border-color: $primary-dark-color;
}

p {
  font-size: 0.95rem;
}
</style>
