<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown &&
    $vuetify.breakpoint.mdAndUp
    ">
    <v-row class="justify-space-around align-start" align-content="center">
      <v-col order="3" lg="3" xl="2" class="hidden-md-and-down" style="margin-top: 2.9px">
        <!-- <side-commercial
          :locale="$vuetify.rtl ? 'fa' : 'en'"
          :page="1"
          :max_ads="2"
        ></side-commercial> -->
        <categories @apply-filter="handleCategoriesFilter">
        </categories>
      </v-col>
      <v-col order="2" cols="12" sm="12" md="9" lg="6" xl="7" class="mt-1">
        <news-list v-on:handle-max-page="changeMaxPageLimit" :locale="$vuetify.rtl ? 'fa' : 'en'" ref="newsList" />
      </v-col>
      <v-col order="1" md="3" lg="3" xl="2" class="hidden-sm-and-down" style="margin-top: 2.9px">
        <side-filter-box :locale="$vuetify.rtl ? 'fa' : 'en'" @apply-filter="handleFilter"
          @apply-categories-filter="handleCategoriesFilter"></side-filter-box>
      </v-col>
    </v-row>
    <div class="text-center localized-pagination">
      <v-pagination v-model="page" :length="max_page" :total-visible="7" color="primary" :class="{
          'pagination-fa': $vuetify.rtl,
          'pagination-en': !$vuetify.rtl,
        }" prev-icon="mdi-menu-left" next-icon="mdi-menu-right"></v-pagination>
    </div>
  </v-container>
</template>

<script>
// const SideCommercial = () => import("./commercial/SideCommercial.vue");
// import HeaderCommercial from "./commercial/HeaderCommercial.vue";
import NewsList from "./NewsList.vue";
// import apiMixin from "../mixins/apiMixin";
import styleMixin from "../mixins/styleMixin";
import utils from "../mixins/utils";
const Categories = () => import("../components/Categories.vue");
const SideFilterBox = () => import("../components/SideFilterBox.vue");

export default {
  // props: ["locale"],
  name: "Home",
  mixins: [styleMixin, utils],
  components: {
    NewsList,
    // HeaderCommercial,
    // SideCommercial,
    SideFilterBox,
    Categories,
  },
  data: () => ({
    page: 1,
    locale: "fa",
    news_locale_tab: "fa",
    max_page: 1,
  }),
  methods: {
    handleCategoriesFilter: function () {
      this.$refs.newsList.getFilteredNews();
    },
    handleFilter: function (val) {
      this.$refs.newsList.getNewsSideFilter(val[0], val[1], val[2], val[3]);
    },
    changeMaxPageLimit: function (max_page_val, page_val) {
      this.max_page = max_page_val;
      if (page_val !== undefined) {
        this.page = page_val;
      } else {
        this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
      }
    },
    getPaginatedNews: function (val) {
      if (this.$route.query.page !== val) {
        let query = Object.assign({}, this.$route.query)
        query.page = val
        if (val >= 2 && query.news_share_id) {
          delete query.news_share_id
        }
        this.$router.replace({
          query: query,
        });
      }

      //
      this.$refs.newsList.changePage(val);
      this.$refs.newsList.getFilteredNews();
      window.scrollTo(0, 0);
    },
    handleRoute: function () {
      // router setups
      const current_route = this.$route.path.split("/").slice(1, 4);
      const current_route_query = this.$route.query;

      if (Object.prototype.hasOwnProperty.call(this.locale_to_btnlang, current_route[0])) {
        // first check if we have slug
        if (current_route_query.news_share_id) {
          this.$refs.newsList.getSlugNews(current_route_query.news_share_id);
        }
        this.setLocale(current_route[0]);
        this.$vuetify.rtl = current_route[0] == "fa" ? true : false;
        // if lang is in 
        // supported languages
        if (Object.prototype.hasOwnProperty.call(this.locale_to_btnlang, current_route_query.news_locale)) {
          this.$refs.newsList.changeBtnLangModel(this.locale_to_btnlang[current_route_query.news_locale]);
        } else if (Object.values(this.locale_to_btnlang).indexOf(parseInt(this.getNewsLocale())) > -1) {
          this.$refs.newsList.changeBtnLangModel(parseInt(this.getNewsLocale()));
        }
        else {
          this.$router.push({ name: "404" });
        }
      } else {
        this.$router.push({ name: "404" });
      }
      // scroll to top
      window.scrollTo(0, 0);
      //
    },
  },
  watch: {
    page(val) {
      if (parseInt(this.$route.query.page) !== val) this.getPaginatedNews(val);
    },
  },
  mounted() {
    if (isNaN(this.$route.query.page)) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          page: 1
        }
      })
    }
    if (this.$route.query.page) this.page = parseInt(this.$route.query.page);
    this.handleRoute();
  },
  created() {
    if (this.$router.currentRoute.path.split("/")[0] === "fa") {
      this.locale = "fa";
      this.$vuetify.rtl = true;
    } else {
      this.locale = "en";
      this.$vuetify.rtl = false;
    }
  },
  beforeDestroy: function () {
    // new
    localStorage.setItem("filter_init", false);
  },
};
</script>


<style scoped>
.pagination-fa {
  font-family: IRANSans;
}

.pagination-en {
  direction: ltr;
}
</style>
