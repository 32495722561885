var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$vuetify.rtl)?_c('div',{staticClass:"marquee"},[_c('div',{ref:"priceBoard",staticClass:"marquee__inner",attrs:{"aria-hidden":"true","dir":'ltr'}},_vm._l((_vm.live_broadcast),function(coin,i){return _c('span',{key:i,staticClass:"mx-2 d-flex"},[_c('span',{staticClass:"mx-1 text-uppercase",class:{
        'coin-symbol-light': !_vm.$vuetify.theme.dark,
        'coin-symbol-dark': _vm.$vuetify.theme.dark,
      }},[_vm._v(" "+_vm._s(coin.symbol)+" ")]),_c('span',{staticClass:"mx-1 coin-price",class:{
        'price-wrapper-positive': coin.price_change_percentage_24h > 0,
        'price-wrapper-negative': coin.price_change_percentage_24h < 0,
      }},[_vm._v(" "+_vm._s(("$" + (coin.current_price)))+" ")]),_c('span',{staticClass:"mx-2 coin-price",class:{
        'price-wrapper-positive': coin.price_change_percentage_24h > 0,
        'price-wrapper-negative': coin.price_change_percentage_24h < 0,
      }},[_vm._v(" "+_vm._s(((coin.price_change_percentage_24h.toFixed(1)) + "%"))+" ")])])}),0)]):_c('div',{staticClass:"marqueeen"},[_c('div',{ref:"priceBoard",staticClass:"marqueeen__inner",attrs:{"aria-hidden":"true","dir":'ltr'}},_vm._l((_vm.live_broadcast),function(coin,i){return _c('span',{key:i,staticClass:"mx-2 d-flex"},[_c('span',{staticClass:"mx-1 text-uppercase",class:{
        'coin-symbol-light': !_vm.$vuetify.theme.dark,
        'coin-symbol-dark': _vm.$vuetify.theme.dark,
      }},[_vm._v(" "+_vm._s(coin.symbol)+" ")]),_c('span',{staticClass:"mx-1 coin-price",class:{
        'price-wrapper-positive': coin.price_change_percentage_24h > 0,
        'price-wrapper-negative': coin.price_change_percentage_24h < 0,
      }},[_vm._v(" "+_vm._s(("$" + (coin.current_price)))+" ")]),_c('span',{staticClass:"mx-2 coin-price",class:{
        'price-wrapper-positive': coin.price_change_percentage_24h > 0,
        'price-wrapper-negative': coin.price_change_percentage_24h < 0,
      }},[_vm._v(" "+_vm._s(((coin.price_change_percentage_24h.toFixed(1)) + "%"))+" ")])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }