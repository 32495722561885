/* eslint-disable no-console */
// const FA_NEWS_LOGO_BASE_URL = '@/assets/news_logo/FA/';
// const EN_NEWS_LOGO_BASE_URL = '@/assets/news_logo/EN/';

import en from "../locale/en"
import fa from "../locale/fa"

export default {
  data() {
    return {
      localization: {
        fa: {
          ...fa,
        },
        en: {
          ...en,
        },
      },

    };
  },
  methods: {
    getImageOrDefault(img) {
      return img ? img : "./default_user.png";
    },
    getPatientLoginBackgroundImage() {
      var background_image_list = ["./back.jpg", "./back2.jpg"];

      return background_image_list[
        Math.floor(Math.random() * background_image_list.length)
      ];
    },
  },
};
