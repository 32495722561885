import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import SignUpPage from "../views/user/signup/SignUpPage.vue";
import AuthCallback from "../views/user/auth/AuthCallback.vue";
import Dashboard from "../views/user/Dashboard.vue";
import AccountActivationPage from "../views/user/signup/AccountActivationPage.vue";
import SignUpConfirmationPage from "../views/user/signup/SignUpConfirmationPage.vue"
import ResetPasswordPage from '../views/user/ResetPasswordPage.vue'
import About from "../views/footer/About.vue";
import Contact from "../views/footer/Contact.vue";
import Advertise from "../views/footer/Advertise.vue";
import Help from "../views/footer/Help.vue";
import PrivacyPolicy from "../views/footer/PrivacyPolicy.vue";
import TermsofService from "../views/footer/TermsofService.vue";
import Error404 from "../views/Error404.vue";
import utilsMixin from "../mixins/utils";


Vue.use(VueRouter);


const routes = [
  // auth callback
  {
    path: "/auth/callback",
    name: "auth-callback",
    component: AuthCallback,
  },
  {
    path: "/category/:category/",
    name: "searched-category",
    redirect: to => {
      return {
        // path: utilsMixin.methods.getLocale() === "fa" ? '/fa/category/:category/' : '/en/category/:category/',
        name: utilsMixin.methods.getLocale() === "fa" ? 'fa-home-category' : 'en-home-category',
        params: to.params,
        query: {
          ...{
            news_locale: utilsMixin.methods.getNewsLocale() === '1' ? 'fa' : 'en'
          },
          ...to.query
        }
      }
    }
  },
  {
    path: "/influencers/:people/",
    name: "searched-influencer",
    redirect: to => {
      return {
        // path: utilsMixin.methods.getLocale() === "fa" ? '/fa/category/:category/' : '/en/category/:category/',
        name: utilsMixin.methods.getLocale() === "fa" ? 'fa-home-influencers' : 'en-home-influencers',
        params: {
          category: to.params.people
        },
        query: {
          ...{
            news_locale: utilsMixin.methods.getNewsLocale() === '1' ? 'fa' : 'en'
          },
          ...to.query
        }
      }
    }
  },
  // fa locale routes
  {
    path: "/fa",
    name: "fa-home",
    component: Home,
    children: [{
      path: 'results',
      name: "fa-home-results",
      component: Home,
    },
    {
      path: 'category/:category',
      name: "fa-home-category",
      component: Home,
      children: [{
        path: 'results',
        name: "fa-home-category-results",
        component: Home,
      },]
    },
    {
      path: 'influencers/:category',
      name: "fa-home-influencers",
      component: Home,
      children: [{
        path: 'results',
        name: "fa-home-influencers-results",
        component: Home,
      },]
    },
    ],
  },
  {
    path: "/fa/about/",
    name: "fa-about",
    component: About,
  }, {
    path: "/fa/contact/",
    name: "fa-contact",
    component: Contact,
  }, {
    path: "/fa/advertise/",
    name: "fa-advertise",
    component: Advertise,
  }, {
    path: "/fa/help/",
    name: "fa-help",
    component: Help,
  }, {
    path: "/fa/privacy-policy/",
    name: "fa-policy",
    component: PrivacyPolicy,
  }, {
    path: "/fa/terms/",
    name: "fa-terms",
    component: TermsofService,
  },
  {
    path: "/en/about/",
    name: "en-about",
    component: About,
  }, {
    path: "/en/contact/",
    name: "en-contact",
    component: Contact,
  }, {
    path: "/en/advertise/",
    name: "en-advertise",
    component: Advertise,
  }, {
    path: "/en/help/",
    name: "en-help",
    component: Help,
  }, {
    path: "/en/privacy-policy/",
    name: "en-policy",
    component: PrivacyPolicy,
  }, {
    path: "/en/terms/",
    name: "en-terms",
    component: TermsofService,
  },
  // en locale routes
  {
    path: "/en",
    name: "en-home",
    component: Home,
    children: [{
      path: 'results',
      name: "en-home-results",
      component: Home,
    }, {
      path: 'category/:category',
      name: "en-home-category",
      component: Home,
      children: [{
        path: 'results',
        name: "en-home-category-results",
        component: Home,
      },]
    }, {
      path: 'influencers/:category',
      name: "en-home-influencers",
      component: Home,
      children: [{
        path: 'results',
        name: "en-home-influencers-results",
        component: Home,
      },]
    },],
  },

  {
    path: "/",
    redirect: () => {
      let locale = utilsMixin.methods.getLocale();
      let news_locale = utilsMixin.methods.getNewsLocale();
      if (locale === "en") {
        utilsMixin.methods.setNewsLocale("2");
        news_locale = "2";
      }
      return {
        name: locale === "fa" ? "fa-home" : "en-home",
        query: {
          news_locale: news_locale === '1' ? 'fa' : 'en'
        }
      }
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/user/login",
    name: "login",
    component: Login,
    children: [{
      path: '?redirected_email=:redirect_email?',
      name: 'login_after_reset_password'
    }]
  },
  {
    path: "/user/signup",
    name: "signup",
    component: SignUpPage,
  },
  {
    path: "/user/signup/confirmation",
    name: "signup_confirmation",
    component: SignUpConfirmationPage
  }, {
    path: "/user/activation/:token",
    name: "account_activation",
    component: AccountActivationPage,
  },
  {
    path: "/user/password/reset/",
    name: "reset_password",
    component: ResetPasswordPage,
  },
  {
    path: "*",
    name: "404",
    component: Error404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
