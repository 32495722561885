<template>
  <v-app id="app">
    <v-app-bar app color="primary" dark dir="rtl">
      <div class="d-flex align-center">

        <v-btn icon @click.prevent="goToUserPage" v-if="
          $router.currentRoute.name !== 'dashboard'
        ">
          <v-icon> mdi-account-circle </v-icon>
        </v-btn>
        <v-btn icon @click.prevent="goToUserPage" v-else-if="
          $router.currentRoute.name === 'dashboard'
        ">
          <v-icon v-if="$vuetify.breakpoint.mdAndDown">
            mdi-logout-variant
          </v-icon>
          <v-icon v-else> mdi-account-circle </v-icon>
        </v-btn>
        <!-- <v-menu offset-y v-else-if="
          $store.state.user_info !== undefined &&
          $router.currentRoute.name !== 'dashboard'
        ">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs">
              <v-icon> mdi-account-circle </v-icon>
            </v-btn>
          </template>
          <v-card tile flat class="text-center mx-auto" min-width="254" max-width="300">
            <v-card-text>
              <v-avatar size="64" class="mb-5">
                <v-img :src="this.$store.state.user_info.profile_image" :alt="this.$store.state.user_info.username"
                  v-if="this.$store.state.user_info.profile_image">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="indigo accent-1"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-img v-else src="https://static.zagros.live/images/zagrosdefaultprofile.svg">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="indigo accent-1"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
              <br />
              <v-chip outlined dir="ltr" v-text="usernamePromoter"> </v-chip>
              <v-btn block depressed dark class="my-4" :color="style.primary"
                :class="{ 'no-letter-spacing': $vuetify.rtl }" @click.prevent="goToUserPage">
                {{ $vuetify.rtl ? "پروفایل" : "Profile" }}
              </v-btn>
              <v-btn block depressed dark class="mb-1 mt-5" color="red darken-4"
                :class="{ 'no-letter-spacing': $vuetify.rtl }" @click.prevent="logoutGracefully()">
                {{ $vuetify.rtl ? "خروج" : "Logout" }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-menu> -->
        <!--  -->
        <v-btn icon v-if="$vuetify.breakpoint.smAndDown && $route.name.includes('home')"
          @click.stop="mobile_drawer = !mobile_drawer">
          <v-icon>
            mdi-filter
          </v-icon>
        </v-btn>
        <v-dialog fullscreen v-model="mobile_drawer" v-if="$vuetify.breakpoint.mdAndDown">
          <v-card flat :dir="locale === 'fa' ? 'rtl' : 'ltr'">
            <v-sheet>
              <v-card-title v-if="locale === 'fa'">
                <v-btn icon class="ml-4" @click.stop="mobile_drawer = !mobile_drawer">
                  <v-icon> mdi-arrow-right </v-icon>
                </v-btn>
                <span class="filters-header-fa" v-text="'فیلترها'"> </span>
              </v-card-title>
              <v-card-title v-else>
                <v-btn icon class="mr-4" @click.stop="mobile_drawer = !mobile_drawer">
                  <v-icon> mdi-arrow-left </v-icon>
                </v-btn>
                <span class="filters-header-en" v-text="'Filters'"> </span>
              </v-card-title>
            </v-sheet>
            <side-filter-box :locale="locale" @apply-filter="handleFilter"
              @apply-categories-filter="handleCategoriesFilter"></side-filter-box>
          </v-card>
        </v-dialog>
        <!--  -->
        <v-btn icon @click.stop="changeThemeBrightness">
          <v-icon> mdi-brightness-4 </v-icon>
        </v-btn>
        <template>
          <div class="text-center">
            <v-menu bottom offset-y auto transition="slide-y-transition">
              <template v-slot:activator="{ on }">
                <v-btn icon dark v-on="on">
                  <v-icon>mdi-web</v-icon>
                </v-btn>
              </template>
              <v-list nav dense>
                <v-list-item-group v-model="selectedLang" color="primary" mandatory>
                  <v-list-item v-for="(item, i) in locales" :key="i" @click.prevent="changeLocale(item)">

                    <v-list-item-content>
                      <v-list-item-title v-text="item.title" :dir="item.is_rtl ? 'rtl' : 'ltr'">
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
        </template>
      </div>

      <v-spacer></v-spacer>
      <a href="https://zagros.live/">
        <div class="d-flex align-center clickable">
          <v-img alt="Logo" class="shrink" contain src="https://static.zagros.live/images/logos/zagros_logo.svg"
            transition="scale-transition" width="50" height="50" />
        </div>
      </a>
    </v-app-bar>
    <v-main :style="`background-color: ${$vuetify.theme.dark ? style.main.bg_color.dark : style.main.bg_color.light
      }`">
      <router-view ref="home" />
    </v-main>
    <!--  -->
    <v-footer dark padless class="mt-4 mt-lg-2">
      <v-card class="flex white--text" flat tile color="primary">
        <v-card-text class="white--text py-0 px-6 px-xl-8">
          <v-row justify-md="space-between" justify-sm="space-between" justify="center" align="center" no-gutters>
            <div class="ms-md-n5 ms-xl-n3">
              <v-btn v-for="link in links" :key="$vuetify.rtl ? link.fa : link.en" color="white" text
                class="mx-1 py-0 px-2 mx-sm-0" :class="{
                  'no-letter-spacing': $vuetify.rtl,
                }" :style="`font-size: ${$vuetify.rtl ? '0.9' : '0.67'}rem`" :href="
  $vuetify.rtl
    ? `${base_url}/${link.route.fa}`
    : `${base_url}/${link.route.en}`
">
                {{ $vuetify.rtl ? link.fa : link.en }}
              </v-btn>
            </div>
            <div class="py-2 mb-2 py-md-0 mb-sm-0" v-html="
              `<span style='color: white;'>Powered by </span><strong style='color: white;'>Zagros Live
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          `
            "></div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import apiMixin from "./mixins/apiMixin";
import authMixin from "./mixins/authMixin";
import styleMixin from "./mixins/styleMixin";
import utils from "./mixins/utils";
import SideFilterBox from "./components/SideFilterBox.vue";

export default {
  name: "App",
  metaInfo() {
    return {
      title: this.$vuetify.rtl ? "زاگرس لایو" : "Zagros Live",
      meta: [
        {
          name: "description",
          content: this.$vuetify.rtl
            ? "زاگرس لایو پایگاه خبری ارزهای دیجیتال"
            : "Zagros Live The Cryptocurrency news aggregator",
        },
      ],
    };
  },
  data: () => ({
    //
    // user_search: "",
    selectedLang: "fa",
    base_url: "",
    locale: "fa",
    mobile_drawer: false,
    mini: true,
    filters_header: `<v-card-actions>
      <v-icon> mdi-arrow-right </v-icon>
      <span v-text="'فیلترها'"> </span>
    </v-card-actions>`,
    links: [
      // new
      { id: 0, route: { fa: "", en: "" }, fa: "خانه", en: "Home" }, // new
      {
        id: 1,
        route: { fa: "fa/about/", en: "en/about/" },
        fa: "درباره ما",
        en: "About",
      },
      {
        id: 2,
        route: { fa: "fa/contact/", en: "en/contact/" },
        fa: "تماس با ما",
        en: "Contact",
      },
      {
        id: 6,
        route: { fa: "fa/help/", en: "en/help/" },
        fa: "راهنما",
        en: "Help",
      },
      {
        id: 3,
        route: { fa: "fa/privacy-policy/", en: "en/privacy-policy/" },
        fa: "حریم خصوصی",
        en: "Privacy Policy",
      },
      {
        id: 4,
        route: { fa: "fa/terms/", en: "en/terms/" },
        fa: "قوانین و مقررات",
        en: "Terms of Service",
      },
      {
        id: 5,
        route: { fa: "fa/advertise/", en: "en/advertise/" },
        fa: "تبلیغات",
        en: "Advertise",
      },
    ],
  }),
  mixins: [styleMixin, apiMixin, authMixin, utils],
  components: {
    SideFilterBox,
  },
  methods: {
    logoutGracefully: function () {
      this.$store.commit("changeUserInfo", undefined);
      this.$store.commit("changeUserSubsDays", 0);
      this.logout();
    },
    // getUserInfo: function () {
    //   this.is_loading = true;
    //   var user_data = undefined;
    //   this.$axios
    //     .get(this.user_api.user_info, { withCredentials: true })
    //     .then((res) => {
    //       if (res.data.status) {
    //         user_data = res.data.data;
    //       }
    //     })
    //     .catch(() => {
    //       this.is_loading = false;
    //     })
    //     .finally(() => {
    //       this.is_loading = false;
    //       this.$store.commit("changeUserInfo", user_data);
    //       // calculate subscription plans
    //       let subs_plan_data = this.setupSubscriptionPlansCharts(
    //         user_data.subscription_set
    //       );
    //       this.$store.commit("changeUserSubsDays", subs_plan_data[0]);
    //     });
    // },
    handleFilter: function (val) {
      this.mobile_drawer = !this.mobile_drawer;
      this.$refs.home.$refs.newsList.getNewsSideFilter(val[0], val[1], val[2]);
      window.scrollTo(0, 0);
    },
    handleCategoriesFilter: function () {
      this.mobile_drawer = !this.mobile_drawer;
      this.$refs.home.handleCategoriesFilter();
      window.scrollTo(0, 0);

    },
    handleRoute: function (link) {
      if (this.$router.currentRoute.name != link.route && link.id != 0) {
        this.$router.push({
          name: `${this.$vuetify.rtl ? "fa" : "en"}-${link.route}`,
        });
      } else if (
        link.id == 0 &&
        this.$router.currentRoute.name != "fa-home" &&
        this.$router.currentRoute.name != "en-home"
      ) {
        this.goToHomePage();
      }
    },
    goToHomePage: function () {
      if (
        (this.$router.currentRoute.name != "fa-home" ||
          this.$router.currentRoute.name != "en-home") &&
        Object.keys(this.$route.params).length != 0
      ) {
        this.$router.push({
          name: `${this.locale}-home`,
          query: { news_locale: this.getNewsLocale() === "1" ? "fa" : "en" },
        });
        this.$router.go();
      } else if (
        this.$router.currentRoute.name != "fa-home" &&
        this.$router.currentRoute.name != "en-home"
      ) {
        this.$router.push({
          name: `${this.locale}-home`,
          query: { news_locale: this.getNewsLocale() === "1" ? "fa" : "en" },
        });
      }
    },
    goToUserPage: function () {
      if (
        this.$route.name != "login" &&
        this.$route.name != "dashboard"
      ) {
        this.$router.push({ name: "login" });
      } else if (
        this.$route.name === "dashboard" &&
        this.$vuetify.breakpoint.mdAndDown
      ) {
        this.$store.commit("changeUserInfo", undefined);
        this.logout();
      }
    },
    changeThemeBrightness: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.setTheme(this.$vuetify.theme.dark);
    },
    getUserLocation: function () {
      if (this.getLocale() === null) {
        this.$axios.get(this.geo_api.user_location).then((res) => {
          if (res.data.country_code === "IR") {
            this.setLocale("fa");
            this.$vuetify.rtl = true;
          } else {
            this.setLocale("en");
            this.$vuetify.rtl = false;
          }
        });
      }
    },
    changeLocale: function (lang) {
      const prev_lang = document.getElementsByTagName('html')[0].lang
      if (prev_lang !== lang.locale) {
        this.locale = lang.locale;
        this.$vuetify.rtl = lang.is_rtl;
        this.setLocale(lang.locale);
        if (this.$route.name.includes(`${prev_lang}-`))
          this.$router.push({
            name: this.$route.name.replace(prev_lang, lang.locale),
            query: this.$route.query,
            params: this.$route.params,
          });
      }
    },
  },
  computed: {
    usernamePromoter: function () {
      return this.$store.state.user_info.username;
    },
  },
  created() {
    // init filters
    localStorage.setItem("filter_init", false);

    if (!this.hasSession()) {
      // send a request for session
      // then store it in cookies
      this.generateSessionFromServer();
    }

    // check theme
    if (this.getTheme() === "true") {
      this.$vuetify.theme.dark = true;
    }
    // check user location
    this.getUserLocation();
    // set locale base on route
    if (this.$router.currentRoute.path.split("/")[1] === "fa") {
      this.locale = "fa";
      this.$vuetify.rtl = true;
      this.setLocale("fa");
    } else {
      this.locale = "en";
      this.$vuetify.rtl = false;
      this.setLocale("en");
    }
    this.selectedLang = this.locale_to_btnlang[this.locale] - 1;
    // get user info if it is authenticated
    // if (this.isAuthenticated()) {
    //   this.getUserInfo();
    // }
  },
  mounted() {
    this.base_url = process.env.VUE_APP_BASE_URL;
  },
};
</script>

<style lang="css" scoped>
.my-menu-light {
  margin-top: 53px;
  contain: initial;
  overflow: visible;
}

.my-menu-light::before {
  position: absolute;
  content: "";
  top: 0;
  right: 14px;
  transform: translateY(-100%);
  width: 4px;
  height: 13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #fff;
}

.my-menu-dark {
  margin-top: 53px;
  contain: initial;
  overflow: visible;
}

.my-menu-dark::before {
  position: absolute;
  content: "";
  top: 0;
  right: 14px;
  transform: translateY(-100%);
  width: 4px;
  height: 13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #1a1a1a;
}


.filters-header-fa {
  font-size: 1.2rem;
}

.filters-header-en {
  margin-top: 2px;
  font-size: 1.2rem;
}

@font-face {
  font-family: IRANSans;
  src: url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/eot/IRANSansWeb(FaNum).eot");
  src: url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/woff2/IRANSansWeb(FaNum).woff2") format("woff2"),
    url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/woff/IRANSansWeb(FaNum).woff") format("woff"),
    url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/ttf/IRANSansWeb(FaNum).ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: IRANSans;
  src: url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/eot/IRANSansWeb(FaNum)_Bold.eot");
  src: url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/woff2/IRANSansWeb(FaNum)_Bold.woff2") format("woff2"),
    url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/woff/IRANSansWeb(FaNum)_Bold.woff") format("woff"),
    url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/ttf/IRANSansWeb(FaNum)_Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: IRANSans;
  src: url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/eot/IRANSansWeb(FaNum)_Black.eot");
  src: url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/woff2/IRANSansWeb(FaNum)_Black.woff2") format("woff2"),
    url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/woff/IRANSansWeb(FaNum)_Black.woff") format("woff"),
    url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/ttf/IRANSansWeb(FaNum)_Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: IRANSans;
  src: url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/eot/IRANSansWeb(FaNum)_Light.eot");
  src: url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/woff2/IRANSansWeb(FaNum)_Light.woff2") format("woff2"),
    url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/woff/IRANSansWeb(FaNum)_Light.woff") format("woff"),
    url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/ttf/IRANSansWeb(FaNum)_Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: IRANSans;
  src: url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/eot/IRANSansWeb(FaNum)_Medium.eot");
  src: url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/woff2/IRANSansWeb(FaNum)_Medium.woff2") format("woff2"),
    url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/woff/IRANSansWeb(FaNum)_Medium.woff") format("woff"),
    url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/ttf/IRANSansWeb(FaNum)_Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: IRANSans;
  src: url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/eot/IRANSansWeb(FaNum)_UltraLight.eot");
  src: url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/woff2/IRANSansWeb(FaNum)_UltraLight.woff2") format("woff2"),
    url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/woff/IRANSansWeb(FaNum)_UltraLight.woff") format("woff"),
    url("assets/fonts/IRANSans/Farsi_numerals/webFonts/fonts/ttf/IRANSansWeb(FaNum)_UltraLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
}

#app {
  font-family: "Samim", sans-serif;
}

.logo {
  font-weight: 500;
  font-size: 1.25rem;
}

.clickable {
  cursor: pointer;
}

.no-letter-spacing {
  letter-spacing: 0;
}

.footer-en {
  font-size: 0.7rem;
}
</style>
