<template>
    <GoogleAuth locale="fa" theme="outline" key="goauth-fa-light" v-if="$vuetify.rtl && !$vuetify.theme.dark">
    </GoogleAuth>
    <GoogleAuth locale="en" theme="outline" key="goauth-en-light" v-else-if="!$vuetify.rtl && !$vuetify.theme.dark">
    </GoogleAuth>
    <GoogleAuth locale="fa" theme="filled_black" key="goauth-fa-dark" v-else-if="$vuetify.rtl && $vuetify.theme.dark">
    </GoogleAuth>
    <GoogleAuth locale="en" theme="filled_black" key="goauth-en-dark" v-else-if="!$vuetify.rtl && $vuetify.theme.dark">
    </GoogleAuth>
</template>

<script>
import GoogleAuth from './GoogleAuth.vue';

export default {
    name: "google_authentication_wrapper",
    components: { GoogleAuth }
}
</script>