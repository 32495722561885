var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height"},[_c('v-layout',{attrs:{"row":"","align-center":"","justify-space-around":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":"","lg6":"","xl4":""}},[_c('v-card',{attrs:{"flat":"","tile":"","color":_vm.$vuetify.theme.dark ? _vm.style.main.bg_color.dark : _vm.style.main.bg_color.light}},[_c('h1',{staticClass:"my-6",style:(("color: " + (_vm.$vuetify.theme.dark
? _vm.style.login.primary_dark
: _vm.style.login.primary) + ";\n                    font-size: 2.3rem;\n                    font-weight: 700;\n                    text-align: center;"))},[_vm._v(" "+_vm._s(_vm.$vuetify.rtl ? _vm.localization.fa.reset_password.title : _vm.localization.en.reset_password.title)+" ")]),_c('v-form',{ref:"resetPassForm",staticClass:"mx-3"},[_c('v-text-field',{attrs:{"type":"text","label":_vm.$vuetify.rtl ? 'ایمیل' : 'Email',"dir":"ltr","outlined":"","rules":_vm.emailRules,"color":_vm.$vuetify.theme.dark
                ? _vm.style.reset_password.secondary
                : _vm.style.reset_password.primary},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('v-btn',{class:{ 'no-letter-spacing': _vm.$vuetify.rtl },attrs:{"block":"","depressed":"","dark":"","color":_vm.style.reset_password.primary,"loading":_vm.is_loading},on:{"click":function($event){$event.preventDefault();return _vm.sendResetPasswordRequest.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$vuetify.rtl ? _vm.localization.fa.reset_password.title : _vm.localization.en.reset_password.title))])],1),_c('v-card-actions',{staticClass:"mt-3"},[_c('v-spacer'),_c('div',{staticClass:"mx-2",domProps:{"textContent":_vm._s(
            _vm.$vuetify.rtl
              ? _vm.localization.fa.login.no_account_msg
              : _vm.localization.en.login.no_account_msg
          )}}),_c('span',{staticStyle:{"font-size":"0.8rem","cursor":"pointer","color":"#30d237"},domProps:{"textContent":_vm._s(
            _vm.$vuetify.rtl
              ? _vm.localization.fa.login.signup_msg
              : _vm.localization.en.login.signup_msg
          )},on:{"click":function($event){$event.preventDefault();return _vm.goToSignUpPage()}}}),_c('v-spacer')],1)],1)],1)],1),_c('message-show',{ref:"MessageShow"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }