import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/fa";


const SERVICE_LANG_KEY = "service_lang";
const NEWS_LOCALE_KEY = "news_locale";


export default {
  data: () => ({
    locales: [
      {
        title: "فارسی",
        is_rtl: true,
        locale: "fa",
      },
      {
        title: "English",
        is_rtl: false,
        locale: "en",
      }
    ],
    locale_to_btnlang: {
      "fa": 1,
      "en": 2,
    },
    btnlang_to_locale: {
      1: "fa",
      2: "en",
    },
  }),
  methods: {
    pubDate: function (date, locale) {
      dayjs.extend(relativeTime);
      return dayjs(date).locale(locale).fromNow();
    },
    slugifyTitle: function (title) {
      return title.split(" ").join("-");
    },
    setNewsLocale: function (lang) {
      localStorage.setItem(NEWS_LOCALE_KEY, lang);
    },
    getNewsLocale: function () {
      return localStorage.getItem(NEWS_LOCALE_KEY);
    },
    setLocale: function (lang) {
      document.getElementsByTagName('html')[0].lang = lang
      localStorage.setItem(SERVICE_LANG_KEY, lang);
    },
    getLocale: function () {
      return localStorage.getItem(SERVICE_LANG_KEY)
      // return document.getElementsByTagName('html')[0].lang;
    },
    getSubscriptionPlanInfo: function (sub_plan) {
      let obj;
      dayjs.extend(relativeTime);
      const now_date = dayjs();
      const days_left = -1 * now_date.diff(sub_plan.exp_date, "day");
      const credit_days = dayjs(sub_plan.exp_date).diff(
        sub_plan.joined_date,
        "day"
      );

      obj = {
        credit_days: credit_days,
        days_left: days_left,
      };
      // returing the obj
      return obj;
    },
    setupSubscriptionPlansCharts: function (subscription_data) {
      // init
      let total_days = 0;
      let days_left = 0;
      // looping over subscription plans
      for (let i = 0; i < subscription_data.length; i++) {
        let sub_plan = this.getSubscriptionPlanInfo(subscription_data[i]);
        total_days += sub_plan.credit_days;
        days_left += sub_plan.days_left;
      }
      return [
        days_left,
        total_days - days_left
      ]
    },
  },
}