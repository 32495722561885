<template>
  <v-snackbar
    v-model="message_bar"
    :color="color"
    :timeout="message_timeout"
    text
    outlined
    class="mb-md-8"
    :top="$vuetify.breakpoint.xs"
    min-width="300"
  >
    <span class="me-1"
      ><v-icon :color="color" v-if="color === 'success'"
        >mdi-check-circle</v-icon
      ></span
    >
    <span class="me-1"
      ><v-icon :color="color" v-if="color === 'error'"
        >mdi-close-circle</v-icon
      ></span
    >
    <span class="me-1"
      ><v-icon :color="color" v-if="color === 'warning'"
        >mdi-alert-circle</v-icon
      ></span
    >
    <strong> {{ message }}</strong>
  </v-snackbar>
</template>

<script>
/* eslint-disable no-console */

export default {
  components: {},
  data() {
    return {
      message_timeout: 2000,
      message: "",
      error_bar: false,
      message_bar: false,
      color: "",
    };
  },
  created() {},
  methods: {
    showMessage(message, type) {
      var color = "grey";
      if (type === "error") {
        color = "error";
      } else if (type === "success") {
        color = "success";
      } else if (type === "warning") {
        color = "warning";
      }
      this.color = color;
      this.message = message;
      this.message_bar = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>

