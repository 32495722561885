const IS_THEME_DARK = "is_theme_dark"
const PAGE_NUM = "page_num"

export default {
  data: () => ({
    style: {
      primary: "#146A5F",
      primary_dark: "#146A50",
      secondary: "#20af9c",
      card: {
        bg_color: "#f5f7fa",
      },
      main: {
        bg_color: {
          light: "#FDFDFD",
          dark: "#1c1c1c",
        }
      },
      chart: {
        color_palette_68: [
          "#26A69A",
          // "rgba(255, 99, 132, 0.68)",
          "#EEEEEE",
          "rgba(54, 162, 235, 0.68)",
          "rgba(255, 206, 86, 0.68)",
          "rgba(75, 192, 192, 0.68)",
          "rgba(153, 102, 255, 0.68)",
          "rgba(255, 159, 64, 0.68)",
        ],
        color_palette_20: [
          "#26A69A",
          "rgba(255, 99, 132, 0.20)",
          "#EEEEEE",
          "rgba(54, 162, 235, 0.20)",
          "rgba(255, 206, 86, 0.20)",
          "rgba(75, 192, 192, 0.20)",
          "rgba(153, 102, 255, 0.20)",
          "rgba(255, 159, 64, 0.20)",
        ],
        border_palette: [
          "#26A69A",
          "rgba(255, 99, 132, 1)",
          "#EEEEEE",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
      },
      login: {
        primary: "#146A5F",
        primary_dark: "white",
        primary_variant: "#146A5F",
        secondary: "#20af9c"
      },
      signup: {
        primary: "#146A5F",
        primary_dark: "white",
        primary_variant: "#146A5F",
        secondary: "#20af9c"
      },
      reset_password: {
        primary: "#146A5F",
        primary_dark: "white",
        primary_variant: "#146A5F",
        secondary: "#20af9c"
      }
    },
  }),
  methods: {
    setTheme: function (status) {
      localStorage.setItem(IS_THEME_DARK, status);
    },
    getTheme: function () {
      return localStorage.getItem(IS_THEME_DARK);
    },
    setPage: function (num) {
      localStorage.setItem(PAGE_NUM, num);
    },
    getPage: function () {
      return localStorage.getItem(PAGE_NUM);
    },
  },

}
