// new
const SOURCE_FA_ICON_DIR = "https://static.zagros.live/images/news_logo/FA/"
const SOURCE_EN_ICON_DIR = "https://static.zagros.live/images/news_logo/EN/"
export default {
  data: () => ({
    localization: {
      fa: {
        fa_sources: {
          title: "منابع فارسی",
          base_url: SOURCE_FA_ICON_DIR,
        },
        en_sources: {
          title: "منابع انگلیسی",
          base_url: SOURCE_EN_ICON_DIR,
        },
        actions: {
          filter: "اعمال فیلتر",
          save_changes: "ذخیره فیلتر"
        }
      },
      en: {
        fa_sources: {
          title: "Farsi Sources",
          base_url: SOURCE_FA_ICON_DIR,
        },
        en_sources: {
          title: "English Sources",
          base_url: SOURCE_EN_ICON_DIR,
        },
        actions: {
          filter: "Filter",
          save_changes: "Save Filter"
        }
      },
    },
    sources: {
      fa: {
        items: [{
          id: 1001,
          title: "ارز دیجیتال",
          en: "arzdigital",
          img: `${SOURCE_FA_ICON_DIR}arzdigital.svg`,

        },
        // {
        //   id: 1002,
        //   title: "ارزیف",
        //   en: "arzif",
        // },
        // {
        //   id: 1003,
        //   title: "بیت من",
        //   en: "bitimen",
        // },
        // {
        //   id: 1004,
        //   title: "بیت پین",
        //   en: "bitpin",
        // },
        // {
        //   id: 1005,
        //   title: "سیتکس",
        //   en: "citex",
        // },
        {
          id: 1006,
          title: "دیجیاتو",
          en: "digiato",
          img: `${SOURCE_FA_ICON_DIR}digiato.svg`,

        },
        {
          id: 1007,
          title: "دنیای ترید",
          en: "donyayetrade",
          img: `${SOURCE_FA_ICON_DIR}donyaye-trade.svg`,

        },
        // {
        //   id: 1008,
        //   title: "اقتصاد نیوز",
        //   en: "eghtesadnews",
        //   img: `${SOURCE_FA_ICON_DIR}eghtesadnews.svg`,

        // },
        {
          id: 1009,
          title: "اقتصاد آنلاین",
          en: "eghtesadonline",
          img: `${SOURCE_FA_ICON_DIR}eghtesadonline.svg`,

        },
        // {
        //   id: 1010,
        //   title: "اکسکوینو",
        //   en: "excoino",
        // },
        // {
        //   id: 1011,
        //   title: "اکسنوین",
        //   en: "exnovin",
        // },
        // {
        //   id: 1012,
        //   title: "قطره",
        //   en: "ghatreh",
        //   img: `${SOURCE_FA_ICON_DIR}ghatreh.svg`,

        // },
        // {
        //   id: 1013,
        //   title: "گرندکس",
        //   en: "grandex",
        // },
        // {
        //   id: 1014,
        //   title: "ایران بایننس",
        //   en: "iranbinance",
        // },
        {
          id: 1015,
          title: "سیگنال",
          en: "isignal",
          img: `${SOURCE_FA_ICON_DIR}isignal.svg`,

        },
        // {
        //   id: 1016,
        //   title: "جیبیتکس",
        //   en: "jibitex",
        // },
        // {
        //   id: 1017,
        //   title: "خبرفوری",
        //   en: "khabarfoori",
        // },
        // {
        //   id: 1018,
        //   title: "خبرآنلاین",
        //   en: "khabaronline",
        //   img: `${SOURCE_FA_ICON_DIR}khabaronline.svg`,
        // },
        // {
        //   id: 1019,
        //   title: "خبرپو",
        //   en: "khabarpu",
        // },
        // {
        //   id: 1020,
        //   title: "مهرنیوز",
        //   en: "mehrnews",
        //   img: `${SOURCE_FA_ICON_DIR}mehrnews.svg`,

        // },
        {
          id: 1021,
          title: "میهن بلاکچین",
          en: "mihanblockchain",
          img: `${SOURCE_FA_ICON_DIR}mihanblockchain.svg`,

        },
        {
          id: 1022,
          title: "میهن سیگنال",
          en: "mihansignal",
          img: `${SOURCE_FA_ICON_DIR}mihansignal.svg`,

        },
        // {
        //   id: 1023,
        //   title: "نوبیتکس",
        //   en: "nobitex",
        // },
        // {
        //   id: 1024,
        //   title: "فینیکس",
        //   en: "phinix",
        // },
        // {
        //   id: 1025,
        //   title: "تبدیل",
        //   en: "tabdeal",
        // },
        // {
        //   id: 1026,
        //   title: "تجارت نیوز",
        //   en: "tejaratnews",
        //   img: `${SOURCE_FA_ICON_DIR}tejaratnews.svg`,

        // },
        // {
        //   id: 1027,
        //   title: "والکس",
        //   en: "wallex",
        // },
        // {
        //   id: 1028,
        //   title: "باشگاه خبرنگاران",
        //   en: "yjc",
        //   img: `${SOURCE_FA_ICON_DIR}yjc.svg`,

        // },
        {
          id: 1029,
          title: "زومیت",
          en: "zoomit",
          img: `${SOURCE_FA_ICON_DIR}zoomit.svg`,

        },
        {
          id: 1030,
          title: "رمز ارز",
          en: "ramzarz",
          img: `${SOURCE_FA_ICON_DIR}ramzarz.svg`,

        },
        ],
      },
      en: {
        items: [{
          id: 2030,
          title: "Ambcrypto",
          img: `${SOURCE_EN_ICON_DIR}ambcrypto.svg`

        },
        {
          id: 2031,
          title: "Beincrypto",
          img: `${SOURCE_EN_ICON_DIR}beincrypto.svg`

        },
        {
          id: 2032,
          title: "Bitcoin",
          img: `${SOURCE_EN_ICON_DIR}bitcoin.svg`

        },
        {
          id: 2033,
          title: "Bitcoinist",
          img: `${SOURCE_EN_ICON_DIR}bitcoinist.svg`

        },
        // {
        //   id: 2034,
        //   title: "Bitfinex",
        //   img: `${SOURCE_EN_ICON_DIR}bitfinex.svg`

        // },
        // {
        //   id: 2035,
        //   title: "Bitso",
        //   img: `${SOURCE_EN_ICON_DIR}bitso.svg`

        // },
        // {
        //   id: 2036,
        //   title: "Bitstamp",
        // },
        // {
        //   id: 2037,
        //   title: "Business Insider",
        //   img: `${SOURCE_EN_ICON_DIR}businessinsider.svg`

        // },
        // {
        //   id: 2038,
        //   title: "Cex",
        //   img: `${SOURCE_EN_ICON_DIR}cex.svg`

        // },
        {
          id: 2039,
          title: "Coindesk",
          img: `${SOURCE_EN_ICON_DIR}coindesk.svg`

        },
        {
          id: 2040,
          title: "Coingape",
          img: `${SOURCE_EN_ICON_DIR}coingape.svg`

        },
        // {
        //   id: 2041,
        //   title: "CoinMarketCap",
        //   img: `${SOURCE_EN_ICON_DIR}coinmarketcap.svg`

        // },
        // {
        //   id: 2042,
        //   title: "Coin Speaker",
        //   img: `${SOURCE_EN_ICON_DIR}coinspeaker.svg`

        // },
        {
          id: 2043,
          title: "Cointelegraph",
          img: `${SOURCE_EN_ICON_DIR}cointelegraph.svg`

        },
        {
          id: 2044,
          title: "Crypto Briefing",
          img: `${SOURCE_EN_ICON_DIR}cryptobriefing.svg`

        },
        // {
        //   id: 2045,
        //   title: "Crypto Com",
        // }, 
        {
          id: 2046,
          title: "Crypto Potato",
          img: `${SOURCE_EN_ICON_DIR}cryptopotato.svg`

        }, {
          id: 2047,
          title: "Daily Coin",
          img: `${SOURCE_EN_ICON_DIR}dailycoin.svg`

        }, {
          id: 2048,
          title: "Daily Hodl",
          img: `${SOURCE_EN_ICON_DIR}dailyhodl.svg`

        },
        {
          id: 2049,
          title: "Decrypt",
          img: `${SOURCE_EN_ICON_DIR}decrypt.svg`

        }, {
          id: 2050,
          title: "Finance Magnates",
          img: `${SOURCE_EN_ICON_DIR}financemagnates.svg`

        }, {
          id: 2051,
          title: "Finbold",
          img: `${SOURCE_EN_ICON_DIR}finbold.svg`

        },
        //  {
        //   id: 2052,
        //   title: "Forbes",
        //   img: `${SOURCE_EN_ICON_DIR}forbes.svg`

        // },
        {
          id: 2053,
          title: "Forkast",
          img: `${SOURCE_EN_ICON_DIR}forkast.svg`

        },
        // {
        //   id: 2054,
        //   title: "Kucoin",
        //   img: `${SOURCE_EN_ICON_DIR}kucoin.svg`

        // }, 
        {
          id: 2055,
          title: "NEWS BTC",
          img: `${SOURCE_EN_ICON_DIR}newsbtc.svg`

        }, {
          id: 2056,
          title: "The Block Crypto",
          img: `${SOURCE_EN_ICON_DIR}theblockcrypto.svg`

        },
        // {
        //   id: 2057,
        //   title: "Trading View",
        //   img: `${SOURCE_EN_ICON_DIR}tradingview.svg`

        // }, 
        {
          id: 2058,
          title: "Trust Nodes",
          img: `${SOURCE_EN_ICON_DIR}trustnodes.svg`

        },
        // {
        //   id: 2059,
        //   title: "Uniswap",
        // },
        {
          id: 2060,
          title: "UToday",
          img: `${SOURCE_EN_ICON_DIR}utoday.svg`

        }, {
          id: 2061,
          title: "Zycrypto",
          img: `${SOURCE_EN_ICON_DIR}zycrypto.svg`

        },
        // {
        //   id: 2062,
        //   title: "Reuters",
        // },
        //  {
        //   id: 2063,
        //   title: "BYBIT",
        // },
        {
          id: 2064,
          title: "Investorplace",
          img: `${SOURCE_EN_ICON_DIR}investorplace.svg`

        },
        ],
      }
    },
  }),
}
