<template>
  <div ref="googleLoginBtn">
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";

export default {
  name: "google_authentication",
  props: ["locale", "theme"],
  methods: {
    async handleCredentialResponse(response) {
      const responsePayload = jwt_decode(response.credential);
      try {
        var prep_query = {
          access_token: responsePayload.jti,
          user_email: responsePayload.email,
          provider: "google",
          token_type: "bearer",
          id_token: responsePayload.sub,
          first_name: responsePayload.given_name,
          last_name: responsePayload.family_name,
        };

        this.$router.push({
          name: "auth-callback",
          query: prep_query,
        })
      } catch {
        return null;
      }
    },
  },
  mounted() {
    const gClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;
    window.google.accounts.id.initialize({
      client_id: gClientId,
      callback: this.handleCredentialResponse,
      auto_select: true
    })
    window.google.accounts.id.renderButton(
      this.$refs.googleLoginBtn, {
      text: 'Login',
      size: 'large',
      width: this.$vuetify.breakpoint.smAndDown ? `${this.$vuetify.breakpoint.width * 0.8}` : '400', // max width 400
      theme: this.theme, // option : filled_black | outline | filled_blue.
      locale: this.locale,
    }
    )
  },
};
</script>

<style scoped>
.no-letter-spacing {
  letter-spacing: 0;
}
</style>
